import { Room } from "../../types/wallTypes";
import { calculatePolygonArea, calculatePolygonCentroid } from "./findRooms";
import { Text } from "@react-three/drei";
import * as THREE from "three"; // Importing THREE for background mesh
import { convertWorldToMillimeter } from "../../utils/conversions"; // Assuming this is the correct import

// Utility function to pretty-print the area in square millimeters
const formatArea = (areaInMillimeters: number): string => {
  return areaInMillimeters.toLocaleString(); // Adds commas or spaces depending on locale
};

// Component to render room areas as text labels
export const RoomLabels: React.FC<{ rooms: Room[] }> = ({ rooms }) => {
  return (
    <group>
      {rooms.map((room, index) => {
        const areaWorldUnits = calculatePolygonArea(room);
        const centroid = calculatePolygonCentroid(room);
        const areaInSquareMillimeters = convertWorldToMillimeter(areaWorldUnits); // Convert to square millimeters

        return (
          <group key={index} position={[centroid.x, centroid.y, 0.01]}>
            <mesh position={[0, 0, -0.01]}>
              <planeGeometry args={[0.8, 0.2]} />
              <meshBasicMaterial color="white" depthWrite={false} />
            </mesh>
            <Text
              fontSize={0.5 / 4} // 1/4th of the previous size
              color="black"
              anchorX="center" // Center-align text horizontally
              anchorY="middle" // Center-align text vertically
            >
              {`${formatArea(areaInSquareMillimeters)} mm²`}
            </Text>
          </group>
        );
      })}
    </group>
  );
};
