import React, { useRef, useState, useEffect, useContext, useCallback } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { TextType } from "../../types/wallTypes";
import { BoundingBox, SelectableSymbol } from "./SelectableSymbol";
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Html, Text } from "@react-three/drei";
import { editorStore } from "../../store/editorStore";

interface TextSymbolProps {
  text: TextType;
  onDragStart: (text: TextType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const TextSymbol: React.FC<TextSymbolProps> = ({
  text,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const floorplannerStore = useContext(FloorplannerStoreContext);

  const [textBoxWidth, setTextBoxWidth] = useState(text.textBoxWidth);
  const [textBoxHeight, setTextBoxHeight] = useState(text.textBoxHeight);
  const [centerPosition, setCenterPosition] = useState<[number, number]>([
    textBoxWidth / 2,
    textBoxHeight / 2,
  ]);
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(text.text || floorplannerStore.text);

  const inputRef = useRef<HTMLInputElement>(null);

  const flipX = text.flipHorizontal ? -1 : 1;
  const flipY = text.flipVertical ? -1 : 1;

  const calculateBoundingBox = useCallback((): BoundingBox => {
    const halfWidth = textBoxWidth / 2;
    const halfHeight = textBoxHeight / 2;
  
    const topLeft: [number, number] = [-halfWidth, halfHeight];
    const topRight: [number, number] = [halfWidth, halfHeight];
    const bottomLeft: [number, number] = [-halfWidth, -halfHeight];
    const bottomRight: [number, number] = [halfWidth, -halfHeight];
  
    return {
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
      width: textBoxWidth,
      height: textBoxHeight,
      depth: 0.01,
    };
  }, [textBoxWidth, textBoxHeight]);
  
  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = (event: PointerEvent) => {
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);

    const dx = (worldX - text.position.x - dragOffset.current.x) * flipX;
    const dy = (worldY - text.position.y - dragOffset.current.y) * flipY;

    const newWidth = Math.max(0.1, textBoxWidth + dx);
    const newHeight = Math.max(0.1, textBoxHeight + dy);

    setTextBoxWidth(newWidth);
    setTextBoxHeight(newHeight);
  };

  const onPointerDownHandle = (event: React.PointerEvent) => {
    event.stopPropagation();
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    dragOffset.current = {
      x: worldX - (textBoxWidth * 0.75 * flipX + text.position.x),
      y: worldY - (textBoxHeight * 0.75 * flipY + text.position.y),
    };
    gl.domElement.addEventListener("pointermove", onHandleDrag);
    gl.domElement.addEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(true);
  };

  const onPointerUpHandle = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };

  useEffect(() => {
    setTextBoxWidth(text.textBoxWidth);
  }, [text.textBoxWidth]);

  useEffect(() => {
    setTextBoxHeight(text.textBoxHeight);
  }, [text.textBoxHeight]);

  const handleTextClick = () => {
    setIsEditing(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(editText.length, editText.length);
      }
    }, 100);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    floorplannerStore.updateSymbolProperty(text.id, "text", editText);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEditText(value);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(value.length, value.length);
      }
    }, 0);
  };

  return (
    <>
      <DraggableObject
        position={[text.position.x, text.position.y]}
        onDragStart={(offset) => onDragStart(text, offset)}
        onDragEnd={(endPosition) => onDragEnd(endPosition)}
        onDrag={onDrag}
        selectable={true}
        attachmentId={text.id}
        attachmentType="doorAttachments"
      >
        <SelectableSymbol
          ref={groupRef}
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResize={(newWidth, newHeight) => {
            setTextBoxWidth(newWidth);
            setTextBoxHeight(newHeight);
            floorplannerStore.updateSymbolProperty(text.id, "textBoxWidth", newWidth);
            floorplannerStore.updateSymbolProperty(text.id, "textBoxHeight", newHeight);
          }}
          center={centerPosition}
          rotation={text.rotation}
          symbol={text}
          drawHandles={true}
        >
          <group ref={groupRef} rotation={[0, 0, text.rotation || 0]}>
            <mesh position={[0, 0, -0.01]}>
              <planeGeometry args={[textBoxWidth, textBoxHeight]} />
              <meshBasicMaterial
                color={text.selected ? 0xE5EAFF : 0xFFFFFF}
                depthWrite={false}
                depthTest={true}
                blending={THREE.NormalBlending}
              />
            </mesh>
            <Text
              position={[0, 0, 0]}
              fontSize={text.fontSize}
              color="black"
              onClick={handleTextClick}
              anchorX="center"
              anchorY="middle"
              maxWidth={textBoxWidth}
              fontWeight={text.fontWeight}
            >
              {editText}
            </Text>
            {text.selected && (
              <group
                onPointerDown={onPointerDownHandle as unknown as (event: THREE.Event) => void}
                onPointerUp={onPointerUpHandle as unknown as (event: THREE.Event) => void}
              >
                <mesh>
                  <boxGeometry args={[0.25, 0.25, 0.01]} />
                  <meshStandardMaterial color="gray" opacity={0.5} transparent />
                </mesh>
              </group>
            )}
          </group>
        </SelectableSymbol>
      </DraggableObject>
      {isEditing && (
        <Html position={[text.position.x, text.position.y, 0]}>
          <input
            ref={inputRef}
            style={{
              background: "white",
              border: "1px solid black",
              fontSize: text.fontSize,
              textAlign: "center",
            }}
            value={editText}
            placeholder="Enter text here..."
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoFocus
          />
        </Html>
      )}
    </>
  );
};

export default TextSymbol;
