import { makeAutoObservable, ObservableMap, runInAction } from "mobx";
import * as THREE from "three";
import {
  CutoutType,
  LineType,
  WallType,
  ClippingPolygonType,
  DoorType,
  DoubleDoorType,
  WindowType,
  CircleStairsType,
  RectStairsType,
  SvgType,
  SymbolType,
  isDoorType,
  isDoubleDoorType,
  isWindowType,
  WallConnectionStart,
  WallConnectionEnd,
  WallConnectionType,
  isCircleStairsType,
  isRectStairsType,
  isSvgType,
  TextType,
  isTextType,
} from "../types/wallTypes";
import { createContext, useContext } from "react";
import { ApolloClient, gql } from "@apollo/client";
import {
  DeleteFloorplanDocument,
  EditFloorplanDocument,
  EditFloorplanMutation,
  FloorplanDocument,
  FloorplansDocument,
} from "../gql";
import { defaultFloorplan } from "./defaultFloorplan";
import { checkFloorplannerStoreIntegrity } from "./checkFloorplannerStoreIntegrity";
import { updateWallConnections } from "../components/FloorPlan/updateWallConnections";
import { editorStore } from "./editorStore";

const LOCAL_STORAGE_KEY = "floorplannerStoreState";
const UNDO_REDO_LIMIT = 50;
export class FloorplannerStore {
  walls: { [key: string]: WallType } = {};
  wallsMap: ObservableMap<string, WallType> = new ObservableMap();
  symbols: { [key: string]: SymbolType } = {};
  symbolsMap: ObservableMap<string, SymbolType> = new ObservableMap();
  client: ApolloClient<any> | undefined;
  dirty: boolean = false;
  blockDirty: boolean = false;
  id: string = "";
  isSaving: boolean = false;
  initialized: boolean = false;
  undoStack: string[] = [];
  redoStack: string[] = [];
  alignmentLines: JSX.Element[] = [];
  boundingBox: THREE.Box3 | null = null;

  // Default properties
  wallWidth: number = 0.1;
  lineWeight: number = 1;
  lineColor: number = 0x000000;
  fillColor: number = 0xffffff; //0x888888;
  hoverColor: number = 0x0000ff;
  wallLineWeight = 1;
  doorWidth = 0.9;
  doorFrameWidth = 0.05;
  doorLineWeight = 0.5;
  doorBladeThickness = 0.05;
  doubleDoorWidth = this.doorWidth;
  openAngle = Math.PI / 2;
  windowWidth = 0.1;
  windowFrameWidth = 0.05;
  windowLength = 1.2;
  windowLineWeight = 0.5;
  circleStairsWidth = 0.9;
  rectStairsWidth = 0.9;
  rectStairsHeight = 2 * this.rectStairsWidth;
  symbolHandleSize = 0.09;
  symbolLineWeight = 1;
  svgWidth = 2;
  svgHeight = 1;
  svgPath = '';
  stairStepSize = 0.32;
  textBoxWidth = 3;
  textBoxHeight = 1;
  fontSize= 0.5;
  fontStyle= "normal";
  fontWeight= "normal"; // A numeric font weight, "normal", or "bold"
  lineHeight = 1
  text =  "Add Text";
  name: string = "Untitled";

  constructor() {
    makeAutoObservable(this);
    // Restore persisted state during hot reload
    this.rehydrateStore();
  }

  rehydrateStore = () => {
    const persistedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    const undoStack = localStorage.getItem(`${LOCAL_STORAGE_KEY}_undoStack`);
    const redoStack = localStorage.getItem(`${LOCAL_STORAGE_KEY}_redoStack`);

    if (persistedState) {
      this.fromJSON(persistedState);
    }
    if (undoStack) {
      this.undoStack = JSON.parse(undoStack);
    }
    if (redoStack) {
      this.redoStack = JSON.parse(redoStack);
    }
  };

  persistStore = () => {
    const state = this.toJSON();
    localStorage.setItem(LOCAL_STORAGE_KEY, state);
    localStorage.setItem(`${LOCAL_STORAGE_KEY}_undoStack`, JSON.stringify(this.undoStack));
    localStorage.setItem(`${LOCAL_STORAGE_KEY}_redoStack`, JSON.stringify(this.redoStack));
  }

  async initialize(client: ApolloClient<any>, id?: string) {
    this.client = client;
    if (id) {
      this.setId(id);
    } else {
      this.loadDefaultFloorplan();
    }
    this.initialized = true;
  }

  loadDefaultFloorplan = (filterList?: string) => {
    runInAction(() => {
      if (filterList === "symbols") {
        this.symbols = {};
       //this.symbols = defaultFloorplan.symbols || {};
      } else {
        this.walls =  {};
        this.symbols =  {};
        //this.walls = defaultFloorplan.walls || {};
        //this.symbols = defaultFloorplan.symbols || {};
      }
      this.name = "Untitled";
      this.dirty = false;
      this.blockDirty = false;
      this.integrityCheck();

      this.persistStore();
      this.resetUndoRedoStacks();
    });
  };

  integrityCheck = () => {
    checkFloorplannerStoreIntegrity(this);
  }



  generateId = () => {
    return Math.random().toString(36).substr(2, 9)
  }

  setId = (id: string) => {
    this.id = id;
    // Load the floorplan from the cloud
    this.loadFromCloud(id);
  }

  setDirty = () => {
    if (this.blockDirty) return;
    // Save the current state to the undo stack before marking dirty
    this.pushToUndoStack();
    this.redoStack = [];  // Clear the redo stack as new changes invalidate redo history
    this.dirty = true;
  };

  setBlockDirty = (block: boolean) => {
    this.blockDirty = block;
  }

  pushToUndoStack = () => {
    const currentState = this.toJSON();
    this.undoStack.push(currentState);

    // Ensure undo stack doesn't exceed the limit
    if (this.undoStack.length > UNDO_REDO_LIMIT) {
      this.undoStack.shift();  // Remove the oldest state to maintain the limit
    }

    this.persistStore();  // Persist the history along with the store
  };

  undo = () => {
    if (this.undoStack.length > 0) {
      const currentState = this.toJSON();
      this.redoStack.push(currentState);  // Save current state to redo stack
      const previousState = this.undoStack.pop();  // Get the last state from undo stack
      this.fromJSON(previousState!);  // Revert to the previous state
      this.persistStore();
      if (this.undoStack.length === 0) {
        this.pushToUndoStack();  // Save the current state to undo stack if there are no more undo states
      }
    }
  };

  redo = () => {
    if (this.redoStack.length > 0) {
      const currentState = this.toJSON();
      this.undoStack.push(currentState);  // Save current state to undo stack

      // Ensure undo stack doesn't exceed the limit
      if (this.undoStack.length > UNDO_REDO_LIMIT) {
        this.undoStack.shift();  // Remove the oldest state to maintain the limit
      }

      const nextState = this.redoStack.pop();  // Get the last state from redo stack
      this.fromJSON(nextState!);  // Revert to the next state
      this.persistStore();
    }
  };

  resetUndoRedoStacks = () => {
    this.undoStack = [];
    this.redoStack = [];
    this.persistStore();  // Persist the reset history along with the store
  };

  setName = (name: string) => {
    if (name === this.name) return;
    this.name = name;
    this.setDirty();
  }

  setLineWeight = (lineWeight: number) => {
    this.lineWeight = lineWeight;
  };

  convertLineWeightToWorld = (lineWeight: number) => {
    // Adjust for camera zoom level as well
    const zoomLevel = editorStore.camera?.zoom || 1;
    // Return lineWeight in world units based on the zoom level
    return lineWeight / zoomLevel / 44;
  }

  setLineColor = (color: number) => {
    this.lineColor = color;
  };

  setFillColor = (color: number) => {
    this.fillColor = color;
  };

  setHoverColor = (color: number) => {
    this.hoverColor = color;
  };

  setWalls = (walls: { [key: string]: WallType }) => {
    this.walls = { ...this.walls, ...walls };
    this.setDirty();
  };

  addWall = (wall: WallType) => {
    const newWall: WallType = {
      ...wall,
      type: "wall",
    };
    this.walls = { ...this.walls, [wall.id]: newWall };
    this.setDirty();
  };

  removeWall = (id: string) => {
    this.walls[id].symbolAttachments?.forEach((attachment) => {
      this.detachSymbolFromWall(attachment.symbolId);
    });
    const wallsCopy = { ...this.walls };
    const wall = wallsCopy[id];
    // Remove the wall from the connections of other walls
    wall.connections?.forEach((connection) => {
      const connectedWall = wallsCopy[connection.id];
      connectedWall.connections = connectedWall.connections?.filter((c) => c.id !== id);
    });
    // Remove any clipping polygons on other walls that belong to this wall through connections
    wall.connections?.forEach((connection) => {
      const connectedWall = wallsCopy[connection.id];
      connectedWall.clippingPolygons = connectedWall.clippingPolygons?.filter((polygon) => polygon.belongsTo !== id);
    });
    // Remove the wall
    delete wallsCopy[id];
    this.walls = wallsCopy;
    this.setDirty();
  };

  addCutout = (wallId: string, cutout: CutoutType) => {
    this.walls[wallId].cutouts?.push(cutout);
  };

  removeCutout = (wallId: string, cutoutIndex: number) => {
    this.walls[wallId].cutouts?.splice(cutoutIndex, 1);
  };

  updateWall = (id: string, start: THREE.Vector2, end: THREE.Vector2) => {
    if (this.walls[id]) {
      const wallsCopy = { ...this.walls };
      wallsCopy[id] = { ...wallsCopy[id], start, end };
      this.walls = wallsCopy;
      this.setDirty();
    }
  };

  setWallWidth = (width: number) => {
    this.wallWidth = width;
  };

  setSymbols = (symbols: { [key: string]: SymbolType }) => {
    this.symbols = { ...this.symbols, ...symbols };
  }

  updateWallPosition = (id: string, start: THREE.Vector2, end: THREE.Vector2) => {
    if (this.walls[id]) {
      const wallsCopy = { ...this.walls };
      wallsCopy[id] = { ...wallsCopy[id], start, end };
      this.walls = wallsCopy;
      this.setDirty();
    }
  };

  updateSymbolProperty = (
    symbolId: string,
    property: string,
    value: any,
  ) => {
    const symbol = this.symbols[symbolId];

    if (symbol) {
      // Update symbol property
      const updatedSymbol = {
        ...symbol,
        [property]: property === "position" ?
          new THREE.Vector2(value[0], value[1]) :
          property === "rotation" && value === 0 ? 0.0001 : 
          value,
      }
      // Make sure this.symbols list is mutated for mobx to detect changes
      this.symbols = {
        ...this.symbols,
        ...{ [symbolId]: updatedSymbol },
      };

      this.setDirty();
    }
  };

  addSymbol = (
    type: "symbol" | "window" | "door" | "doubleDoor" | "circleStairs" | "rectStairs" | "svg" | "text",
    position: [number, number],
    props?: any
  ) => {
    try {
      this.blockDirty = true;
      const id = Math.random().toString(36).substr(2, 9);
       // Place the symbol at the visible center if no position is provided
       const [centerX, centerY] = editorStore.getVisibleCenter();
       const posX = position[0] || centerX;
       const posY = position[1] || centerY;
      const newSymbol: SymbolType = {
        id,
        type,
        position: new THREE.Vector2(posX, posY),
        zIndex: 0.01 + (Object.keys(this.symbols).length / 1000), // Default zIndex within 0.01-0.02
      };
      if (props?.name) newSymbol.name = props.name;
      // If the object is a door, set some default properties
      if (isDoorType(newSymbol)) {
        newSymbol.doorWidth = this.doorWidth;
        newSymbol.openAngle = this.openAngle;
      }
      // If the object is a Double door, set some default properties
      if (isDoubleDoorType(newSymbol)) {
        newSymbol.doubleDoorWidth = this.doubleDoorWidth;
        newSymbol.openAngle = this.openAngle;
      }
      // If the object is a window, set some default properties
      if (isWindowType(newSymbol)) {
        newSymbol.windowWidth = this.windowWidth;
        newSymbol.windowLength = this.windowLength;
      }
      // If the object is a circleStairs, set some default properties
      if (isCircleStairsType(newSymbol)) {
        newSymbol.circleStairsWidth = this.circleStairsWidth;
        newSymbol.openAngle = this.openAngle;
        newSymbol.stairStepSize = this.stairStepSize;
      }
      // If the object is a Rect stairs, set some default properties
      if (isRectStairsType(newSymbol)) {
        newSymbol.rectStairsWidth = this.rectStairsWidth;
        newSymbol.rectStairsHeight = this.rectStairsHeight;
        newSymbol.openAngle = this.openAngle;
        newSymbol.stairStepSize = this.stairStepSize;
      }
      // If the object is an SVG, set some default properties
      if (isSvgType(newSymbol)) {
        newSymbol.svgPath = props?.svgPath || this.svgPath;
        newSymbol.svgWidth = props?.svgWidth || this.svgWidth;
        newSymbol.svgHeight = props?.svgHeight || this.svgHeight;
      }
      // If the object is a Text, set some default properties
      if (isTextType(newSymbol)) {
        newSymbol.textBoxWidth = this.textBoxWidth;
        newSymbol.textBoxHeight = this.textBoxHeight;
        newSymbol.fontSize = this.fontSize;
        newSymbol.fontStyle = this.fontStyle;
        newSymbol.lineHeight = this.lineHeight;
        newSymbol.fontWeight = this.fontWeight;
        newSymbol.text = this.text;
      }

      this.symbols = {
        ...this.symbols,
        [id]: newSymbol
      };
    } finally {
      this.blockDirty = false;
      this.setDirty();
    } 
  };

  removeSymbol = (id: string) => {
    this.detachSymbolFromWall(id);
    const symbolsCopy = { ...this.symbols };
    delete symbolsCopy[id];
    this.symbols = symbolsCopy;
    this.setDirty();
  };

  symbolIsAttached = (symbolId: string) => {
    const symbol = this.symbols[symbolId];
    if (symbol) return symbol.attachedTo;
    else return null;
  }

  detachSymbolFromWall = (symbolId: string) => {
    const symbol = this.symbols[symbolId];
    if (symbol && symbol.attachedTo) {
      runInAction(() => {
        // Remove the attachment from the wall
        const wallId = symbol.attachedTo as string;
        const wallsCopy = { ...this.walls };
        const wallCopy = wallsCopy[wallId];
        wallCopy.symbolAttachments = wallCopy.symbolAttachments?.filter(attachment => attachment.symbolId !== symbolId);
        // Remove the clipping shape in the wall for the object
        wallCopy.clippingPolygons = wallCopy.clippingPolygons?.filter(polygon => polygon.belongsTo !== symbolId);
        wallsCopy[wallId] = wallCopy
        // Make sure this.walls list is mutated for mobx to detect changes to walls
        this.walls = wallsCopy;
        // Remove the attachment from the symbol
        const symbolCopy = { ...symbol };
        delete symbolCopy.attachedTo;
        delete symbolCopy.attachedDistance;
        // Make sure this.symbols list is mutated for mobx to detect changes to symbols
        this.symbols = { ...this.symbols, [symbolId]: symbolCopy };
        this.setDirty();
      });
    }
  }

  attachSymbolToWall = (symbolId: string, wallId: string, position: [number, number]) => {
    const symbol = this.symbols[symbolId];

    if (symbol) {
      if (symbol.attachedTo) {
        console.error("Trying to attach a symbol to a new wall while it is already attached to a wall, please detach first", symbolId);
        return;
      }
      runInAction(() => {
        // Attach the object to the wall
        const wallCopy = { ...this.walls[wallId] };
        // Find the closest point on the wall to the symbol
        if (!wallCopy.start || !wallCopy.end) {
          console.error("Wall does not have start or end points", wallId);
          return;
        }
        const wallStart = new THREE.Vector2(wallCopy.start.x, wallCopy.start.y);
        const wallEnd = new THREE.Vector2(wallCopy.end.x, wallCopy.end.y);
        const wallDirection = wallEnd.clone().sub(wallStart).normalize();
        const symbolPosition = new THREE.Vector2(position[0], position[1]);
        const symbolDistance = symbolPosition.clone().sub(wallStart).dot(wallDirection);
        const symbolPositionOnWall = wallStart.clone().add(wallDirection.clone().multiplyScalar(symbolDistance));
        const symbolDistanceFromStart = symbolPositionOnWall.distanceTo(wallStart);
        // Add the symbol attachment to the wall
        if (!wallCopy.symbolAttachments) wallCopy.symbolAttachments = [];
        wallCopy.symbolAttachments.push({
          symbolId,
          positionFromStart: symbolDistanceFromStart,
        });
        // Add a clipping shape in the wall for the symbol
        const clippingPolygon = this.getClippingPolygon(symbol, wallCopy);
        if (!wallCopy.clippingPolygons) wallCopy.clippingPolygons = [];
        wallCopy.clippingPolygons.push(clippingPolygon);
        // Mutate the walls with the new symbol attachment and clipping shape
        this.walls = { ...this.walls, [wallId]: wallCopy };

        // Attach the object to the wall
        const updatedObject = {
          ...symbol,
          attachedTo: wallId,
          attachedPosition: position,
        }
        // Mutate the symbols with the new attachment
        this.symbols = { ...this.symbols, [symbolId]: updatedObject };

        // Update the object position
        this.updateSymbolProperty(symbolId, "position", symbolPositionOnWall.toArray());

        // Update the object rotation to align with the wall
        let wallAngle = Math.atan2(wallCopy.end.y - wallCopy.start.y, wallCopy.end.x - wallCopy.start.x);

        this.updateSymbolProperty(symbolId, "rotation", wallAngle);

        // If the object is a window, update the window width to match the wall width + wall line width
        // if (isWindowType(symbol)) {
        //   this.updateSymbolProperty(symbolId, "windowWidth", (wallCopy.wallWidth || floorplannerStore.wallWidth) + (wallCopy.lineWidth || floorplannerStore.lineWidth) * 2);
        // }
      });

      this.setDirty();
    }
  }

  getSymbolPositionOfWall = (symbolId: string, wallId: string) => {
    const symbol = this.symbols[symbolId];
    const wall = this.walls[wallId];
    if (symbol && wall) {
      const symbolAttachment = wall.symbolAttachments?.find(attachment => attachment.symbolId === symbolId);
      if (symbolAttachment) {
        const wallStart = new THREE.Vector2(wall.start.x, wall.start.y);
        const wallEnd = new THREE.Vector2(wall.end.x, wall.end.y);
        const wallDirection = wallEnd.clone().sub(wallStart).normalize();
        const symbolPosition = wallStart.clone().add(wallDirection.clone().multiplyScalar(symbolAttachment.positionFromStart));
        return symbolPosition;
      }
    }
    return null;
  }

  cloneSymbol = (symbolId: string, newPosition?: THREE.Vector2) => {
    const symbol = this.symbols[symbolId];
    if (symbol) {
      const newSymbol = {
        ...symbol,
        id: this.generateId(),
        attachedTo: undefined,
        attachedPosition: undefined,
        attachedDistance: undefined,
        clipWall: undefined,
        position: newPosition || symbol.position.clone().add(new THREE.Vector2(0.2, 0.2)),
      };
      this.symbols = { ...this.symbols, [newSymbol.id]: newSymbol };
      this.setDirty();
    }
  }

  updateSymbolPosition = (symbolId: string, newPosition: THREE.Vector2) => {
    const symbol = this.symbols[symbolId];
    if (symbol) {
      const updatedSymbol = {
        ...symbol,
        position: newPosition,
      };
      this.symbols = {
        ...this.symbols,
        ...{ [symbolId]: updatedSymbol },
      };
      this.setDirty();
    }
  }

  setAlignmentLines(newLines: JSX.Element[]) {
    this.alignmentLines = newLines;
  }

  clearAlignmentLines() {
    this.alignmentLines = [];
  }
  
  sendToFront = (symbolId: string) => {
    const symbol = this.symbols[symbolId];
    if (symbol) {
      const maxZOrder = Math.max(...Object.values(this.symbols).map(s => s.zIndex));
      this.updateSymbolProperty(symbolId, 'zOrder', maxZOrder + 0.00001);
    }
  };

  bringForward = (symbolId: string) => {
    const symbol = this.symbols[symbolId];
    if (symbol) {
      const currentZOrder = symbol.zIndex;
      const symbolsArray = Object.values(this.symbols);
      const nextSymbol = symbolsArray.filter(s => s.zIndex > currentZOrder)
        .sort((a, b) => a.zIndex - b.zIndex)[0];
      if (nextSymbol) {
        const nextZOrder = nextSymbol.zIndex;
        this.updateSymbolProperty(symbolId, 'zOrder', (currentZOrder + nextZOrder) / 2);
      } else {
        this.sendToFront(symbolId);
      }
    }
  };

  bringBackward = (symbolId: string) => {
    const symbol = this.symbols[symbolId];
    if (symbol) {
      const currentZOrder = symbol.zIndex;
      const symbolsArray = Object.values(this.symbols);
      const prevSymbol = symbolsArray.filter(s => s.zIndex < currentZOrder)
        .sort((a, b) => b.zIndex - a.zIndex)[0];
      if (prevSymbol) {
        const prevZOrder = prevSymbol.zIndex;
        this.updateSymbolProperty(symbolId, 'zOrder', (currentZOrder + prevZOrder) / 2);
      } else {
        this.sendToBack(symbolId);
      }
    }
  };

  sendToBack = (symbolId: string) => {
    const symbol = this.symbols[symbolId];
    if (symbol) {
      const minZOrder = Math.min(...Object.values(this.symbols).map(s => s.zIndex));
      this.updateSymbolProperty(symbolId, 'zOrder', minZOrder - 0.00001);
    }
  };

  wallLength = (wall: WallType): number => {
    const lengthOfWall = new THREE.Vector2().subVectors(wall.end, wall.start).length();
    return lengthOfWall;
  }

  setWallLength = (
    wall: WallType,
    newLength: number,
    whichEndToUpdate: number | null = null,
    whatLength: "outer" | "inner" = "outer"
  ) => {
    if (editorStore.wallConstructionMode) {
      whichEndToUpdate = WallConnectionEnd;
    }
    // Validate newLength so it is a positive number
    if (newLength <= 0) {
      console.error("New length must be a positive number");
      return;
    }
    // Pick an end to extend if not specified
    if (whichEndToUpdate === null) {
      const endsAvailable = this.getAvailableWallConnectionPositions(wall);
      if (endsAvailable.size === 0) {
        // No ends available, so prefer to update the end
        whichEndToUpdate = WallConnectionEnd;
      } else {
        whichEndToUpdate = endsAvailable.values().next().value as number;
      }
    }

    runInAction(() => {
      // Deduct the start and end cap lengths from the new length
      newLength -= whatLength === "outer" ?
        this.wallEndCapLength(wall) + this.wallStartCapLength(wall) :
        -(this.wallEndCapLength(wall) + this.wallStartCapLength(wall));
      // Update the wall length
      const tempWalls = { ...this.walls };
      if (whichEndToUpdate === WallConnectionEnd) {
        const wallDirection = new THREE.Vector2().subVectors(wall.end, wall.start).normalize();
        const updatedEnd = wall.start.clone().add(wallDirection.clone().multiplyScalar(newLength));
        tempWalls[wall.id].end = updatedEnd;
        // if the wall has connected walls, update their positions as well
        updateWallConnections(tempWalls, wall, "end", [wall.id], this);
        if (tempWalls[wall.id].end !== updatedEnd) {
          tempWalls[wall.id].end = updatedEnd;
        }
      } else if (whichEndToUpdate === WallConnectionStart) {
        const wallDirection = new THREE.Vector2().subVectors(wall.start, wall.end).normalize();
        const updatedStart = wall.end.clone().add(wallDirection.clone().multiplyScalar(newLength));
        tempWalls[wall.id].start = updatedStart;
        // if the wall has connected walls, update their positions as well
        updateWallConnections(tempWalls, wall, "start", [wall.id], this);
        if (tempWalls[wall.id].start !== updatedStart) {
          tempWalls[wall.id].start = updatedStart;
        }
      }
      updateWallConnections(tempWalls, wall, null, [wall.id], this);
      // finally, update the walls
      this.setWalls(tempWalls);
    });
  }

  setWallProperty = (wallId: string, property: string, value: any) => {
    const wall = this.walls[wallId];
    if (wall) {
      const updatedWall = {
        ...wall,
        [property]: value,
      }
      this.walls = {
        ...this.walls,
        ...{ [wallId]: updatedWall },
      };
      this.setDirty();
    }
  }

  wallStartCapLength = (wall: WallType): number => {
    // Find the connected wall at the start or end (use forEach instead of find)
    let startConnectedWall: WallType | null = null as WallType | null;
    wall.connections?.forEach((connection) => {
      if (connection.sourcePosition === WallConnectionStart) {
        startConnectedWall = this.walls[connection.id];
      }
    });
    if (!startConnectedWall) {
      return 0;
    }
    return (startConnectedWall.wallWidth || this.wallWidth) / 2// + this.convertLineWeightToWorld(wall.lineWeight || this.wallLineWeight) / 2;
  }

  wallEndCapLength = (wall: WallType): number => {
    // Find the connected wall at the start or end (use forEach instead of find)
    let endConnectedWall: WallType | null = null as WallType | null;
    wall.connections?.forEach((connection) => {
      if (connection.sourcePosition === WallConnectionEnd) {
        endConnectedWall = this.walls[connection.id];
      }
    });
    if (!endConnectedWall) {
      return 0;
    }
    return (endConnectedWall.wallWidth || this.wallWidth) / 2// + this.convertLineWeightToWorld(wall.lineWeight || this.wallLineWeight) / 2;
  }

  wallOuterLength = (wall: WallType): number => {
    const lengthOfWall = this.wallLength(wall);
    const startCapLength = this.wallStartCapLength(wall);
    const endCapLength = this.wallEndCapLength(wall);
    const wallLength = lengthOfWall + startCapLength + endCapLength;
    return wallLength;
  }

  wallInnerLength = (wall: WallType): number => {
    const lengthOfWall = this.wallLength(wall);
    const startCapLength = this.wallStartCapLength(wall);
    const endCapLength = this.wallEndCapLength(wall);
    const wallLength = lengthOfWall - startCapLength - endCapLength;
    return wallLength;
  }

  /**
   * Returns the perpendicular vector to the wall that points towards the inner side of the room.
   * 
   * To decide which side of the wall is the inner side, we must look at the connected walls and their directions.
   * If the connected line crosses the wall direction, the inner side is the side where the connected line is pointing towards.
   * 
   * @param wall The wall to calculate the perpendicular vector for.
   * @returns The perpendicular vector to the wall that points towards the inner side of the room.
   * 
   */
  wallPerpendicularInner = (wall: WallType): THREE.Vector2 => {
    // Step 1: Get the direction of the wall (normalized)
    const wallDirection = new THREE.Vector2().subVectors(wall.end, wall.start).normalize();

    // Step 2: Find the connected wall at the start or end (find does not work so let's use forEach)
    let startConnection: WallConnectionType | undefined = undefined as WallConnectionType | undefined;
    let endConnection: WallConnectionType | undefined = undefined as WallConnectionType | undefined;
    wall.connections?.forEach((connection) => {
      if (connection.sourcePosition === WallConnectionStart) {
        startConnection = connection;
      } else if (connection.sourcePosition === WallConnectionEnd) {
        endConnection = connection;
      }
    });

    let connectedWallStart: THREE.Vector2 | null = null;
    let connectedWallEnd: THREE.Vector2 | null = null;
    let connectionPoint: THREE.Vector2 | null = null;

    // Step 3: Get the connected wall's start and end points and determine the connection point
    if (startConnection && this.walls[startConnection.id]) {
      const connectedWall = this.walls[startConnection.id];
      connectedWallStart = new THREE.Vector2(connectedWall.start.x, connectedWall.start.y);
      connectedWallEnd = new THREE.Vector2(connectedWall.end.x, connectedWall.end.y);
      connectionPoint = wall.start;
    } else if (endConnection && this.walls[endConnection.id]) {
      const connectedWall = this.walls[endConnection.id];
      connectedWallStart = new THREE.Vector2(connectedWall.start.x, connectedWall.start.y);
      connectedWallEnd = new THREE.Vector2(connectedWall.end.x, connectedWall.end.y);
      connectionPoint = wall.end;
    }

    // Step 4: If no connected wall is found, return the default perpendicular vector
    if (!connectedWallStart || !connectedWallEnd || !connectionPoint) {
      return new THREE.Vector2(-wallDirection.y, wallDirection.x); // Default perpendicular vector
    }

    // Step 5: Calculate the vector from the connection point to the connected wall
    // If it's a start connection, calculate from the start point of the wall to the connected wall's start point
    // If it's an end connection, calculate from the end point of the wall to the connected wall's start point
    let toConnectedWall: THREE.Vector2;
    if (connectedWallStart.distanceTo(connectionPoint) > connectedWallEnd.distanceTo(connectionPoint)) {
      toConnectedWall = new THREE.Vector2().subVectors(connectedWallStart, connectionPoint);
    } else {
      toConnectedWall = new THREE.Vector2().subVectors(connectedWallEnd, connectionPoint);
    }

    // Step 6: Calculate the cross product between the wall direction and the vector to the connected wall
    const crossProduct = wallDirection.x * toConnectedWall.y - wallDirection.y * toConnectedWall.x;

    // Step 7: Based on the sign of the cross product, decide the perpendicular direction
    if (crossProduct < 0) {
      // The connected wall is on one side of the wall, so return the flipped perpendicular vector
      return new THREE.Vector2(wallDirection.y, -wallDirection.x);
    } else {
      // The connected wall has crossed the source wall, so return the default perpendicular vector
      return new THREE.Vector2(-wallDirection.y, wallDirection.x);
    }
  }

  wallPerpendicularOuter = (wall: WallType): THREE.Vector2 => {
    // the opposite direction of the inner perpendicular
    return this.wallPerpendicularInner(wall).negate();
  }

  wallDirection = (wall: WallType): THREE.Vector2 => {
    return new THREE.Vector2().subVectors(wall.end, wall.start).normalize();
  }

  wallOppositeDirection = (wall: WallType): THREE.Vector2 => {
    return this.wallDirection(wall).negate();
  }

  // Return a set containing WallConnectionStart or WallConnectionEnd that is available for connection
  getAvailableWallConnectionPositions = (wall: WallType): Set<number> => {
    const availablePositions = new Set([WallConnectionStart, WallConnectionEnd]);
    wall.connections?.forEach((connection) => {
      availablePositions.delete(connection.sourcePosition);
    });
    return availablePositions;
  }

  wallIsConnected(wall: WallType, onlyCheckEnds: boolean = false): boolean {
    let connected = false;
    wall.connections?.forEach((connection) => {
      if (onlyCheckEnds) {
        if (connection.sourcePosition === WallConnectionStart || connection.sourcePosition === WallConnectionEnd) {
          connected = true;
        }
      } else if (this.walls[connection.id]) {
        connected = true;
      }
    });
    return connected;
  };

  getConnectedWall = (wall: WallType, position: number): WallType | null => {
    let connectedWall: WallType | null = null;
    const tempWall = this.walls[wall.id];
    const connectedWallId = tempWall.connections?.find((connection) => connection.sourcePosition === position)?.id;
    if (connectedWallId) {
      connectedWall = this.walls[connectedWallId];
    }
    return connectedWall;
  }

  cloneWall = (id: string, newPosition?: THREE.Vector2) => {
    const wall = this.walls[id];
    if (wall) {
      // Make a deep copy of the wall
      const newWall = JSON.parse(JSON.stringify(wall));
      newWall.id = Math.random().toString(36).substr(2, 9);
      newWall.start = newPosition || wall.start.clone().add(new THREE.Vector2(0.2, 0.2));
      newWall.end = newPosition ? newPosition.clone().add(wall.end.clone().sub(wall.start)) : wall.end.clone().add(new THREE.Vector2(0.2, 0.2));
      newWall.connections = [];
      newWall.symbolAttachments = [];
      newWall.clippingPolygons = [];
      this.addWall(newWall);
    }
  };

  getClippingPolygon = (symbol: SymbolType, wall: WallType): ClippingPolygonType => {
    const objectWidth = isDoorType(symbol)
      ? symbol.doorWidth + ((symbol.doorFrameWidth || this.doorFrameWidth) + this.convertLineWeightToWorld(wall.lineWeight || floorplannerStore.wallLineWeight)) * 2
      : isDoubleDoorType(symbol)
        ? (symbol.doubleDoorWidth + (symbol.doorFrameWidth || this.doorFrameWidth)) * 2
        : isWindowType(symbol)
          ? symbol.windowLength
          : 0.8;
    const objectLength = objectWidth / 2;
    const wallAngle = Math.atan2(wall.end.y - wall.start.y, wall.end.x - wall.start.x);
    const objectDirection = new THREE.Vector2(Math.cos(wallAngle), Math.sin(wallAngle));
    // If it is a door or Doubel door, offset the position by the door/ double door width
    const offset = isDoorType(symbol) ? objectWidth / 2 - (symbol.doorFrameWidth || this.doorFrameWidth) - this.convertLineWeightToWorld(wall.lineWeight || floorplannerStore.wallLineWeight) :
      isDoubleDoorType(symbol) ? objectWidth / 2 - (symbol.doorFrameWidth || this.doorFrameWidth) : 0;
    // If it is a door and the door is flipped, offset the position by the door width
    // if ((isDoorType(symbol) || isDoubleDoorType(symbol)) && (symbol.flipHorizontal)) {
    //   objectDirection.negate();
    // }
    const wallDirection = wall.end.clone().sub(wall.start).normalize();
    const symbolDistance = symbol.position.clone().sub(wall.start).dot(wallDirection);
    const symbolPositionOnWall = wall.start.clone().add(wallDirection.clone().multiplyScalar(symbolDistance));
    const objectPositionOffset = symbolPositionOnWall.clone().add(objectDirection.clone().multiplyScalar(offset));
    const objectLengthVector = objectDirection.clone().multiplyScalar(objectLength);
    const objectWidthVector = objectDirection.clone().rotateAround(new THREE.Vector2(0, 0), Math.PI / 2).multiplyScalar(objectWidth / 2);
    const topLeft = objectPositionOffset.clone().add(objectLengthVector).add(objectWidthVector);
    const topRight = objectPositionOffset.clone().add(objectLengthVector).sub(objectWidthVector);
    const bottomRight = objectPositionOffset.clone().sub(objectLengthVector).sub(objectWidthVector);
    const bottomLeft = objectPositionOffset.clone().sub(objectLengthVector).add(objectWidthVector);
    const clippingPolygon: ClippingPolygonType = {
      belongsTo: symbol.id,
      polygon: [
        new THREE.Vector2(topLeft.x, topLeft.y),
        new THREE.Vector2(topRight.x, topRight.y),
        new THREE.Vector2(bottomRight.x, bottomRight.y),
        new THREE.Vector2(bottomLeft.x, bottomLeft.y),
      ],
      objectType: "symbol",
    };
    return clippingPolygon;
  }

  unSelectAll = () => {
    runInAction(() => {
      let wallsModified = false;
      const wallsCopy = { ...this.walls };
      Object.values(wallsCopy).forEach((wall) => {
        if (wall.selected) {
          wall.selected = false;
          wallsModified = true;
        }
      });
      if (wallsModified) {
        this.walls = wallsCopy;
      }

      let symbolsModified = false;
      const symbolsCopy = { ...this.symbols };
      Object.values(this.symbols).forEach((symbol) => {
        if (symbol.selected) {
          symbol.selected = false;
          symbolsModified = true;
        }
      });
      if (symbolsModified) {
        this.symbols = symbolsCopy;
      }
    });
  }

  selectWall = (id: string) => {
    const wallsCopy = { ...this.walls };
    const wall = wallsCopy[id];
    if (wall) {
      wall.selected = true;
      this.walls = wallsCopy;
    }
  }

  selectSymbol = (id: string) => {
    const symbolsCopy = { ...this.symbols };
    const symbol = symbolsCopy[id];
    if (symbol) {
      symbol.selected = true;
      this.symbols = symbolsCopy;
    }
  }

  toJSON = (excludeProperties: string[] | undefined = undefined) => {
    const walls = Object.values(this.walls).map((wall) => {
      const wallCopy: Partial<WallType> = { ...wall };
      delete wallCopy.selected;
      if (excludeProperties) {
        excludeProperties.forEach((property) => {
          delete wallCopy[property as keyof WallType];
        });
      }
      return wallCopy;
    });
    const symbols = Object.values(this.symbols).map((symbol) => {
      const symbolCopy: Partial<SymbolType | DoorType | DoubleDoorType | WindowType | CircleStairsType | RectStairsType | SvgType | TextType> = { ...symbol };
      delete symbolCopy.selected;
      if (excludeProperties) {
        excludeProperties.forEach((property) => {
          delete symbolCopy[property as keyof (SymbolType | DoorType | DoubleDoorType | WindowType | CircleStairsType | RectStairsType | SvgType| TextType)];
        });
      }
      return symbolCopy;
    });
    return JSON.stringify({
      id: this.id,
      name: this.name,
      walls: walls,
      symbols: symbols,
    });
  };

  fromJSON = (json: string) => {
    const parsedStructure = JSON.parse(json);
    if (!parsedStructure) {
      return;
    }

    // Make mobx observable changes but batch them for all properties
    runInAction(() => {
      this.walls = {}; // Replace the entire walls object
      this.symbols = {}; // Replace the entire symbols object
      this.dirty = false;
      this.blockDirty = false;
      this.name = "Untitled";
      if (parsedStructure.name) {
        this.name = parsedStructure.name;
      }
      if (parsedStructure.walls) {
        parsedStructure.walls.forEach((wall: WallType) => {
          this.walls[wall.id] = {
            ...wall,
            type: "wall",
            start: new THREE.Vector2(wall.start?.x, wall.start?.y),
            end: new THREE.Vector2(wall.end?.x, wall.end?.y),
          };
          if (wall.controlPoint) {
            this.walls[wall.id].controlPoint = new THREE.Vector2(wall.controlPoint.x, wall.controlPoint.y);
          }
          if (wall.cutouts) {
            this.walls[wall.id].cutouts = wall.cutouts.map((cutout: CutoutType) => {
              return {
                ...cutout,
                appliesTo: new Set(cutout.appliesTo),
              };
            });
          }
        });
        // Remove all properties that are not in the wall type
        Object.values(this.walls).forEach((wall) => {
          Object.keys(wall).forEach((key) => {
            if (!Object.keys(wall).includes(key)) {
              delete wall[key as keyof WallType];
            }
          });
        });
      }

      if (parsedStructure.symbols) {
        parsedStructure.symbols.forEach((symbol: SymbolType | DoorType | DoubleDoorType | CircleStairsType | RectStairsType | WindowType | SvgType | TextType) => {
          this.symbols[symbol.id] = {
            ...symbol,
            position: symbol.position
              ? new THREE.Vector2(symbol.position.x, symbol.position.y)
              : new THREE.Vector2(0, 0),
          };
          // If the object is a door, set some default properties
          if (isDoorType(this.symbols[symbol.id])) {
            if (!(this.symbols[symbol.id] as DoorType).doorWidth) {
              (this.symbols[symbol.id] as DoorType).doorWidth = this.doorWidth;
            }
            if (!(this.symbols[symbol.id] as DoorType).openAngle) {
              (this.symbols[symbol.id] as DoorType).openAngle = this.openAngle;
            }
          }
          // If the object is a Double door, set some default properties
          if (isDoubleDoorType(this.symbols[symbol.id])) {
            if (!(this.symbols[symbol.id] as DoubleDoorType).doubleDoorWidth) {
              (this.symbols[symbol.id] as DoubleDoorType).doubleDoorWidth = this.doubleDoorWidth;
            }
            if (!(this.symbols[symbol.id] as DoubleDoorType).openAngle) {
              (this.symbols[symbol.id] as DoubleDoorType).openAngle = this.openAngle;
            }
          }
          // If the object is a window, set some default properties
          if (isWindowType(this.symbols[symbol.id])) {
            if (!(this.symbols[symbol.id] as WindowType).windowWidth) {
              (this.symbols[symbol.id] as WindowType).windowWidth = 0.2;
            }
            if (!(this.symbols[symbol.id] as WindowType).windowLength) {
              (this.symbols[symbol.id] as WindowType).windowLength = 1;
            }
          }
          // If the object is a Circle Stairs, set some default properties
          if (isCircleStairsType(this.symbols[symbol.id])) {
            if (!(this.symbols[symbol.id] as CircleStairsType).circleStairsWidth) {
              (this.symbols[symbol.id] as CircleStairsType).circleStairsWidth = this.circleStairsWidth;
            }
            if (!(this.symbols[symbol.id] as CircleStairsType).openAngle) {
              (this.symbols[symbol.id] as CircleStairsType).openAngle = this.openAngle;
            }
            if (!(this.symbols[symbol.id] as CircleStairsType).stairStepSize) {
              (this.symbols[symbol.id] as CircleStairsType).stairStepSize = this.stairStepSize;
            }
          }
          // If the object is a Rectangle Stairs, set some default properties
          if (isRectStairsType(this.symbols[symbol.id])) {
            if (!(this.symbols[symbol.id] as RectStairsType).rectStairsWidth) {
              (this.symbols[symbol.id] as RectStairsType).rectStairsWidth = this.rectStairsWidth;
            }
            if (!(this.symbols[symbol.id] as RectStairsType).rectStairsHeight) {
              (this.symbols[symbol.id] as RectStairsType).rectStairsHeight = this.rectStairsHeight;
            }
            if (!(this.symbols[symbol.id] as RectStairsType).openAngle) {
              (this.symbols[symbol.id] as RectStairsType).openAngle = this.openAngle;
            }
            if (!(this.symbols[symbol.id] as RectStairsType).stairStepSize) {
              (this.symbols[symbol.id] as RectStairsType).stairStepSize = this.stairStepSize;
            }
          }
          // If the object is a Rectangle Stairs, set some default properties
          if (isTextType(this.symbols[symbol.id])) {
            if (!(this.symbols[symbol.id] as TextType).text) {
              (this.symbols[symbol.id] as TextType).text = this.text;
            }
            if (!(this.symbols[symbol.id] as TextType).fontSize) {
              (this.symbols[symbol.id] as TextType).fontSize = this.fontSize;
            }
            if (!(this.symbols[symbol.id] as TextType).fontStyle) {
              (this.symbols[symbol.id] as TextType).fontStyle = this.fontStyle;
            }
            if (!(this.symbols[symbol.id] as TextType).fontWeight) {
              (this.symbols[symbol.id] as TextType).fontWeight = this.fontWeight;
            }
            if (!(this.symbols[symbol.id] as TextType).lineHeight) {
              (this.symbols[symbol.id] as TextType).lineHeight = this.lineHeight;
            }
          }
          // If the object is an SVG, set some default properties
        if (isSvgType(this.symbols[symbol.id])) {
          const svgSymbol = this.symbols[symbol.id] as SvgType;
          svgSymbol.svgPath = svgSymbol.svgPath || this.svgPath;
          svgSymbol.svgWidth = svgSymbol.svgWidth || this.svgWidth;
          svgSymbol.svgHeight = svgSymbol.svgHeight || this.svgHeight;
        }
        });
          
        // Remove all properties that are not in the symbol type
        Object.values(this.symbols).forEach((symbol) => {
          Object.keys(symbol).forEach((key) => {
            if (!Object.keys(symbol).includes(key)) {
              delete symbol[key as keyof SymbolType];
            }
          });
        });
      }
    });
  };

  saveToCloud = async (
    withSnapshotImage: boolean = false,
    force: boolean = false,
    retryCount: number = 3,
    retryDelay: number = 1000, // 1 second initial delay
  ) => {
    if (!force && !this.dirty) {
      return;
    }

    // Prevent overlapping executions
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    try {
      const j = this.toJSON();
      const id = this.id;
      const file = null;

      for (let attempt = 1; attempt <= retryCount; attempt++) {
        try {
          const result = await this.client?.mutate<EditFloorplanMutation>({
            variables: { name: this.name, json: j, id: id, image: file },
            mutation: EditFloorplanDocument,
          });

          this.id = result?.data?.editFloorplan.id || "";
          this.dirty = false;
          this.persistStore();
          this.isSaving = false;
          return; // Successfully saved, exit the function
        } catch (error) {
          if (attempt < retryCount) {
            console.warn(`Save failed, retrying (${attempt}/${retryCount})...`);
            await new Promise(res => setTimeout(res, retryDelay));
            retryDelay *= 2; // Exponential backoff
          } else {
            console.error("Failed to save after multiple attempts:", error);
            this.isSaving = false;
            // Optionally notify the user of the failure
            // this.notifyUser("Failed to save floorplan. Please try again later.");
            throw error; // Re-throw the error after exhausting retries
          }
        }
      }
    } catch (error) {
      console.error("Failed to save floorplan:", error);
      this.isSaving = false;
    }
  };

  loadFromCloud = async (id: string) => {
    const { data } = (await this.client?.query({
      query: FloorplanDocument,
      variables: { id },
    })) || { data: { floorplan: null } };
    if (!data.floorplan) {
      return;
    }
    runInAction(() => {
      this.fromJSON(data.floorplan.json);
      this.id = data.floorplan.id;
      this.dirty = false;
      this.blockDirty = false;
      this.integrityCheck();
      this.persistStore();
      this.resetUndoRedoStacks();
      this.pushToUndoStack();
    });
  };

  deleteFromCloud = async (id: string) => {
    await this.client?.mutate({
      mutation: DeleteFloorplanDocument,
      variables: { id },
    });
    const floorplans = await this.findFloorplans();
    if (floorplans.length > 0) {
      await this.loadFromCloud(floorplans[0].id);
    } else {
      this.loadDefaultFloorplan();
    }
  };

  findFloorplans = async () => {
    const { data } = (await this.client?.query({
      query: FloorplansDocument,
    })) || { data: { floorplans: [] } };
    return data.floorplans;
  };
}

gql`
  query Floorplan($id: String!) {
    floorplan(id: $id) {
      id
      name
      json
      image
    }
  }
`;

gql`
  query Floorplans {
    floorplans {
      id
      name
      json
      image
    }
  }
`;

gql`
  mutation editFloorplan(
    $id: String!
    $name: String!
    $json: String!
    $image: Upload
  ) {
    editFloorplan(id: $id, name: $name, json: $json, image: $image) {
      id
      name
      json
      image
    }
  }
`;

gql`
  mutation deleteFloorplan(
    $id: String!
  ) {
    deleteFloorplan(id: $id) {
      id
    }
  }
`;

export const floorplannerStore = new FloorplannerStore();
export const FloorplannerStoreContext = createContext(floorplannerStore);
export const useFloorplannerStore = () => useContext(FloorplannerStoreContext);
