import { FloorplannerStore, floorplannerStore } from '../../store/floorplannerStore';
import { WallConnectionEnd, WallConnectionStart } from '../../types/wallTypes';
import { calculateLineVertices } from './calculateLineVertices';

export const composeLines = (
    filledLines: any[],
    leftLines: any[],
    rightLines: any[],
    endCaps: any[],
    floorplannerStore: FloorplannerStore
) => {
    const {
        walls,
        wallWidth,
    } = floorplannerStore;
    for (const wall of Object.values(walls)) {
        const customWallWidth = wall.wallWidth || wallWidth;
        const customWallWeight = floorplannerStore.convertLineWeightToWorld(wall.lineWeight || floorplannerStore.wallLineWeight)
        filledLines.push({
            wall: wall,
            paths: calculateLineVertices(
                wall.start,
                wall.end,
                0,
                customWallWeight,
                customWallWidth,
                wall.lineForm === "arc" ? "arc" : "straight",
                wall.controlPoint
            ),
            lineSide: "inner",
        });
        leftLines.push({
            wall: wall,
            paths: calculateLineVertices(
                wall.start,
                wall.end,
                -(customWallWidth + customWallWeight),
                customWallWeight,
                customWallWidth,
                wall.lineForm === "arc" ? "arcLeft" : "left",
                wall.controlPoint
            ),
            lineSide: "left",
        });
        rightLines.push({
            wall: wall,
            paths: calculateLineVertices(
                wall.start,
                wall.end,
                customWallWidth + customWallWeight,
                customWallWeight,
                customWallWidth,
                wall.lineForm === "arc" ? "arcRight" : "right",
                wall.controlPoint
            ),
            lineSide: "right",
        });
        let endCap: ("endCapBoth" | "endCapStart" | "endCapEnd" | "") = "";
        const endPoints = wall.connections?.filter(
            (c) =>
                c.sourcePosition === WallConnectionEnd ||
                c.sourcePosition === WallConnectionStart,
        );
        if (endPoints === undefined || endPoints.length === 0 ||
            endPoints?.filter(
                (c) =>
                    c.sourcePosition === WallConnectionStart ||
                    c.sourcePosition === WallConnectionEnd,
            ).length === 0
        ) {
            endCap = "endCapBoth";
        } else if (
            endPoints?.filter((c) => c.sourcePosition === WallConnectionStart)
                .length === 0
        ) {
            endCap = "endCapStart";
        } else if (
            endPoints?.filter((c) => c.sourcePosition === WallConnectionEnd)
                .length === 0
        ) {
            endCap = "endCapEnd";
        }
        if (endCap !== "") {
            endCaps.push({
                wall: wall,
                paths: calculateLineVertices(
                    wall.start,
                    wall.end,
                    0,
                    customWallWeight,
                    customWallWidth,
                    endCap,
                ),
                lineSide: "endcap",
            });
        } else {
            endCaps.push({
                wall: wall,
                paths: [],
                lineSide: "endcap",
            });
        }
    }
};