import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";
import { invalidate, ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { projectToWorld } from "./projectToWorld";
import { SymbolType } from "../../types/wallTypes";
import { editorStore } from "../../store/editorStore";
import { floorplannerStore } from "../../store/floorplannerStore";
import { Line } from "@react-three/drei";
import { FaRotate } from "react-icons/fa6";
import { Svg } from "./Svg";
import svgRotateTR from '../../../src/assets/tilt.svg';
import svgRotateTL from '../../../src/assets/tiltTL.svg';
import svgRotateBR from '../../../src/assets/tiltBR.svg';
import svgRotateBL from '../../../src/assets/tiltBL.svg';
export interface BoundingBox {
  topLeft: [number, number];
  topRight: [number, number];
  bottomLeft: [number, number];
  bottomRight: [number, number];
  width: number;
  height: number;
  depth: number;
}

export interface SelectableSymbolProps {
  symbol: SymbolType;
  children: React.ReactNode;
  handleSize: number;
  calculateBoundingBox: () => BoundingBox;
  onResize: (newWidth: number, newHeight: number, newDepth: number, handle: string) => void;
  onPointerOver?: (event: ThreeEvent<PointerEvent>) => void;
  onPointerOut?: (event: ThreeEvent<PointerEvent>) => void;
  center?: [number, number];
  rotation?: number;
  pivot?: [number, number];
  drawHandles?: boolean;
  lockAspectRatio?: boolean;
  onRotate?: (angle: number) => void;
}
/**
 * A component that allows an object to be selected and resized.
 * 
 * @param symbol The symbol to render.
 * @param children The children to render.
 * @param handleSize The size of the handles.
 * @param calculateBoundingBox The function to calculate the bounding box that each symbol use.
 * @param onResize The function to call when the object is resized.
 * @param center The center of the object.
 * @param rotation The rotation of the object.
 * @param pivot The pivot of the object.
 * @param drawHandles Whether to draw the handles.
 * @param lockAspectRatio Whether to lock the aspect ratio.
 * @param onRotate The function to call when the object is rotated.
 * @returns The SelectableSymbol component.
 * 
 * @see AttachableSymbol
 * @see DraggableObject
 **/
export const SelectableSymbol = React.forwardRef<THREE.Group, SelectableSymbolProps>(
  ({
    symbol,
    children,
    handleSize,
    calculateBoundingBox,
    onResize,
    onPointerOver = () => { },
    onPointerOut = () => { },
    center = [0, 0],
    rotation = 0,
    pivot = [0, 0],
    drawHandles = true,
    lockAspectRatio = false, // Default to false
    onRotate = () => { }, // Default to no-op
  }, ref) => {
    const [boundingBox, setBoundingBox] = useState<BoundingBox>({
      topLeft: [0, 0],
      topRight: [0, 0],
      bottomLeft: [0, 0],
      bottomRight: [0, 0],
      width: 0,
      height: 0,
      depth: 0,
    });

    const { gl, camera } = useThree();
    const handleRadius = handleSize / editorStore.zoomLevelDivisor();
    const handleDepth = 0.02; // Depth of the handles
    const initialPointerPosition = useRef<[number, number] | null>(null);
    const isResizing = useRef(false);
    const isRotating = useRef(false);
    const groupRef = useRef<THREE.Group>(null);
    const aspectRatio = boundingBox.width / boundingBox.height; // Calculate aspect ratio
    const rotationStart = useRef<number>(rotation);
    const angleRef = useRef<number>(rotation);
    const [hoveredHandle, setHoveredHandle] = useState<string | null>(null);
    // SVG path data for FaRotateLeft (Extracted directly from the react-icons/fa6 package)
    // const svgRotateData = FaRotate({}).props.children[0].props.d;
    //const svgRotate = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="black"><path d="${svgRotateData}"/></svg>`;
    //const [isRotateHovered, setIsRotateHovered] = useState(false);
    const [isRotateHovered, setIsRotateHovered] = useState<string | undefined>(undefined);
    const rotatedPivot = new THREE.Vector3(pivot[0], pivot[1], 0).applyMatrix4(new THREE.Matrix4().makeRotationZ(rotation));
    //const rotatedExtraHandlePos = new THREE.Vector3(handleSize, handleSize, 0).applyMatrix4(new THREE.Matrix4().makeRotationZ(rotation));

    // Get the angle from topRight to the bottomLeft
    const angleTopRight = Math.atan2(boundingBox.bottomLeft[1] - boundingBox.topRight[1], boundingBox.bottomLeft[0] - boundingBox.topRight[0]);
    const angleTopLeft = Math.atan2(boundingBox.bottomRight[1] - boundingBox.topLeft[1], boundingBox.bottomRight[0] - boundingBox.topLeft[0]);
    const angleBottomRight = Math.atan2(boundingBox.topLeft[1] - boundingBox.bottomRight[1], boundingBox.topLeft[0] - boundingBox.bottomRight[0]);
    const angleBottomLeft = Math.atan2(boundingBox.topRight[1] - boundingBox.bottomLeft[1], boundingBox.topRight[0] - boundingBox.bottomLeft[0]);

    const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);

    useFrame(() => {
      if (camera.zoom !== prevCameraZoom) {
        setPrevCameraZoom(camera.zoom);
        invalidate(); // Trigger a re-render
      }
    });

    // Effect to trigger the bounding box update
    useEffect(() => {
      if (groupRef.current) {
        const boundingBox = calculateBoundingBox();
        setBoundingBox(boundingBox);
      }

    }, [calculateBoundingBox, groupRef.current]);

    const handlePointerDown = (
      event: ThreeEvent<PointerEvent>,
      corner: string
    ) => {
      event.stopPropagation();

      const initialBoundingBox = calculateBoundingBox();
      const initialAspectRatio = initialBoundingBox.width / initialBoundingBox.height;

      const getCornerPosition = (corner: string) => {
        switch (corner) {
          case "topLeft": return new THREE.Vector2(initialBoundingBox.topLeft[0], initialBoundingBox.topLeft[1]);
          case "topRight": return new THREE.Vector2(initialBoundingBox.topRight[0], initialBoundingBox.topRight[1]);
          case "bottomLeft": return new THREE.Vector2(initialBoundingBox.bottomLeft[0], initialBoundingBox.bottomLeft[1]);
          case "bottomRight": return new THREE.Vector2(initialBoundingBox.bottomRight[0], initialBoundingBox.bottomRight[1]);
        }
      };

      const initialCornerPosition = getCornerPosition(corner);
      const initialPointerPosition = new THREE.Vector2(event.point.x, event.point.y);

      const onPointerMove = (moveEvent: PointerEvent) => {
        isResizing.current = true;
        const [worldX, worldY] = projectToWorld(
          moveEvent.clientX,
          moveEvent.clientY,
          gl,
          camera
        );
        const currentPointerPosition = new THREE.Vector2(worldX, worldY);
        const pointerDelta = currentPointerPosition.sub(initialPointerPosition);

        let newWidth = initialBoundingBox.width;
        let newHeight = initialBoundingBox.height;

        if (corner.includes("Left")) {
          newWidth = Math.max(initialBoundingBox.width - pointerDelta.x, 0.1);
        } else if (corner.includes("Right")) {
          newWidth = Math.max(initialBoundingBox.width + pointerDelta.x, 0.1);
        }

        if (corner.includes("top")) {
          newHeight = Math.max(initialBoundingBox.height + pointerDelta.y, 0.1);
        } else if (corner.includes("bottom")) {
          newHeight = Math.max(initialBoundingBox.height - pointerDelta.y, 0.1);
        }

        if (lockAspectRatio) {
          if (Math.abs(pointerDelta.x) > Math.abs(pointerDelta.y)) {
            newHeight = newWidth / initialAspectRatio;
          } else {
            newWidth = newHeight * initialAspectRatio;
          }
        }

        onResize(newWidth, newHeight, initialBoundingBox.depth, corner);
      };

      const onPointerUp = () => {
        window.removeEventListener("pointermove", onPointerMove);
        window.removeEventListener("pointerup", onPointerUp);
        floorplannerStore.setBlockDirty(false);
        floorplannerStore.setDirty();
        setHoveredHandle(null);
        // Select the object after resizing
        editorStore.clearSelections();
        editorStore.addSelection(symbol);
        floorplannerStore.updateSymbolProperty(symbol.id, "selected", !symbol.selected);

        isResizing.current = false;
      };

      window.addEventListener("pointermove", onPointerMove);
      window.addEventListener("pointerup", onPointerUp);
      floorplannerStore.setBlockDirty(true);
    };

    const handleGroupPointerDown = (event: ThreeEvent<PointerEvent>) => {
      initialPointerPosition.current = [event.clientX, event.clientY];
      isResizing.current = false;
      setHoveredHandle(null);

      const onPointerUp = (upEvent: PointerEvent) => {
        const [initialX, initialY] = initialPointerPosition.current!;
        const movedX = Math.abs(upEvent.clientX - initialX);
        const movedY = Math.abs(upEvent.clientY - initialY);

        if (movedX < 5 && movedY < 5 && !isResizing.current) {
          // Select the object if it was not dragged
          editorStore.clearSelections();
          editorStore.addSelection(symbol);
          floorplannerStore.updateSymbolProperty(symbol.id, "selected", !symbol.selected);
        }

        window.removeEventListener("pointerup", onPointerUp);
        floorplannerStore.setBlockDirty(false);
        floorplannerStore.setDirty();
      };

      window.addEventListener("pointerup", onPointerUp);
      floorplannerStore.setBlockDirty(true);
    };

    const handleRotatePointerDown = (event: ThreeEvent<PointerEvent>) => {
      event.stopPropagation();
      const initialPointer = new THREE.Vector2(event.point.x, event.point.y);
      const centerVector = new THREE.Vector2(center[0], center[1]);
      floorplannerStore.setBlockDirty(true);

      const onPointerMove = (moveEvent: PointerEvent) => {
        isRotating.current = true;
        // Convert the clientX/clientY (screen coordinates) to world coordinates using the projectToWorld function
        const [worldX, worldY] = projectToWorld(moveEvent.clientX, moveEvent.clientY, gl, camera);

        // Current pointer position in world coordinates
        const currentPointer = new THREE.Vector2(worldX, worldY);

        // Get the symbol position in world coordinates
        const symbolPosition = new THREE.Vector2(symbol.position.x, symbol.position.y);

        // The center of the symbol (in world coordinates) should be passed here instead of assuming (0, 0)
        const symbolCenter = new THREE.Vector2(
          symbolPosition.x + centerVector.x,
          symbolPosition.y + centerVector.y
        );

        // Calculate the distance between the current pointer and the symbol's center
        const distanceToCenter = currentPointer.distanceTo(symbolCenter);

        // Define a threshold distance to avoid jumpy behavior near the center
        const threshold = 0.1;

        // If the pointer is too close to the symbol's center, ignore angle changes to prevent jumping
        if (distanceToCenter < threshold) {
          return; // Skip angle update if the pointer is too close to the center
        }

        // Calculate the initial and current angles relative to the symbol's center (when the user starts dragging and moves the mouse)
        const initialAngle = Math.atan2(initialPointer.y - symbolCenter.y, initialPointer.x - symbolCenter.x);
        const currentAngle = Math.atan2(currentPointer.y - symbolCenter.y, currentPointer.x - symbolCenter.x);

        // Calculate the delta angle (change in angle) only if the angle has changed
        let deltaAngle = currentAngle - initialAngle;

        // Ignore mouse movements that don't change the angle (i.e., pure radial movement)
        if (deltaAngle === 0) {
          return; // No change in angle, no rotation needed
        }

        // Handle angle wrapping to ensure smooth rotation across ±π boundaries
        if (deltaAngle > Math.PI) {
          deltaAngle -= 2 * Math.PI;
        } else if (deltaAngle < -Math.PI) {
          deltaAngle += 2 * Math.PI;
        }

        // Compute the new rotation angle in radians
        let newAngle = rotationStart.current + deltaAngle;

        // Snap to 45-degree increments (convert to degrees for easier snapping)
        const newAngleInDegrees = THREE.MathUtils.radToDeg(newAngle);

        // Calculate the closest 45-degree angle
        const snapInterval = 45; // Snap to 45-degree increments
        const closestSnapAngle = Math.round(newAngleInDegrees / snapInterval) * snapInterval;

        // Define a snapping threshold (e.g., 0.1 radians or 5.73 degrees)
        const snapThreshold = 0.1; // in radians, ~5.73 degrees
        const angleDifference = Math.abs(newAngleInDegrees - closestSnapAngle);

        // Check if shift key is pressed in case we should ignore snapping
        if (!moveEvent.shiftKey) {
          // If the angle is close enough to a 45-degree step, snap to it
          if (angleDifference < THREE.MathUtils.radToDeg(snapThreshold)) {
            newAngle = THREE.MathUtils.degToRad(closestSnapAngle); // Snap to closest angle
          }
        }

        // Update the angle reference and trigger the rotation callback
        angleRef.current = newAngle;
        onRotate(newAngle);

        // Update the symbol's rotation in the store
        floorplannerStore.updateSymbolProperty(symbol.id, "rotation", angleRef.current);
      };

      const onPointerUp = () => {
        isRotating.current = false;
        floorplannerStore.setBlockDirty(false);
        // Remove event listeners
        window.removeEventListener("pointermove", onPointerMove);
        window.removeEventListener("pointerup", onPointerUp);
        rotationStart.current = angleRef.current;
        // Select the symbol after rotation
        editorStore.clearSelections();
        editorStore.addSelection(symbol);
        floorplannerStore.updateSymbolProperty(symbol.id, "selected", !symbol.selected);
      };

      window.addEventListener("pointermove", onPointerMove);
      window.addEventListener("pointerup", onPointerUp);
    };

    const handlePointerEnter = (handle: string) => {
      setHoveredHandle(handle);
    };

    const handlePointerLeave = () => {
      setHoveredHandle(null);
    };

    const rotationMatrix = new THREE.Matrix4().makeRotationZ(rotation);
    const transformedCenter = new THREE.Vector3(center[0], center[1], 0).applyMatrix4(rotationMatrix);

    const getTransformedCorner = (x: number, y: number) => {
      const corner = new THREE.Vector3(x, y, 0);
      corner.applyMatrix4(rotationMatrix);
      return corner;
    };

    const topLeft = getTransformedCorner(boundingBox.topLeft[0], boundingBox.topLeft[1]);
    const topRight = getTransformedCorner(boundingBox.topRight[0], boundingBox.topRight[1]);
    const bottomLeft = getTransformedCorner(boundingBox.bottomLeft[0], boundingBox.bottomLeft[1]);
    const bottomRight = getTransformedCorner(boundingBox.bottomRight[0], boundingBox.bottomRight[1]);

    const isHovered = (handle: string) => hoveredHandle === handle;

    return (
      <group ref={groupRef}>
        {/* Draw a transparent plane to capture pointer events */}
        <mesh
          position={[
            transformedCenter.x + rotatedPivot.x,
            transformedCenter.y + rotatedPivot.y,
            -0.001,
          ]}
          rotation={[0, 0, rotation]}
          onPointerDown={handleGroupPointerDown}
          onPointerOver={onPointerOver}
          onPointerOut={onPointerOut}
        >
          <planeGeometry args={[boundingBox.width, boundingBox.height]} />
          <meshBasicMaterial
            transparent
            opacity={0}
          //color={symbol.selected ? "yellow" : "white"}
          />
        </mesh>
        {children}
        {(symbol.selected && drawHandles) && (
          <>
            {/* Draw a thin blue line between all four corners (Using Line of drei) */}
            <Line
              points={[topLeft.clone().add(new THREE.Vector3(
                rotatedPivot.x,
                rotatedPivot.y,
                0)), topRight.clone().add(new THREE.Vector3(
                  rotatedPivot.x,
                  rotatedPivot.y,
                  0))]}
              color="blue"
              linewidth={0.4}
            />
            <Line
              points={[topRight.clone().add(new THREE.Vector3(
                rotatedPivot.x,
                rotatedPivot.y,
                0)), bottomRight.clone().add(new THREE.Vector3(
                  rotatedPivot.x,
                  rotatedPivot.y,
                  0))]}
              color="blue"
              linewidth={0.4}
            />
            <Line
              points={[bottomRight.clone().add(new THREE.Vector3(
                rotatedPivot.x,
                rotatedPivot.y,
                0)), bottomLeft.clone().add(new THREE.Vector3(
                  rotatedPivot.x,
                  rotatedPivot.y,
                  0))]}
              color="blue"
              linewidth={0.4}
            />
            <Line
              points={[bottomLeft.clone().add(new THREE.Vector3(
                rotatedPivot.x,
                rotatedPivot.y,
                0)), topLeft.clone().add(new THREE.Vector3(
                  rotatedPivot.x,
                  rotatedPivot.y,
                  0))]}
              color="blue"
              linewidth={0.4}
            />

            {/* Paint a green dot in the center */}
            {/* <mesh position={[center[0], center[1], 0.002]}>
              <circleGeometry args={[0.05, 32]} />
              <meshBasicMaterial color="green" />
            </mesh> */}

            {/* Rotation Extra handle (invisible larger area above topRight) */}
            {!floorplannerStore.symbolIsAttached(symbol.id) && (
              <>

                {/* Top Right Rotation Handle */}
                <group
                  position={[
                    topRight.x - Math.cos(angleTopRight + rotation) * handleSize * 2 / editorStore.zoomLevelDivisor() * 2,
                    topRight.y - Math.sin(angleTopRight + rotation) * handleSize * 2 / editorStore.zoomLevelDivisor() * 2,
                    0]}
                  onPointerDown={handleRotatePointerDown}
                  onPointerOver={() => setIsRotateHovered("topRight")}
                  onPointerOut={() => setIsRotateHovered(undefined)}
                >
                  {/* Invisible larger area */}
                  <mesh
                    position={[0, 0, -0.001]}
                  >
                    <circleGeometry args={[(handleSize * 1.5), 32]} />
                    <meshBasicMaterial
                      transparent
                      opacity={0}
                    //color="yellow"
                    />
                  </mesh>

                  {/* Use Svg component from drei to render the FaRotateLeft icon */}
                  {isRotateHovered === "topRight" && <Svg src={svgRotateTR} scale={0.009 / editorStore.zoomLevelDivisor() * 2} rotation={new THREE.Euler(0, 0, rotation)} />}
                </group>

                {/* Top Left Rotation Handle */}
                <group
                  position={[
                    topLeft.x - Math.cos(angleTopLeft + rotation) * handleSize * 2 / editorStore.zoomLevelDivisor() * 2,
                    topLeft.y - Math.sin(angleTopLeft + rotation) * handleSize * 2 / editorStore.zoomLevelDivisor() * 2,
                    0]}
                  onPointerDown={handleRotatePointerDown}
                  onPointerOver={() => setIsRotateHovered("topLeftCorner")}
                  onPointerOut={() => setIsRotateHovered(undefined)}
                >
                  {/* Invisible larger area */}
                  <mesh
                    position={[0, 0, -0.001]}
                  >
                    <circleGeometry args={[(handleSize * 1.5), 32]} />
                    <meshBasicMaterial
                      transparent
                      opacity={0}
                    //color="green"
                    />
                  </mesh>

                  {/* Use Svg component from drei to render the FaRotateLeft icon */}
                  {isRotateHovered === "topLeftCorner" && <Svg src={svgRotateTL} scale={0.009 / editorStore.zoomLevelDivisor() * 2} rotation={new THREE.Euler(0, 0, rotation)} />}
                </group>

                {/* Bottom Right Rotation Handle */}
                <group
                  position={[
                    bottomRight.x - Math.cos(angleBottomRight + rotation) * handleSize * 2 / editorStore.zoomLevelDivisor() * 2,
                    bottomRight.y - Math.sin(angleBottomRight + rotation) * handleSize * 2 / editorStore.zoomLevelDivisor() * 2,
                    0]}
                  onPointerDown={handleRotatePointerDown}
                  onPointerOver={() => setIsRotateHovered("bottomRightCorner")}
                  onPointerOut={() => setIsRotateHovered(undefined)}
                >
                  {/* Invisible larger area */}
                  <mesh
                    position={[0, 0, -0.001]}
                  >
                    <circleGeometry args={[(handleSize * 1.5), 32]} />
                    <meshBasicMaterial
                      transparent
                      opacity={0}
                    //color="red"
                    />
                  </mesh>

                  {/* Use Svg component from drei to render the FaRotateLeft icon */}
                  {isRotateHovered === "bottomRightCorner" && <Svg src={svgRotateBR} scale={0.009 / editorStore.zoomLevelDivisor() * 2} rotation={new THREE.Euler(0, 0, rotation)} />}
                </group>

                {/* Bottom Left Rotation Handle */}
                <group
                  position={[
                    bottomLeft.x - Math.cos(angleBottomLeft + rotation) * handleSize * 2 / editorStore.zoomLevelDivisor() * 2,
                    bottomLeft.y - Math.sin(angleBottomLeft + rotation) * handleSize * 2 / editorStore.zoomLevelDivisor() * 2,
                    0]}
                  onPointerDown={handleRotatePointerDown}
                  onPointerOver={() => setIsRotateHovered("bottomLeftCorner")}
                  onPointerOut={() => setIsRotateHovered(undefined)}
                >
                  {/* Invisible larger area */}
                  <mesh
                    position={[0, 0, -0.001]}
                  >
                    <circleGeometry args={[(handleSize * 1.5), 32]} />
                    <meshBasicMaterial
                      transparent
                      opacity={0}
                    //color="gray"
                    />
                  </mesh>

                  {/* Use Svg component from drei to render the FaRotateLeft icon */}
                  {isRotateHovered === "bottomLeftCorner" && <Svg src={svgRotateBL} scale={0.009 / editorStore.zoomLevelDivisor() * 2} rotation={new THREE.Euler(0, 0, rotation)} />}
                </group>
              </>
            )}

            {/* Top Right Handle */}
            <group
              position={[
                topRight.x + rotatedPivot.x,
                topRight.y + rotatedPivot.y,
                0,
              ]}
              onPointerDown={(e) => handlePointerDown(e, "topRight")}
              onPointerOver={() => handlePointerEnter("topRight")}
              onPointerOut={handlePointerLeave}
            >
              <mesh>
                <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                <meshBasicMaterial color="blue" />
              </mesh>
              <mesh
                position={[0, 0, handleDepth / 2]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                <meshBasicMaterial color={isHovered("topRight") ? "blue" : "white"} />
              </mesh>
            </group>


            {/* Top Left Handle */}
            <group
              position={[
                topLeft.x + rotatedPivot.x,
                topLeft.y + rotatedPivot.y,
                0,
              ]}
              onPointerDown={(e) => handlePointerDown(e, "topLeft")}
              onPointerOver={() => handlePointerEnter("topLeft")}
              onPointerOut={handlePointerLeave}
            >
              <mesh>
                <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                <meshBasicMaterial color="blue" />
              </mesh>
              <mesh
                position={[0, 0, handleDepth / 2]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                <meshBasicMaterial color={isHovered("topLeft") ? "blue" : "white"} />
              </mesh>
            </group>

            {/* Bottom Right Handle */}
            <group
              position={[
                bottomRight.x + rotatedPivot.x,
                bottomRight.y + rotatedPivot.y,
                0,
              ]}
              onPointerDown={(e) => handlePointerDown(e, "bottomRight")}
              onPointerOver={() => handlePointerEnter("bottomRight")}
              onPointerOut={handlePointerLeave}
            >
              <mesh>
                <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                <meshBasicMaterial color="blue" />
              </mesh>
              <mesh
                position={[0, 0, handleDepth / 2]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                <meshBasicMaterial color={isHovered("bottomRight") ? "blue" : "white"} />
              </mesh>
            </group>

            {/* Bottom Left Handle */}
            <group
              position={[
                bottomLeft.x + rotatedPivot.x,
                bottomLeft.y + rotatedPivot.y,
                0,
              ]}
              onPointerDown={(e) => handlePointerDown(e, "bottomLeft")}
              onPointerOver={() => handlePointerEnter("bottomLeft")}
              onPointerOut={handlePointerLeave}
            >
              <mesh>
                <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                <meshBasicMaterial color="blue" />
              </mesh>
              <mesh
                position={[0, 0, handleDepth / 2]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                <meshBasicMaterial color={isHovered("bottomLeft") ? "blue" : "white"} />
              </mesh>
            </group>

          </>
        )}
      </group>
    );
  }
);
