import * as THREE from "three";
import { FloorplannerStore } from '../../store/floorplannerStore';
import {
  WallConnectionEnd,
  WallConnectionStart,
  WallType,
} from "../../types/wallTypes";

export const updateWallConnections = (
  walls: { [x: string]: WallType },
  wall: WallType,
  dragHandle: string | null,
  updatedWalls?: string[],
  floorplannerStore?: FloorplannerStore
) => {
  const newUpdatedWalls = updatedWalls || [];
  let updated = false;
  if (!wall || !wall.connections) return;
  wall.connections?.forEach((c) => {
    if (walls[c.id]) {
      if (c.sourcePosition === WallConnectionStart) {
        // A connection from the start of the wall
        if (dragHandle === "start" || dragHandle === "middleV" || dragHandle === "middleH") {
          if (
            c.targetPosition === WallConnectionStart &&
            (walls[c.id].start.x !== wall.start.x ||
              walls[c.id].start.y !== wall.start.y)
          ) {
            walls[c.id].start = new THREE.Vector2(wall.start.x, wall.start.y);
            updated = true;
          } else if (
            c.targetPosition === WallConnectionEnd &&
            (walls[c.id].end.x !== wall.start.x ||
              walls[c.id].end.y !== wall.start.y)
          ) {
            walls[c.id].end = new THREE.Vector2(wall.start.x, wall.start.y);
            updated = true;
          }
        }
      } else if (c.sourcePosition === WallConnectionEnd) {
        // A connection from the end of the wall
        if (dragHandle === "end" || dragHandle === "middleV" || dragHandle === "middleH") {
          if (
            c.targetPosition === WallConnectionStart &&
            (walls[c.id].start.x !== wall.end.x ||
              walls[c.id].start.y !== wall.end.y)
          ) {
            walls[c.id].start = new THREE.Vector2(wall.end.x, wall.end.y);
            updated = true;
          } else if (
            c.targetPosition === WallConnectionEnd &&
            (walls[c.id].end.x !== wall.end.x ||
              walls[c.id].end.y !== wall.end.y)
          ) {
            walls[c.id].end = new THREE.Vector2(wall.end.x, wall.end.y);
            updated = true;
          }
        }
      } else {
        // A connection from a custom position of the wall
        const wallDirection = new THREE.Vector2()
          .subVectors(wall.end, wall.start)
          .normalize();
        const newPosition = new THREE.Vector2().addVectors(
          wall.start,
          wallDirection.clone().multiplyScalar(c.sourcePosition),
        );
        if (
          c.targetPosition === WallConnectionStart &&
          (walls[c.id].start.x !== newPosition.x ||
            walls[c.id].start.y !== newPosition.y)
        ) {
          walls[c.id].start = newPosition;
          updated = true;
        } else if (
          c.targetPosition === WallConnectionEnd &&
          (walls[c.id].end.x !== newPosition.x ||
            walls[c.id].end.y !== newPosition.y)
        ) {
          walls[c.id].end = newPosition;
          updated = true;
        }
      }
    }
  });
  wall.symbolAttachments?.forEach((symbolAttachment) => {
    // Update symbol attachment positions
    const symbol = floorplannerStore?.symbols[symbolAttachment.symbolId];
    if (!symbol) return;
    const tempWall = walls[wall.id];
    const wallDirection = new THREE.Vector2()
      .subVectors(tempWall.end, tempWall.start)
      .normalize();
    const newPosition = new THREE.Vector2().addVectors(
      tempWall.start,
      wallDirection.clone().multiplyScalar(symbolAttachment.positionFromStart),
    );
    if (newPosition.x !== symbol.position.x || newPosition.y !== symbol.position.y) {
      const newWallAngle = Math.atan2(
        tempWall.end.y - tempWall.start.y,
        tempWall.end.x - tempWall.start.x,
      );
      floorplannerStore?.updateSymbolProperty(
        symbolAttachment.symbolId,
        "position",
        newPosition.toArray(),
      );
      floorplannerStore?.updateSymbolProperty(
        symbolAttachment.symbolId,
        "rotation",
        newWallAngle,
      );
      // Remove any clipping polygons that belong to the symbol
      tempWall.clippingPolygons = tempWall.clippingPolygons?.filter(
        (polygon) => polygon.belongsTo !== symbol.id,
      );
      // Add new clipping polygons
      const clippingPolygon = floorplannerStore?.getClippingPolygon(
        symbol,
        wall
      );
      if (clippingPolygon) {
        tempWall.clippingPolygons = tempWall.clippingPolygons || [];
        tempWall.clippingPolygons.push(clippingPolygon);
      }
    }
  });
  // Check if any connection has been updated, and if so, recursively update connections of connected walls
  if (updated) {
    wall.connections?.forEach((c) => {
      if (!newUpdatedWalls.includes(c.id)) {
        newUpdatedWalls.push(c.id);
        updateWallConnections(
          walls,
          walls[c.id],
          null,
          newUpdatedWalls,
          floorplannerStore
        );
      }
    });
  }
};
