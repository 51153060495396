import { makeAutoObservable } from "mobx";
import React, { useRef } from "react";
import { useContext } from "react";
import { WallType, SymbolType, isSymbolType } from '../types/wallTypes';
import { floorplannerStore } from "./floorplannerStore";
import { ApolloClient } from "@apollo/client";
import * as THREE from "three";
import { invalidate } from "@react-three/fiber";

class EditorStore {
  client: ApolloClient<any> | undefined;
  floorplannerStore = floorplannerStore;
  view3D = false;
  selections = Array<WallType | SymbolType>();
  wallEditingLength = undefined as string | undefined;
  textEditing = undefined as string | undefined;
  wallShowLength = true;
  textShow = true;
  showGrid = true;
  minimized = false;
  canUndo = false;
  canRedo = false;
  clipboard: Array<WallType | SymbolType> = [];
  pasteOffset = new THREE.Vector2(0.2, 0.2);
  lastPastePosition: THREE.Vector2 | null = null;
  camera: THREE.PerspectiveCamera | THREE.OrthographicCamera | null = null;
  wallConstructionMode = false;
  wallConstructionModeAddNewWall = false;
  movementOffset = 0.001;
  panning = false;

  constructor() {
    makeAutoObservable(this);
    this.updateUndoRedoState(); // Initialize undo/redo state
    window.addEventListener("keydown", this.handleKeyDown);
  }

  dispose() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  initialize(client: ApolloClient<any>) {
    this.client = client;
  }

  setCamera(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera) {
    this.camera = camera;
  }

  zoomIn = () => {
    if (this.camera) {
      this.camera.zoom = Math.min(this.camera.zoom + 0.05, 7); // Set max zoom level
      this.camera.updateProjectionMatrix();
      invalidate();
    }
  };

  zoomOut = () => {
    if (this.camera && this.camera.zoom > 0.1) {
      this.camera.zoom = Math.max(this.camera.zoom - 0.05, 0.2); // Set min zoom level
      this.camera.updateProjectionMatrix();
      invalidate();
    }
  };

  resetZoom = () => {
    if (this.camera) {
      this.camera.zoom = 1;
      this.camera.updateProjectionMatrix();
      // Reset panning
      this.camera.position.set(0, 0, 20);
      invalidate();
    }
  };

  zoomLevel = () => {
    return this.camera?.zoom || 1;
  }

  zoomLevelDivisor = () => {
    // Return the divisor
    return this.zoomLevel() / 1.4;
  }

  setPanning = (panning: boolean) => {
    this.panning = panning;
  }

  // Method to update undo/redo state
  updateUndoRedoState = () => {
    this.canUndo = this.floorplannerStore.undoStack.length > 0;
    this.canRedo = this.floorplannerStore.redoStack.length > 0;
  };

  // Undo action
  undo = () => {
    this.floorplannerStore.undo();
    this.updateUndoRedoState();
  };

  // Redo action
  redo = () => {
    this.floorplannerStore.redo();
    this.updateUndoRedoState();
  };

  copy = () => {
    // Copy selected elements to clipboard
    this.clipboard = this.selections.map(selection => ({ ...selection }));
    this.lastPastePosition = null;
  };

  // Paste action with position offset or at mouse position
  paste = () => {

    this.clipboard.forEach(item => {
      const itemPosition = item.type === "wall" ? item.start : item.position;
      // Always paste pasteOffset away from the last paste position, but the first paste is at item.position + pasteOffset
      const newPosition = this.lastPastePosition ? this.lastPastePosition.clone().add(this.pasteOffset) : itemPosition.clone().add(this.pasteOffset);

      if (item.type === "wall") {
        this.floorplannerStore.cloneWall(item.id, newPosition);
      } else if (isSymbolType(item)) {
        this.floorplannerStore.cloneSymbol(item.id, newPosition);
      }

      this.lastPastePosition = newPosition;
    });

  };

  duplicateSelected = () => {
    this.copy();
    this.paste();
  }

  bringToFront = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.bringWallToFront(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.sendToFront(selection.id);
      }
    });
  }

  sendToBack = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.sendWallToBack(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.sendToBack(selection.id);
      }
    });
  }

  bringForward = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.bringWallForward(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.bringForward(selection.id);
      }
    });
  }

  bringBackward = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.sendWallBackward(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.bringBackward(selection.id);
      }
    });
  }
  /*   addText = () => {
      this.selections.forEach((selection) => {
          if (selection.type === "text") {
            floorplannerStore.addSymbol("text", [0, 0]);
          } 
      });
    }
       */


  // Method to get the visible bounds based on the current camera position and zoom level

  getVisibleBounds = () => {
    if (!this.camera) return null;

    const aspectRatio = window.innerWidth / window.innerHeight;

    if (this.camera instanceof THREE.PerspectiveCamera) {
      // Handle Perspective Camera bounds calculation
      const halfFov = THREE.MathUtils.degToRad(this.camera.fov / 2); // Convert vertical FOV to radians
      const distance = Math.abs(this.camera.position.z); // Distance from camera to the scene origin (assuming z-axis is depth)
      const halfHeight = distance * Math.tan(halfFov); // Visible height at the camera's current position
      const halfWidth = halfHeight * aspectRatio;

      const visibleArea = {
        xMin: this.camera.position.x - halfWidth,
        xMax: this.camera.position.x + halfWidth,
        yMin: this.camera.position.y - halfHeight,
        yMax: this.camera.position.y + halfHeight,
      };

      return visibleArea;
    } else if (this.camera instanceof THREE.OrthographicCamera) {
      // Handle Orthographic Camera bounds calculation
      const halfWidth = (this.camera.right - this.camera.left) / 2 / this.camera.zoom;
      const halfHeight = (this.camera.top - this.camera.bottom) / 2 / this.camera.zoom;

      const visibleArea = {
        xMin: this.camera.position.x - halfWidth,
        xMax: this.camera.position.x + halfWidth,
        yMin: this.camera.position.y - halfHeight,
        yMax: this.camera.position.y + halfHeight,
      };

      return visibleArea;
    }

    return null; // If camera type is not handled
  };


  // Helper to calculate the center of the visible area
  getVisibleCenter = () => {
    const visibleBounds = this.getVisibleBounds();
    if (!visibleBounds) return [0, 0];

    const centerX = (visibleBounds.xMin + visibleBounds.xMax) / 2;
    const centerY = (visibleBounds.yMin + visibleBounds.yMax) / 2;
    return [centerX, centerY];
  };


  handleKeyDown = (event: KeyboardEvent) => {
    const activeElement = document.activeElement;
    const isInputField =
      activeElement?.tagName === "INPUT" ||
      activeElement?.tagName === "TEXTAREA" ||
      (activeElement?.getAttribute("contenteditable") === "true");

    // Always handle some keys regardless of input field focus
    if (this.wallConstructionMode && event.key === "Escape") {
      event.preventDefault(); // Prevent any default behavior of the input field
      this.wallConstructionMode = false; // or any other action you need for "Esc"
    } else if(this.wallConstructionMode && event.key === "Enter") {
      this.wallConstructionModeAddNewWall = true
    }

    // Only handle other shortcuts if not focused on input field
    if (!isInputField) {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === "z") {
          // Ctrl+Z or Cmd+Z
          event.preventDefault();
          this.undo();
        } else if (event.key === "y" || (event.shiftKey && event.key === "z")) {
          // Ctrl+Y or Ctrl+Shift+Z (or Cmd+Shift+Z on macOS)
          event.preventDefault();
          this.redo();
        } else if (event.key === "c") {
          // Ctrl+C or Cmd+C
          event.preventDefault();
          this.copy();
        } else if (event.key === "v") {
          // Ctrl+V or Cmd+V
          event.preventDefault();
          this.paste();
        }
      }

      // Handle zoom in and zoom out with keyboard shortcuts
      if (event.key === "=" || event.key === "+") {
        event.preventDefault();
        this.zoomIn();
      } else if (event.key === "-") {
        event.preventDefault();
        this.zoomOut();
      } else if (event.key === "0") {
        event.preventDefault();
        this.resetZoom();
      }

      // Handle movement with arrow keys
      if (event.key === "ArrowUp") {
        event.preventDefault();
        this.moveSelections(0, this.movementOffset); // Move up
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        this.moveSelections(0, -this.movementOffset); // Move down
      } else if (event.key === "ArrowLeft") {
        event.preventDefault();
        this.moveSelections(-this.movementOffset, 0); // Move left
      } else if (event.key === "ArrowRight") {
        event.preventDefault();
        this.moveSelections(this.movementOffset, 0); // Move right
      }

      // Wall construction mode
      if (event.key === "w") {
        this.wallConstructionMode = !this.wallConstructionMode;
      }
    }
  };

  // Handle mouse wheel/trackpad zoom events
  handleWheel = (event: WheelEvent) => {
    if (this.camera && !this.view3D) {
      event.preventDefault(); // Prevent default scrolling behavior
      const delta = Math.sign(event.deltaY);

      if (delta > 0) {
        this.zoomOut(); // Zoom out if scrolling down or zooming out on trackpad
      } else if (delta < 0) {
        this.zoomIn(); // Zoom in if scrolling up or zooming in on trackpad
      }
    }
  };

  setWallConstructionMode = (mode: boolean) => {
    this.wallConstructionMode = mode;
  }

  setWallConstructionModeAddNewWall = (mode: boolean) => {
    this.wallConstructionModeAddNewWall = mode;
  }

  // Method to move selected elements by a given offset
  moveSelections = (xOffset: number, yOffset: number) => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        // Move the wall by updating its positions
        selection.start.add(new THREE.Vector2(xOffset, yOffset));
        selection.end.add(new THREE.Vector2(xOffset, yOffset));
        selection.controlPoint?.add(new THREE.Vector2(xOffset, yOffset));
        this.floorplannerStore.updateWallPosition(selection.id, selection.start, selection.end);
      } else if (isSymbolType(selection)) {
        // Move the symbol by updating its position
        selection.position.add(new THREE.Vector2(xOffset, yOffset));
        this.floorplannerStore.updateSymbolPosition(selection.id, selection.position);
      }
    });

    invalidate(); // Trigger re-render to reflect the changes
  };

  setWallEditingLength = (length: string | undefined) => {
    this.wallEditingLength = length
  }

  setWallShowLength = (show: boolean) => {
    this.wallShowLength = show;
  }
  setTextEditing = (text: string | undefined) => {
    this.textEditing = text
  }

  setTextShow = (show: boolean) => {
    this.textShow = show;
  }
  setSelections = (selections: Array<WallType | SymbolType>) => {
    // Loop through selections and select them in the floorplanner
    floorplannerStore.unSelectAll();
    selections.forEach((selection) => {
      switch (selection.type) {
        case "wall":
          floorplannerStore.selectWall(selection.id);
          break;
        case "symbol":
        case "door":
        case "doubleDoor":
        case "window":
        case "circleStairs":
        case "rectStairs":
        case "svg":
        case "text":
          floorplannerStore.selectSymbol(selection.id);
          break;
        default:
          console.error("Unknown selection type", selection);
      }
    });
    this.selections = selections;
  };

  clearSelections = () => {
    floorplannerStore.unSelectAll();
    this.selections = [];
  };

  addSelection = (selection: WallType | SymbolType) => {
    this.selections.push(selection);
  };

  removeSelection = (selection: WallType | SymbolType) => {
    const index = this.selections.findIndex((s) => s.id === selection.id);
    if (index > -1) {
      this.selections.splice(index, 1);
    }
  };

  setView3D = (view3D: boolean) => {
    this.view3D = view3D;
  };

  setShowGrid = (showGrid: boolean) => {
    this.showGrid = showGrid;
  };

  setMinimized = (minimized: boolean) => {
    this.minimized = minimized;
  };

  // to delete the selection with Backspace
  delete = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        this.floorplannerStore.removeWall(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.removeSymbol(selection.id);
      }
    });
    this.clearSelections();
    this.updateUndoRedoState();
  };
}

export const editorStore = new EditorStore();
export const editorStoreContext = React.createContext<EditorStore>(editorStore);
export const useEditorStore = () => useContext(editorStoreContext);
