import { WallShapeType } from "../../types/wallTypes";
import * as THREE from "three";
import ClipperLib from "clipper-lib";
import { createShapes } from "./createShapes";
import { FloorplannerStore } from '../../store/floorplannerStore';
import { Floorplan } from "../../gql";

export const fillMeshWalls = (
    filledLines: WallShapeType[],
    leftLines: WallShapeType[],
    rightLines: WallShapeType[],
    endCaps: WallShapeType[],
    newMeshWalls: THREE.Mesh[],
    floorplannerStore: FloorplannerStore
) => {
    const {
        walls,
        setWalls,
        wallWidth,
        lineColor,
        fillColor,
        hoverColor,
    } = floorplannerStore;

    const addHolesToShapes = (
        shapes: THREE.Shape[],
        polygons: THREE.Vector2[][],
    ) => {
        if (polygons.length === 0) return;

        const scale = 10000000;
        const holeInset = 0.99998; // Adjust this value to control the offset (0.99 for 1% inward)

        shapes.forEach((shape) => {
            const shapePoints: ClipperLib.Path = shape
                .getPoints()
                .map((p) => ({ X: p.x * scale, Y: p.y * scale }));

            polygons.forEach((polygon) => {
                if (polygon.length === 0) return;

                const polygonPoints: ClipperLib.Path = polygon.map((p) => ({
                    // Scale the polygon coordinates outward (we always want the hole to be larger/outside of the shape) to add a margin
                    X: p.x * (scale + 1),
                    Y: p.y * (scale + 1),
                }));

                const clipper = new ClipperLib.Clipper();
                clipper.AddPath(shapePoints, ClipperLib.PolyType.ptSubject, true);
                const solution: ClipperLib.Paths = [];
                clipper.AddPath(polygonPoints, ClipperLib.PolyType.ptClip, true);
                clipper.Execute(ClipperLib.ClipType.ctIntersection, solution);

                if (solution.length === 0) return;

                solution.forEach((ring) => {
                    const trimmedPolygon = ring.map(
                        (point) => new THREE.Vector2(point.X / scale, point.Y / scale),
                    );

                    if (trimmedPolygon.length >= 3) {
                        // Ensure valid polygon
                        // Scale the hole coordinates inward (we always want the hole to be smaller/inside of the shape)
                        const center = trimmedPolygon
                            .reduce((sum, p) => sum.add(p), new THREE.Vector2(0, 0))
                            .multiplyScalar(1 / trimmedPolygon.length);
                        const insetPolygon = trimmedPolygon.map((p) =>
                            p.clone().sub(center).multiplyScalar(holeInset).add(center),
                        );

                        const hole = new THREE.Path().setFromPoints(insetPolygon);
                        shape.holes.push(hole);

                        // const debugShape = new THREE.Shape();
                        // debugShape.curves = hole.curves;
                        // debugDrawShape(debugShape, 0xff00ff); // Debug color for holes
                    } else {
                        console.warn("Invalid hole shape", trimmedPolygon);
                    }
                });

                //debugDrawShape(shape, 0x0000ff); // Debug color for shapes
            });
        });
    };

    // const debugDrawShape = (shape: THREE.Shape, color: number) => {
    //   const points = shape.getPoints();
    //   points.push(points[0]);
    //   const geometry = new THREE.BufferGeometry().setFromPoints(points);
    //   const material = new THREE.LineBasicMaterial({ color });
    //   const line = new THREE.Line(geometry, material);
    //   line.position.z = 0.001;
    //   scene.add(line);

    //   shape.holes.forEach((hole) => {
    //     const holePoints = hole.getPoints();
    //     const holeGeometry = new THREE.BufferGeometry().setFromPoints(
    //       holePoints,
    //     );
    //     const holeMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
    //     const holeLine = new THREE.Line(holeGeometry, holeMaterial);
    //     holeLine.position.z = 0.001;
    //     scene.add(holeLine);
    //   });
    // };

    for (let i = 0; i < Object.keys(walls).length; i++) {
        const wall = walls[Object.keys(walls)[i]];

        const innerShapes = createShapes(
            filledLines[i],
            "inner",
            wall.lineWeight as number,
            wall.wallWidth as number,
        );
        const leftShapes = createShapes(
            leftLines[i],
            "left",
            wall.lineWeight as number,
            wall.wallWidth as number,
        );
        const rightShapes = createShapes(
            rightLines[i],
            "right",
            wall.lineWeight as number,
            wall.wallWidth as number,
        );
        const endCapsShapes = createShapes(
            endCaps[i],
            "endCaps",
            wall.lineWeight as number,
            wall.wallWidth as number,
        );

        const clippingPolygons = wall.clippingPolygons?.map(
            (polygon) => polygon.polygon,
        );
        if (clippingPolygons) {
            addHolesToShapes(innerShapes, clippingPolygons);
            addHolesToShapes(leftShapes, clippingPolygons);
            addHolesToShapes(rightShapes, clippingPolygons);
            addHolesToShapes(endCapsShapes, clippingPolygons);
        }

        const innerGeometry = new THREE.ShapeGeometry(innerShapes);
        const leftLineGeometry = new THREE.ShapeGeometry(leftShapes);
        const rightLineGeometry = new THREE.ShapeGeometry(rightShapes);
        const endCapsGeometry = new THREE.ShapeGeometry(endCapsShapes);

        const customFillColor =
            walls[Object.keys(walls)[i]]?.fillColor || fillColor;
        const customLineColor =
            walls[Object.keys(walls)[i]]?.lineColor || lineColor;
        const filledMaterial = new THREE.MeshBasicMaterial({
            color: customFillColor,
            side: THREE.DoubleSide,
        });
        const lineMaterial = new THREE.MeshBasicMaterial({
            color: customLineColor,
            side: THREE.DoubleSide,
        });

        const innerMesh = new THREE.Mesh(innerGeometry, filledMaterial);
        const leftLineMesh = new THREE.Mesh(leftLineGeometry, lineMaterial);
        const rightLineMesh = new THREE.Mesh(rightLineGeometry, lineMaterial);
        const endCapsMesh = new THREE.Mesh(endCapsGeometry, lineMaterial);

        innerMesh.userData.type = "fill";
        leftLineMesh.userData.type = "line";
        rightLineMesh.userData.type = "line";
        endCapsMesh.userData.type = "line";

        const mesh = new THREE.Mesh();
        mesh.add(innerMesh);
        mesh.add(leftLineMesh);
        mesh.add(rightLineMesh);
        mesh.add(endCapsMesh);

        mesh.userData.index = i;
        mesh.userData.id = Object.keys(walls)[i];
        newMeshWalls.push(mesh);
    }
}
