import { WallConnectionEnd, WallType } from "../../types/wallTypes";
import * as THREE from 'three';
import { removeWallConnection } from "./removeWallConnection";
import { snapToOtherWalls } from "./snapToOtherWalls";

const snapAngle = (angle: number, snapInterval: number) => {
    return Math.round(angle / snapInterval) * snapInterval;
};

/**
 * Drag the end handle of the wall, it will move the end of the wall maintaining connections and updates their positions
 * @param wall The wall to drag
 * @param endOffsetX The end x offset of the wall
 * @param endOffsetY The end y offset of the wall
 * @param newX The new x position of the wall
 * @param newY The new y position of the wall
 * @param tempWalls The temporary walls object
 * @param dragEndFreeToSnap If the end is free to snap
 * @param isShiftPressed If the Shift key is pressed
 * @param delta The delta vector
 * @returns boolean If the wall was snapped
 */
export const dragEndHandle = (
    wall: WallType,
    endOffsetX: number,
    endOffsetY: number,
    newX: number,
    newY: number,
    tempWalls: { [key: string]: WallType },
    dragEndFreeToSnap: boolean,
    isShiftPressed: boolean,
    delta: THREE.Vector2,
): boolean => {
    let snapped = false;
    let newDelta = new THREE.Vector2(newX - endOffsetX, newY - endOffsetY).sub(
        wall.end,
    );
    const newEndPosition = wall.end.clone().add(newDelta);
    // Angle snapping
    if (!isShiftPressed) {
        // Snap to specific angles only if Shift is NOT held
        const angle = Math.atan2(newEndPosition.y - wall.start.y, newEndPosition.x - wall.start.x);
        const snappedAngle = snapAngle(angle, Math.PI / 4); // Snap to 45-degree intervals

        // Compare if the current angle is close to a snapped angle
        const angleDiff = Math.abs(angle - snappedAngle);
        // snap tolerance approximately 1 degrees
        if (angleDiff < 0.0175) {
            // Calculate new position based on snapped angle
            const length = newEndPosition.distanceTo(wall.start);
            newEndPosition.x = wall.start.x + length * Math.cos(snappedAngle);
            newEndPosition.y = wall.start.y + length * Math.sin(snappedAngle);
        }
    }

    if (dragEndFreeToSnap) {
        // Always snap to other walls, even when Shift is held
        removeWallConnection(tempWalls, wall, WallConnectionEnd);
        snapped = snapToOtherWalls(tempWalls, wall, WallConnectionEnd, newEndPosition, false);

    }

    if (!snapped) {
        tempWalls[wall.id].end.copy(newEndPosition);
    }

    delta.x = newDelta.x;
    delta.y = newDelta.y;
    return snapped;
}