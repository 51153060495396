import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { editorStore } from "../../store/editorStore";
import { floorplannerStore } from "../../store/floorplannerStore";
import { HiOutlinePlus, HiChevronDown } from "react-icons/hi";
import boniLogo from "../../../src/assets/boni_logo2.png";
import React from 'react';
import { SymbolsPanel } from "./SymbolsPanel";
import { BsLayoutSidebar } from "react-icons/bs";
import { FiLayout } from "react-icons/fi";
import { FaRegCircle } from "react-icons/fa";
import { LuArmchair } from "react-icons/lu";
import { LuWrench } from "react-icons/lu";
import { FiHeart } from "react-icons/fi";
import { LuUploadCloud } from "react-icons/lu";
import { TemplatesPanel } from "./TemnplatesPanel";
import { ProductsPanel } from "./ProductsPanel";
import { PaintWallpaperPanel } from "./PaintWallpaperPanel";
import { FavoritesPanel } from "./FavoritesPanel";
import { UploadsPanel } from "./UploadsPanel";
import { CustomProductsPanel } from "./CustomProductsPanel";
import { Tooltip } from "flowbite-react";

const LeftPanel = observer(() => {
  const [isSymbolsPanelVisible, setSymbolsPanelVisible] = useState(false);
  const [symbolsPanelPosition, setSymbolsPanelPosition] = useState({ top: 0, left: 0 });
  const symbolsPanelRef = useRef<HTMLDivElement>(null);
  const symbolInitiatorRef = useRef<HTMLDivElement>(null);
  const [isTemplatesPanelVisible, setTemplatesPanelVisible] = useState(false);
  const [templatesPanelPosition, setTemplatesPanelPosition] = useState({ top: 0, left: 0 });
  const templatesPanelRef = useRef<HTMLDivElement>(null);
  const templatesInitiatorRef = useRef<HTMLDivElement>(null);
  const [isProductsPanelVisible, setProductsPanelVisible] = useState(false);
  const [productsPanelPosition, setProductsPanelPosition] = useState({ top: 0, left: 0 });
  const productsPanelRef = useRef<HTMLDivElement>(null);
  const productsInitiatorRef = useRef<HTMLDivElement>(null);
  const [isPaintWallpaperPanelVisible, setPaintWallpaperPanelVisible] = useState(false);
  const [paintWallpaperPanelPosition, setPaintWallpaperPanelPosition] = useState({ top: 0, left: 0 });
  const paintWallpaperPanelRef = useRef<HTMLDivElement>(null);
  const paintWallpaperInitiatorRef = useRef<HTMLDivElement>(null);
  const [isFavoritesPanelVisible, setFavoritesPanelVisible] = useState(false);
  const [favoritesPanelPosition, setFavoritesPanelPosition] = useState({ top: 0, left: 0 });
  const favoritesPanelRef = useRef<HTMLDivElement>(null);
  const favoritesInitiatorRef = useRef<HTMLDivElement>(null);
  const [isUploadsPanelVisible, setUploadsPanelVisible] = useState(false);
  const [uploadsPanelPosition, setUploadsPanelPosition] = useState({ top: 0, left: 0 });
  const uploadsPanelRef = useRef<HTMLDivElement>(null);
  const uploadsInitiatorRef = useRef<HTMLDivElement>(null);
  const [isCustomProductsPanelVisible, setCustomProductsPanelVisible] = useState(false);
  const [customProductsPanelPosition, setCustomProductsPanelPosition] = useState({ top: 0, left: 0 });
  const customProductsPanelRef = useRef<HTMLDivElement>(null);
  const customProductsInitiatorRef = useRef<HTMLDivElement>(null);
  const [systemMenuOpen, setSystemMenuOpen] = useState(false);
  const systemMenuRef = useRef<HTMLDivElement | null>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(floorplannerStore.name);

  const handleNameClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const handleBlurOrSubmit = () => {
    floorplannerStore.setName(newName);
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleBlurOrSubmit();
    }
  };


  const openSymbolsPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setSymbolsPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setSymbolsPanelVisible(true);
  };

  const closeSymbolsPanel = () => {
    setSymbolsPanelVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the SymbolsPanel and not the initiator
      if (
        symbolsPanelRef.current &&
        !symbolsPanelRef.current.contains(event.target as Node) &&
        symbolInitiatorRef.current &&
        !symbolInitiatorRef.current.contains(event.target as Node)
      ) {
        closeSymbolsPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [symbolsPanelRef, symbolInitiatorRef]);

  const openTemplatesPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setTemplatesPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setTemplatesPanelVisible(true);
  };

  const closeTemplatesPanel = () => {
    setTemplatesPanelVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the TemplatesPanel and not the initiator
      if (
        templatesPanelRef.current &&
        !templatesPanelRef.current.contains(event.target as Node) &&
        templatesInitiatorRef.current &&
        !templatesInitiatorRef.current.contains(event.target as Node)
      ) {
        closeTemplatesPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [templatesPanelRef, templatesInitiatorRef]);

  const openProductsPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setProductsPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setProductsPanelVisible(true);
  };

  const closeProductsPanel = () => {
    setProductsPanelVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the ProductsPanel and not the initiator
      if (
        productsPanelRef.current &&
        !productsPanelRef.current.contains(event.target as Node) &&
        productsInitiatorRef.current &&
        !productsInitiatorRef.current.contains(event.target as Node)
      ) {
        closeProductsPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [productsPanelRef, productsInitiatorRef]);

  const openPaintWallpaperPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setPaintWallpaperPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setPaintWallpaperPanelVisible(true);
  }

  const closePaintWallpaperPanel = () => {
    setPaintWallpaperPanelVisible(false);
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the PaintWallpaperPanel and not the initiator
      if (
        paintWallpaperPanelRef.current &&
        !paintWallpaperPanelRef.current.contains(event.target as Node) &&
        paintWallpaperInitiatorRef.current &&
        !paintWallpaperInitiatorRef.current.contains(event.target as Node)
      ) {
        closePaintWallpaperPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [paintWallpaperPanelRef, paintWallpaperInitiatorRef]);

  const openFavoritesPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setFavoritesPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setFavoritesPanelVisible(true);
  }

  const closeFavoritesPanel = () => {
    setFavoritesPanelVisible(false);
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the FavoritesPanel and not the initiator
      if (
        favoritesPanelRef.current &&
        !favoritesPanelRef.current.contains(event.target as Node) &&
        favoritesInitiatorRef.current &&
        !favoritesInitiatorRef.current.contains(event.target as Node)
      ) {
        closeFavoritesPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [favoritesPanelRef, favoritesInitiatorRef]);

  const openUploadsPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setUploadsPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setUploadsPanelVisible(true);
  }

  const closeUploadsPanel = () => {
    setUploadsPanelVisible(false);
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the UploadsPanel and not the initiator
      if (
        uploadsPanelRef.current &&
        !uploadsPanelRef.current.contains(event.target as Node) &&
        uploadsInitiatorRef.current &&
        !uploadsInitiatorRef.current.contains(event.target as Node)
      ) {
        closeUploadsPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [uploadsPanelRef, uploadsInitiatorRef]);

  const openCustomProductsPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setCustomProductsPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setCustomProductsPanelVisible(true);
  }

  const closeCustomProductsPanel = () => {
    setCustomProductsPanelVisible(false);
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the CustomProductsPanel and not the initiator
      if (
        customProductsPanelRef.current &&
        !customProductsPanelRef.current.contains(event.target as Node) &&
        customProductsInitiatorRef.current &&
        !customProductsInitiatorRef.current.contains(event.target as Node)
      ) {
        closeCustomProductsPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [customProductsPanelRef, customProductsInitiatorRef]);

  // Handle click outside systemMenu to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (systemMenuRef.current && !systemMenuRef.current.contains(event.target as Node)) {
        setSystemMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [systemMenuRef]);

  const TitleEditBox = () => {
    return (
      <>
        {/* Floorplan title edit box */}
        < div className="relative inline-flex w-full items-left rounded-sm p-3 pt-0 cursor-text" >
          {
            isEditing ? (
              <input
                type="text"
                value={newName}
                onChange={handleNameChange}
                onBlur={handleBlurOrSubmit}
                onKeyDown={handleKeyDown}
                autoFocus
                className="w-full h-full text-zinc-950 font-['DM Sans'] text-sm font-normal bg-stone-100 border-none outline-none"
                style={{ boxSizing: "border-box" }} // Ensures padding and borders are considered in width calculation
              />
            ) : (
              <div
                className="text-zinc-950 font-['DM Sans'] text-sm font-medium cursor-pointer text-ellipsis overflow-hidden whitespace-nowrap"
                onClick={handleNameClick}
                style={{ boxSizing: "border-box" }}
              >
                {floorplannerStore.name}
              </div>
            )}
        </div >
      </>
    );
  }


  return (
    <div
      className="sidebar-left select-none flex flex-col items-left justify-start gap-1"
      onClick={(event) => event.stopPropagation()} // Prevent clicks inside the sidebar from affecting SymbolsPanel visibility
    >
      {/* Logo section */}
      <div className="flex items-col justify-between pb-2">
        <div
          className="flex items-center ml-3 mt-3 cursor-pointer"
          onClick={() => setSystemMenuOpen(!systemMenuOpen)}
          ref={systemMenuRef}
        >
          <div
            className="flex items-center bg-bonigold"
            style={{ width: 34, height: 34 }}
          >
            <img
              src={boniLogo}
              alt="boni logo black"
              className="w-6 lg:w-12"
              style={{ width: 34 }}
            />
          </div>
          <HiChevronDown className="h-4 w-4" />
          {/* System Menu */}
          {systemMenuOpen && (
            <div className="fixed left-10 top-16 w-36 bg-black text-white border border-gray-300 shadow-lg rounded-lg py-2 z-20">
              <div
                className="px-4 py-2 cursor-pointer flex items-center justify-center gap-2 flex-row hover:bg-[#66645F]"
                onClick={() => {
                }}
              >
                My Page
              </div>
              <div
                className="px-4 py-2 cursor-pointer flex items-center justify-center gap-2 flex-row hover:bg-[#66645F]"
                onClick={() => {
                }}
              >
                Products
              </div>
              <div
                className="px-4 py-2 cursor-pointer flex items-center justify-center gap-2 flex-row hover:bg-[#66645F]"
                onClick={() => {
                }}
              >
                Gallery
              </div>
              <div className="border-b border-[#66645F]" />
              <div
                className="px-4 py-2 cursor-pointer flex items-center justify-center gap-2 flex-row hover:bg-[#66645F]"
                onClick={() => {
                }}
              >
                Designers
              </div>
              <div className="border-b border-[#66645F]" />
              <div
                className="px-4 py-2 cursor-pointer flex items-center justify-center gap-2 flex-row hover:bg-[#66645F]"
                onClick={() => {
                }}
              >
                Tutorials
              </div>
            </div>
          )}
        </div>
        <Tooltip content="Minimize" placement="bottom">
        <div
          className={`flex items-center mr-3 mt-2 cursor-pointer`}
          onClick={() => editorStore.setMinimized(!editorStore.minimized)}
        >
          <div className={`flex items-center justify-center rounded-sm w-[24px] h-[24px] ${editorStore.minimized ? " bg-eggshell300" : ""}`}>
            <BsLayoutSidebar style={{ strokeWidth: 0.5 }} />
          </div>
          </div>
        </Tooltip>
      </div>

      {!editorStore.minimized && (
        <>
          <TitleEditBox />
          {/* Divider line 100% width */}
          <div className="border-b border-stone-200 w-full" />
          {/* Links */}
          <div className="font-dm text-xs font-normal text-black p-1 pt-1 mb-2">
            {/* Elements */}
            <div className="inline-flex w-full flex-col items-start justify-start gap-2 p-2 pb-1">
            Building blocks
            </div>
            <div
              className={`inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer ${isTemplatesPanelVisible ? "bg-eggshell100" : ""}`}
              onClick={isTemplatesPanelVisible ? closeTemplatesPanel : openTemplatesPanel}
              ref={templatesInitiatorRef} // Ref for the initiator
            >
              {/* Floorplan Templates */}
              <FiLayout style={{ strokeWidth: 2 }} size={19} />
              Floorplan templates
              {/* TemplatesPanel */}
              {isTemplatesPanelVisible && (
                <TemplatesPanel
                  position={templatesPanelPosition}
                  onClose={closeTemplatesPanel}
                  dropdownRef={templatesPanelRef}
                />
              )}
            </div>
            <div
              className={`inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer ${isSymbolsPanelVisible ? "bg-eggshell100" : ""}`}
              onClick={isSymbolsPanelVisible ? closeSymbolsPanel : openSymbolsPanel}
              ref={symbolInitiatorRef} // Ref for the initiator
            >
              <FaRegCircle style={{ strokeWidth: 2 }} size={19} />
              Symbols
              {/* SymbolsPanel */}
              {isSymbolsPanelVisible && (
                <SymbolsPanel
                  position={symbolsPanelPosition}
                  onClose={closeSymbolsPanel}
                  dropdownRef={symbolsPanelRef}
                />
              )}
            </div>

            {/* Products */}
           {/*  <div
              className="inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer"
              onClick={isProductsPanelVisible ? closeProductsPanel : openProductsPanel}
              ref={productsInitiatorRef} // Ref for the initiator
            >
              <LuArmchair style={{ strokeWidth: 2 }} size={19} />
              Products
              // ProductsPanel 
              {isProductsPanelVisible && (
                <ProductsPanel
                  position={productsPanelPosition}
                  onClose={closeProductsPanel}
                  dropdownRef={productsPanelRef}
                />
              )}
            </div> */}

            {/* Paint & Wallpaper */}
          {/*   <div
              className="inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer"
              onClick={isPaintWallpaperPanelVisible ? closePaintWallpaperPanel : openPaintWallpaperPanel}
              ref={paintWallpaperInitiatorRef} // Ref for the initiator
            >
              <LuWrench style={{ strokeWidth: 2 }} size={19} />
              Paint & Wallpaper
              // PaintWallpaperPanel 
              {isPaintWallpaperPanelVisible && (
                <PaintWallpaperPanel
                  position={paintWallpaperPanelPosition}
                  onClose={closePaintWallpaperPanel}
                  dropdownRef={paintWallpaperPanelRef}
                />
              )}
            </div> */}

            {/* Favorites */}
            {/* <div
              className="inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer"
              onClick={isFavoritesPanelVisible ? closeFavoritesPanel : openFavoritesPanel}
              ref={favoritesInitiatorRef} // Ref for the initiator
            >
              <FiHeart style={{ strokeWidth: 2 }} size={19} />
              Favorites
              // FavoritesPanel 
              {isFavoritesPanelVisible && (
                <FavoritesPanel
                  position={favoritesPanelPosition}
                  onClose={closeFavoritesPanel}
                  dropdownRef={favoritesPanelRef}
                />
              )}
            </div> */}

            {/* Upload */}
            {/* <div
              className="inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer"
              onClick={isUploadsPanelVisible ? closeUploadsPanel : openUploadsPanel}
              ref={uploadsInitiatorRef} // Ref for the initiator
            >
              <LuUploadCloud style={{ strokeWidth: 2 }} size={19} />
              Upload
              // UploadsPanel 
              {isUploadsPanelVisible && (
                <UploadsPanel
                  position={uploadsPanelPosition}
                  onClose={closeUploadsPanel}
                  dropdownRef={uploadsPanelRef}
                />
              )}
            </div> */}

            {/* Custom Products */}
            {/* <div
              className="inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer"
              onClick={isCustomProductsPanelVisible ? closeCustomProductsPanel : openCustomProductsPanel}
              ref={customProductsInitiatorRef} // Ref for the initiator
            >
              <LuArmchair style={{ strokeWidth: 2 }} size={19} />
              Custom products
              // CustomProductsPanel 
              {isCustomProductsPanelVisible && (
                <CustomProductsPanel
                  position={customProductsPanelPosition}
                  onClose={closeCustomProductsPanel}
                  dropdownRef={customProductsPanelRef}
                />
              )}
            </div> */}
          </div>
        </>
      )}

    </div>
  );
});

export default LeftPanel;
