/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, ReactNode, useRef } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { DoorType, isCircleStairsType, isDoorType, isDoubleDoorType, isRectStairsType, isSvgType, isTextType, isWindowType, LineType, WindowType } from "../../types/wallTypes";
import Grid from "../FloorPlan/Grid";
import { GizmoViewcube, Line, OrbitControls } from "@react-three/drei";
import WallStructure from "../FloorPlan/WallStructure";
import { editorStoreContext } from "../../store/editorStore";
import Door from "../FloorPlan/Door";
import Window from "../FloorPlan/Window";
import { Stats, GizmoHelper, GizmoViewport } from "@react-three/drei";
import {
  FloorplannerStoreContext,
  floorplannerStore,
} from "../../store/floorplannerStore";
import { observer } from "mobx-react-lite";
import * as THREE from "three";
import DoubleDoor from "../FloorPlan/DoubleDoor";
import CircleStairs from "../FloorPlan/CircleStairs";
import RectangularStairs from "../FloorPlan/RectStairs";
import RectStairs from "../FloorPlan/RectStairs";
import SvgSymbol from "../FloorPlan/SvgSymbol";
import TextSymbol from "../FloorPlan/TextSymbol";
import CameraSetup from "../FloorPlan/CameraSetup";
import PanningPlane from "./PanningPlane";
import WallConstructionPlane from "./WallConstructionPlane";
import SelectionPlane from "./SelectionPlane";

interface StoreProviderProps {
  children: ReactNode;
}

export const FloorplannerStoreProvider: React.FC<StoreProviderProps> = ({
  children,
}) => {
  const { scene, camera } = useThree();
  const [boundingBox, setBoundingBox] = useState<THREE.Box3 | null>(null);

  // Compute the bounding box for all objects in the scene under this provider
  useEffect(() => {
    if (scene && scene.children.length > 0) {
      const box = new THREE.Box3();
      scene.children.forEach((child) => {
        box.expandByObject(child); // Expand the bounding box to include the current object
      });
      floorplannerStore.boundingBox = box;
    }
  }, [scene.children]);

  return (
    <FloorplannerStoreContext.Provider value={floorplannerStore}>
      {children}
    </FloorplannerStoreContext.Provider>
  );
};

const FloorPlan: React.FC = observer(() => {
  const editorStore = React.useContext(editorStoreContext);
  const { saveToCloud } = React.useContext(FloorplannerStoreContext);
  const symbols = floorplannerStore.symbols;

  // Auto-save every second
  useEffect(() => {
    const interval = setInterval(() => {
      saveToCloud(false, false)
        .catch((error) => {
          console.error("Auto-save failed:", error);
        });
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Detect backspace key to delete selected item
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Backspace") {
        // Only delete if canvas is focused
        if (document.activeElement === document.body && !editorStore.wallEditingLength) {
          editorStore.delete();
        }
        if (document.activeElement === document.body && !editorStore.textEditing) {
          editorStore.delete();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [editorStore]);

  const CanvasEventHandler: React.FC = observer(() => {
    const { camera, gl } = useThree(); // Access the camera and the canvas element (gl.domElement)
    const editorStore = React.useContext(editorStoreContext);

    useEffect(() => {
      // Set the camera reference in editorStore
      editorStore.setCamera(camera);

      // Add the wheel event listener to the canvas element only
      const handleWheel = (event: WheelEvent) => {
        editorStore.handleWheel(event);
      };

      const canvas = gl.domElement;

      if (canvas) {
        canvas.addEventListener("wheel", handleWheel, { passive: false });
      }

      return () => {
        // Clean up the event listener when the component unmounts
        if (canvas) {
          canvas.removeEventListener("wheel", handleWheel);
        }
      };
    }, [camera, editorStore, gl]);

    return null; // This component just sets up event handling, no need to render anything
  });

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <Canvas
        camera={{ position: [0, 0, 20], fov: 40 }}
        frameloop="demand"
        style={{ userSelect: "none" }}
        gl={{ localClippingEnabled: true, antialias: true, toneMapping: THREE.NoToneMapping }}
      >
        <CameraSetup /> 
        {editorStore.view3D && <OrbitControls />}
        {editorStore.showGrid && <Grid />}
        <PanningPlane />
        <SelectionPlane />
        <WallConstructionPlane />
        <CanvasEventHandler />
        <ambientLight intensity={1} />
        <pointLight position={[10, 10, 10]} />
        <FloorplannerStoreProvider>
          <WallStructure />
          {Object.values(symbols).map((symbol) => (
            isDoorType(symbol) ? (
              <Door
                key={symbol.id}
                door={symbol}
                onDragStart={() => {
                  // Do nothing
                }}
                onDragEnd={(newPosition) => {
                  // Do nothing
                }}
                onDrag={() => {
                  // Do nothing
                }}
                // If door is attached to a wall, send the wall to the door
                attachedToWall={symbol.attachedTo ? floorplannerStore.walls[symbol.attachedTo] : undefined}
              />
            ) :
              isDoubleDoorType(symbol) ? (
                <DoubleDoor
                  key={symbol.id}
                  doubleDoor={symbol}
                  onDragStart={() => {
                    // Do nothing
                  }}
                  onDragEnd={(newPosition) => {
                    // Do nothing
                  }}
                  onDrag={() => {
                    // Do nothing
                  }}
                  // If door is attached to a wall, send the wall to the door
                  attachedToWall={symbol.attachedTo ? floorplannerStore.walls[symbol.attachedTo] : undefined}
                />
              ) :
                isCircleStairsType(symbol) ? (
                  <CircleStairs
                    key={symbol.id}
                    circleStair={symbol}
                    onDragStart={() => {
                      // Do nothing
                    }}
                    onDragEnd={(newPosition) => {
                      // Do nothing
                    }}
                    onDrag={() => {
                      // Do nothing
                    }}
                  />
                ) :
                  isRectStairsType(symbol) ? (
                    <RectStairs
                      key={symbol.id}
                      rectStair={symbol}
                      onDragStart={() => {
                        // Do nothing
                      }}
                      onDragEnd={(newPosition) => {
                        // Do nothing
                      }}
                      onDrag={() => {
                        // Do nothing
                      }}
                    />
                  ) :
                    isSvgType(symbol) ? (
                      <SvgSymbol
                        key={symbol.id}
                        svg={symbol}
                        onDragStart={() => {
                          // Do nothing
                        }}
                        onDragEnd={(newPosition) => {
                          // Do nothing
                        }}
                        onDrag={() => {
                          // Do nothing
                        }}
                      />
                    ) :
                      isTextType(symbol) ? (
                        <TextSymbol
                          key={symbol.id}
                          text={symbol}
                          onDragStart={() => {
                            // Do nothing
                          }}
                          onDragEnd={(newPosition) => {
                            // Do nothing
                          }}
                          onDrag={() => {
                            // Do nothing
                          }}
                        />
                      ) :
                        isWindowType(symbol) ? (
                          <Window
                            key={symbol.id}
                            window={symbol}
                            onDragStart={() => {
                              // Do nothing
                            }}
                            onDragEnd={(newPosition) => {
                              // Do nothing
                            }}
                            onDrag={() => {
                              // Do nothing
                            }}
                            // If window is attached to a wall, send the wall width to the window
                            attachedToWall={symbol.attachedTo ? floorplannerStore.walls[symbol.attachedTo] : undefined}
                          />
                        ) : null
          ))}
        </FloorplannerStoreProvider>
        {/* <Stats /> */}
      </Canvas>
    </div>
  );
});

export default FloorPlan;
