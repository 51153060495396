import React, { useRef, useState, useEffect, useCallback } from "react";
import * as THREE from "three";
import { invalidate, useFrame, useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { DoorType, DoubleDoorType, WallType } from "../../types/wallTypes";
import { SelectableSymbol, BoundingBox } from "./SelectableSymbol";
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import AttachableSymbol from "./AttachableSymbol";
import { Line } from "@react-three/drei";
import { editorStore } from "../../store/editorStore";

export const defaultOpenAngle = Math.PI / 2;
export const defaultDoorWidth = 1;

interface DoubleDoorProps {
  doubleDoor: DoubleDoorType;
  attachedToWall?: WallType;
  onDragStart: (doubleDoor: DoubleDoorType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const DoubleDoor: React.FC<DoubleDoorProps> = ({
  doubleDoor,
  attachedToWall,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const [currentOpenAngle, setCurrentOpenAngle] = useState(doubleDoor.openAngle);
  const currentOpenAngleRef = useRef(currentOpenAngle); // Ref for the current open angle in event listeners
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const attachableRef = useRef<{ checkForAttachment: () => void }>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);

  const lineWeight = doubleDoor.lineWeight || floorplannerStore.doorLineWeight;
  const frameThickness = doubleDoor.doorFrameWidth || floorplannerStore.doorFrameWidth;
  const bladeThickness = doubleDoor.doorBladeThickness || floorplannerStore.doorBladeThickness;
  const [doubleDoorWidth, setDoubleDoorWidth] = useState(doubleDoor.doubleDoorWidth || floorplannerStore.doorWidth);
  const grabHandleSize = 0.12;
  const handleRadius = grabHandleSize / editorStore.zoomLevelDivisor();
  const handleDepth = 0.02; // Depth of the handles
  const flipX = 1;
  const flipY = doubleDoor.flipVertical ? -1 : 1;
  const [centerPosition, setCenterPosition] = useState<[number, number]>([
    doubleDoorWidth / 2,
    doubleDoorWidth / 2,
  ]);
  const lineColor = doubleDoor.lineColor || floorplannerStore.lineColor;
  const wallWidth = attachedToWall?.wallWidth || floorplannerStore.wallWidth;
  const wallLineWeight = floorplannerStore.convertLineWeightToWorld(attachedToWall?.lineWeight || floorplannerStore.wallLineWeight);
  const wallLineColor = attachedToWall?.lineColor || floorplannerStore.lineColor;
  const doorFrameWidth = doubleDoor.doorFrameWidth || floorplannerStore.doorFrameWidth;
  const hingePosition1 = doubleDoor.flipHorizontal ?
    attachedToWall ? [doubleDoorWidth, (wallLineWeight / 2 + bladeThickness / 2) * flipY, 0] : [doubleDoorWidth, 0, 0] :
    attachedToWall ? [0, (wallLineWeight / 2 + bladeThickness / 2) * flipY, 0] : [0, 0, 0];
  const hingePosition2 = doubleDoor.flipHorizontal ?
    attachedToWall ? [doubleDoorWidth, (wallLineWeight / 2 + bladeThickness / 2) * flipY, 0] : [doubleDoorWidth, 0, 0] :
    attachedToWall ? [doubleDoorWidth * 2, (wallLineWeight / 2 + bladeThickness / 2) * flipY, 0] : [doubleDoorWidth * 2, 0, 0];
  // Assuming the hinge is on the left side when flipX is 1, and on the right side when flipX is -1
  const hingeOffsetX = 0;  // Adjust hinge position based on flipX
  // Calculate handle position relative to hinge
  const openAngleGrabHandleX = hingeOffsetX + doubleDoorWidth * 0.6 * Math.cos(currentOpenAngle) * flipX;
  const openAngleGrabHandleY = doubleDoorWidth * 0.6 * Math.sin(currentOpenAngle) * flipY;
  const [isHovered, setIsHovered] = useState(false);

  const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);

  useFrame(() => {
    if (camera.zoom !== prevCameraZoom) {
      setPrevCameraZoom(camera.zoom);
      invalidate(); // Trigger a re-render
    }
  });

  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) {
      return {
        topLeft: [0, 0],
        topRight: [0, 0],
        bottomLeft: [0, 0],
        bottomRight: [0, 0],
        width: 0,
        height: 0,
        depth: 0,
      };
    }

    // Clone the object
    const tempGroup = groupRef.current.clone();

    // Save the original rotation
    const originalRotation = tempGroup.rotation.clone();

    // Temporarily reset the rotation to 0
    tempGroup.rotation.set(0, 0, 0);

    // Calculate the bounding box as if the object had no rotation
    const boundingBox = new THREE.Box3().setFromObject(tempGroup);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    // Restore the original rotation
    tempGroup.rotation.copy(originalRotation);

    const topLeft = new THREE.Vector3(
      -(attachedToWall ? frameThickness + wallLineWeight : 0),
      size.y - (attachedToWall ? wallWidth / 2 + wallLineWeight : 0),
      0
    );
    const topRight = new THREE.Vector3(
      size.x - (attachedToWall ? frameThickness * 2 - wallLineWeight : 0),
      size.y - (attachedToWall ? wallWidth / 2 + wallLineWeight : 0),
      0
    );
    const bottomLeft = new THREE.Vector3(
      -(attachedToWall ? frameThickness + wallLineWeight : 0),
      -(attachedToWall ? wallWidth / 2 + wallLineWeight : doorFrameWidth),
      0
    );
    const bottomRight = new THREE.Vector3(
      size.x - (attachedToWall ? frameThickness * 2 - wallLineWeight : 0),
      -(attachedToWall ? wallWidth / 2 + wallLineWeight : doorFrameWidth),
      0
    );

    if (doubleDoor.flipVertical) {
      topLeft.y = -topLeft.y;
      topRight.y = -topRight.y;
      bottomLeft.y = -bottomLeft.y;
      bottomRight.y = -bottomRight.y;
      setCenterPosition([doubleDoorWidth + (attachedToWall ? frameThickness + wallLineWeight : 0) - size.x / 2, -(size.y / 2 - (attachedToWall ? wallWidth : 0))]);
    } else {
      setCenterPosition([size.x / 2 - (attachedToWall ? frameThickness + wallLineWeight : 0), size.y / 2 - (attachedToWall ? wallWidth : 0)]);
    }

    return {
      topLeft: [topLeft.x, topLeft.y],
      topRight: [topRight.x, topRight.y],
      bottomLeft: [bottomLeft.x, bottomLeft.y],
      bottomRight: [bottomRight.x, bottomRight.y],
      width: size.x,
      height: size.y,
      depth: size.z,
    };
  }, [doubleDoor.position.x, doubleDoor.position.y, doubleDoor.flipHorizontal, doubleDoor.flipVertical, doubleDoorWidth, currentOpenAngle]);

  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = (event: PointerEvent) => {
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    // Get the current rotation of the door in radians
    const doorRotation = doubleDoor.rotation || 0;

    // Translate the world coordinates into the door's local space
    // Create a new THREE.Vector2 for mouse position in world space
    const mousePosition = new THREE.Vector2(worldX, worldY);

    // Create a vector for the door's current position
    const doorPosition = new THREE.Vector2(doubleDoor.position.x, doubleDoor.position.y);

    // Compute the offset of the mouse relative to the door's position
    const offset = mousePosition.clone().sub(doorPosition);

    // Rotate the offset by the inverse of the door's rotation to transform into local space
    offset.rotateAround(new THREE.Vector2(0, 0), -doorRotation);

    // Now use the local offset to compute the new angle for the door
    const dx = offset.x - dragOffset.current.x;
    const dy = offset.y - dragOffset.current.y;

    const angle = Math.atan2(dy * flipY, dx * flipX);
    let newAngle = angle < 0 ? angle + 2 * Math.PI : angle;
    if (newAngle > Math.PI) newAngle = 0;
    else if (newAngle > Math.PI / 2) newAngle = Math.PI / 2;
    setCurrentOpenAngle(newAngle);
  };

  const onPointerDownHandle = (event: React.PointerEvent) => {
    event.stopPropagation();
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    dragOffset.current = {
      x: worldX - (doubleDoorWidth * 0.75 * Math.cos(currentOpenAngle) * flipX + doubleDoor.position.x),
      y: worldY - (doubleDoorWidth * 0.75 * Math.sin(currentOpenAngle) * flipY + doubleDoor.position.y),
    };
    gl.domElement.addEventListener("pointermove", onHandleDrag);
    gl.domElement.addEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(true);
  };

  const onPointerUpHandle = () => {
    if (doubleDoor.openAngle !== currentOpenAngleRef.current) {
      floorplannerStore.updateSymbolProperty(doubleDoor.id, "openAngle", currentOpenAngleRef.current);
    }
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };

  const handleDrag = (newPosition: [number, number]) => {
    onDrag(newPosition);
  };

  const generateArcPoints = () => {
    const arcPoints = [];
    const segments = 64;
    for (let i = 0; i <= segments; i++) {
      const angle = (i / segments) * currentOpenAngle;
      arcPoints.push(new THREE.Vector3(
        doubleDoorWidth * Math.cos(angle) * flipX,
        doubleDoorWidth * Math.sin(angle) * flipY,
        0
      ));
    }
    return arcPoints;
  };

  const arcPoints = generateArcPoints();

  const generateMirrorArcPoints = () => {
    const arcPoints = [];
    const segments = 64;
    for (let i = 0; i <= segments; i++) {
      const angle = (i / segments) * currentOpenAngle;
      arcPoints.push(new THREE.Vector3(
        doubleDoorWidth * Math.cos(angle) * -flipX,
        doubleDoorWidth * Math.sin(angle) * flipY,
        0
      ));
    }
    return arcPoints;
  };

  const mirrorArcPoints = generateMirrorArcPoints();

  // Calculate door blade points
  const doorBladePoints: [number, number, number][] = [
    [0, -bladeThickness, 0], // Bottom-left corner
    [doubleDoorWidth * flipX, -bladeThickness, 0], // Bottom-right corner
    [doubleDoorWidth * flipX, bladeThickness * flipY - bladeThickness, 0], // Top-right corner
    [0, bladeThickness * flipY - bladeThickness, 0], // Top-left corner
    [0, -bladeThickness, 0], // Back to Bottom-left to close the rectangle
  ];
  const doorBladePointsFlipY: [number, number, number][] = [
    [0, bladeThickness, 0], // Bottom-left corner
    [doubleDoorWidth * flipX, bladeThickness, 0], // Bottom-right corner
    [doubleDoorWidth * flipX, bladeThickness * flipY + bladeThickness, 0], // Top-right corner
    [0, bladeThickness * flipY + bladeThickness, 0], // Top-left corner
    [0, bladeThickness, 0], // Back to Bottom-left to close the rectangle
  ];
  const doorBladePointsMirror: [number, number, number][] = [
    [0, 0, 0], // Bottom-left corner
    [doubleDoorWidth * flipX, 0, 0], // Bottom-right corner
    [doubleDoorWidth * flipX, bladeThickness * flipY, 0], // Top-right corner
    [0, bladeThickness * flipY, 0], // Top-left corner
    [0, 0, 0], // Back to Bottom-left to close the rectangle
  ];

  // Generate a door frame points, assuming attachedToWall is true
  const generateFramePoints = (wallWidth: number, side: number): [number, number, number][] => {
    const offset = (side * (doubleDoorWidth) + doubleDoorWidth); // Side is either -1 (left) or 1 (right)
    return [
      [offset, -wallWidth / 2, 0],
      [offset + frameThickness * side, -wallWidth / 2, 0],
      [offset + frameThickness * side, wallWidth / 2, 0],
      [offset, wallWidth / 2, 0],
      [offset, -wallWidth / 2, 0], // Closing the frame
    ];
  };

  useEffect(() => {
    setCurrentOpenAngle(doubleDoor.openAngle);
  }, [doubleDoor.openAngle]);

  useEffect(() => {
    setDoubleDoorWidth(doubleDoor.doubleDoorWidth);
  }, [doubleDoor.doubleDoorWidth]);

  // Update the ref whenever currentOpenAngle changes
  useEffect(() => {
    currentOpenAngleRef.current = currentOpenAngle;
  }, [currentOpenAngle]);

  return (
    <DraggableObject
      position={[doubleDoor.position.x, doubleDoor.position.y]}
      onDragStart={(offset) => onDragStart(doubleDoor, offset)}
      onDragEnd={(endPosition) => onDragEnd(endPosition)}
      onDrag={handleDrag}
      selectable={true}
      attachmentId={doubleDoor.id}
      attachmentType="doorAttachments"
      symbol={doubleDoor}
    >
      <AttachableSymbol
        attachmentId={doubleDoor.id}
        attachmentType="doorAttachments"
        onAttachment={(attachPosition) => {
          // Handle attachment logic
        }}
        store={floorplannerStore}
        ref={attachableRef}
      >
        <SelectableSymbol
          ref={groupRef}
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResize={(newWidth, newHeight, newDepth, handle) => {
            newWidth -= newWidth / 2;
            newWidth -= attachedToWall ? frameThickness + wallLineWeight : wallLineWeight;
            const widthDiff = newWidth - (floorplannerStore.symbols[doubleDoor.id] as DoubleDoorType).doubleDoorWidth;
            floorplannerStore.updateSymbolProperty(doubleDoor.id, "doubleDoorWidth", newWidth);
            if (handle === "topLeft" || handle === "bottomLeft") {
              // Lock bottomRight position and adjust topLeft but take rotation into account
              doubleDoor.position.x -= (widthDiff * 2) * Math.cos(doubleDoor.rotation || 0);
              doubleDoor.position.y -= (widthDiff * 2) * Math.sin(doubleDoor.rotation || 0);
            }
            if (attachedToWall) {
              // Get symbol's position
              const position = floorplannerStore.getSymbolPositionOfWall(doubleDoor.id, attachedToWall.id);
              if (position) {
                floorplannerStore.detachSymbolFromWall(doubleDoor.id);
                if (handle === "topLeft" || handle === "bottomLeft") {
                  position.x -= (widthDiff * 2) * Math.cos(doubleDoor.rotation || 0);
                  position.y -= (widthDiff * 2) * Math.sin(doubleDoor.rotation || 0);
                }
                floorplannerStore.attachSymbolToWall(doubleDoor.id, attachedToWall.id, [position.x, position.y]);
              }
            }
          }}
          center={centerPosition}
          rotation={doubleDoor.rotation}
          symbol={doubleDoor}
          onPointerOver={() => setIsHovered(true)}
          onPointerOut={() => setIsHovered(false)}
        >
          <group
            ref={groupRef}
            rotation={[0, 0, doubleDoor.rotation || 0]}
          >
            {/* If door is attached to a wall, close the endpoints by drawing a line mesh across the opening with the wall properties */}
            {attachedToWall && (
              <>
                <mesh position={[-doorFrameWidth - wallLineWeight / 2, 0, 0]}>
                  <boxGeometry args={[wallLineWeight, wallWidth, 0.001]} />
                  <meshStandardMaterial color={wallLineColor} />
                </mesh>
                <mesh position={[doubleDoorWidth*2 + doorFrameWidth + wallLineWeight / 2, 0, 0]}>
                  <boxGeometry args={[wallLineWeight, wallWidth, 0.001]} />
                  <meshStandardMaterial color={wallLineColor} />
                </mesh>
              </>
            )}

            {/* Render the arc */}
            <Line
              position={new THREE.Vector3(hingePosition1[0], hingePosition1[1], hingePosition1[2])}
              points={arcPoints.map((point) => [point.x, point.y, point.z])}
              color={isHovered ? 'blue' : lineColor} 
              lineWidth={lineWeight / 2} // Fixed pixel size
            />

            {/* Render the mirror arc */}
            <Line
              position={new THREE.Vector3(hingePosition2[0], hingePosition2[1], hingePosition2[2])}
              points={mirrorArcPoints.map((point) => [point.x, point.y, point.z])}
              color={isHovered ? 'blue' : lineColor} 
              lineWidth={lineWeight / 2} // Fixed pixel size
            />

            {/* Render door blade using Line */}
            <Line
              points={flipY === 1 ? doorBladePoints : doorBladePointsFlipY}
              color={isHovered ? 'blue' : lineColor} 
              lineWidth={lineWeight} // Fixed pixel size
              position={new THREE.Vector3(hingePosition1[0], hingePosition1[1], hingePosition1[2])} // Start at the hinge position
               rotation={[0, 0, currentOpenAngle * flipX * flipY]}
            />
            <Line
              points={doorBladePointsMirror}
              color={isHovered ? 'blue' : lineColor} 
              lineWidth={lineWeight} // Fixed pixel size
              position={new THREE.Vector3(hingePosition2[0], hingePosition2[1], hingePosition2[2])} // Start at the hinge position
              rotation={[0, 0, Math.PI - currentOpenAngle * flipX * flipY]}
            />

            {/* Render frames if attached to a wall */}
            {attachedToWall && (
              <>
                {/* Left Frame */}
                <Line
                  points={generateFramePoints(wallWidth + wallLineWeight * 2, -1)}
                  color={isHovered ? 'blue' : lineColor} 
                  lineWidth={1}
                />
                {/* Right Frame */}
                <Line
                  points={generateFramePoints(wallWidth + wallLineWeight * 2, 1)}
                  color={isHovered ? 'blue' : lineColor} 
                  lineWidth={1}
                />
              </>
            )}

            {/* Handle for adjusting open angle */}
            {doubleDoor.selected && (
              <group
                onPointerDown={onPointerDownHandle as unknown as (event: THREE.Event) => void}
                onPointerUp={onPointerUpHandle as unknown as (event: THREE.Event) => void}
                position={[
                  openAngleGrabHandleX,
                  openAngleGrabHandleY,
                  0,
                ]}
              >
                <mesh>
                  <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                  <meshBasicMaterial color="blue" />
                </mesh>
                <mesh
                  position={[0, 0, handleDepth / 2]}
                  rotation={[Math.PI / 2, 0, 0]}
                >
                  <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                  <meshBasicMaterial color="white" />
                </mesh>
              </group>
            )}
          </group>
        </SelectableSymbol>
      </AttachableSymbol>
    </DraggableObject>
  );
};

export default DoubleDoor;