import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { SvgType } from "../../types/wallTypes";
import { BoundingBox, SelectableSymbol } from './SelectableSymbol';
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Svg } from "./Svg";

interface SvgSymbolProps {
  svg: SvgType;
  onDragStart: (svg: SvgType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const SvgSymbol: React.FC<SvgSymbolProps> = ({
  svg,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);

  const [svgWidth, setSvgWidth] = useState(svg.svgWidth);
  const [svgHeight, setSvgHeight] = useState(svg.svgHeight);
  const [aspectRatio] = useState(svg.svgWidth / svg.svgHeight);

  const flipX = svg.flipHorizontal ? -1 : 1;
  const flipY = svg.flipVertical ? -1 : 1;
  const lineColor = svg.lineColor || floorplannerStore.lineColor;
  // State to store the pivot dynamically
  const [pivot, setPivot] = useState<[number, number]>([0, 0]);
  const dragOffset = useRef({ x: 0, y: 0 });
  const lineWeight = svg.lineWeight || floorplannerStore.symbolLineWeight
  const lineWeightInWorld = floorplannerStore.convertLineWeightToWorld(lineWeight);
  const [centerPosition] = useState<[number, number]>([
    0,
    0,
  ]);

  // useEffect(() => {
  //   // Load the SVG to get the actual dimensions
  //   const loadSVG = async () => {
  //     const response = await fetch(svg.svgPath);
  //     const text = await response.text();
  //     const parser = new DOMParser();
  //     const svgDoc = parser.parseFromString(text, "image/svg+xml");
  //     svgElement.current = svgDoc.querySelector("svg");
  //     if (svgElement.current) {
  //       const viewBox = svgElement.current.getAttribute("viewBox")?.split(" ") || ["0", "0", "1", "1"];
  //       setOriginalWidth(parseFloat(viewBox[2] as string));
  //       setOriginalHeight(parseFloat(viewBox[3] as string));
  //     }
  //   };

  //   loadSVG();
  //   setIsMounted(true); // Trigger the recalculation of bounding box after mounting
  // }, [svg.svgPath]);

  // Calculate the bounding box based on current SVG dimensions
  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) {
      return {
        topLeft: [0, 0],
        topRight: [0, 0],
        bottomLeft: [0, 0],
        bottomRight: [0, 0],
        width: 0,
        height: 0,
        depth: 0,
      };
    }

    // const width = svgWidth;
    // const height = svgWidth / (aspectRatio + 0.15);

    // // Adjust the bounding box by subtracting the line weight from all sides
    // const adjustedWidth = width + lineWeightInWorld;
    // const adjustedHeight = height + lineWeightInWorld;

    // const scaleX = svgWidth / originalWidth;
    // const scaleY = svgHeight / originalHeight;

    // const boundingBox = new THREE.Box3();
    // (svgElement.current as SVGSVGElement).querySelectorAll("g").forEach((path) => {
    //   console.log(path);
    //   path.querySelectorAll("polyline").forEach((polyline) => {
    //     console.log(polyline);
    //     const points = polyline.getAttribute("points")?.split(" ") || [];
    //     console.log(points);
    //     points.forEach((point) => {
    //       const [x, y] = point.split(".");
    //       console.log(x, y);
    //       boundingBox.expandByPoint(new Vector3(parseFloat(x), parseFloat(y), 0));
    //     });
    //   });
    // });

    // const width = (boundingBox.max.x - boundingBox.min.x) * scaleX;
    // const height = (boundingBox.max.y - boundingBox.min.y) * scaleY;
    // const lineWeight = lineWeightInWorld;
    // const adjustedWidth = width + lineWeight;
    // const adjustedHeight = height + lineWeight;

    const tempObject = groupRef.current.clone();
    tempObject.rotation.set(0, 0, 0);

    const box = new THREE.Box3().setFromObject(tempObject);
    const width = box.max.x - box.min.x;
    const height = box.max.y - box.min.y;
    const lineWeight = lineWeightInWorld;
    const adjustedWidth = width + lineWeight;
    const adjustedHeight = height + lineWeight;

    return {
      topLeft: [-width / 2 - lineWeightInWorld / 2, height / 2 + lineWeightInWorld / 2],
      topRight: [width / 2 + lineWeightInWorld / 2, height / 2 + lineWeightInWorld / 2],
      bottomLeft: [-width / 2 - lineWeightInWorld / 2, -height / 2 - lineWeightInWorld / 2],
      bottomRight: [width / 2 + lineWeightInWorld / 2, -height / 2 - lineWeightInWorld / 2],
      width: adjustedWidth,
      height: adjustedHeight,
      depth: 0,
    };

  }, [svg.lineWeight, svgWidth, svgHeight]);

  const onHandleDrag = (event: PointerEvent) => {
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);

    const dx = (worldX - svg.position.x - dragOffset.current.x) * flipX;
    //const dy = (worldY - svg.position.y - dragOffset.current.y) * flipY;

    const newWidth = Math.max(0.1, svgWidth + dx);
    //const newHeight = Math.max(0.1, svgHeight + dy);
    const newHeight = newWidth / aspectRatio;

    setSvgWidth(newWidth);
    setSvgHeight(newHeight);
  };

  const onPointerDownHandle = (event: React.PointerEvent) => {
    event.stopPropagation();
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    dragOffset.current = {
      x: worldX - (svgWidth * 0.75 * flipX + svg.position.x),
      y: worldY - (svgHeight * 0.75 * flipY + svg.position.y),
    };
    gl.domElement.addEventListener("pointermove", onHandleDrag);
    gl.domElement.addEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(true);
  };

  const onPointerUpHandle = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };

  useEffect(() => {
    setSvgWidth(svg.svgWidth);
    setSvgHeight(svg.svgWidth / aspectRatio);
  }, [svg.svgWidth]);

  return (
    <DraggableObject
      position={[svg.position.x, svg.position.y]}
      onDragStart={(offset) => onDragStart(svg, offset)}
      onDragEnd={(endPosition) => onDragEnd(endPosition)}
      onDrag={onDrag}
      selectable={true}
      attachmentId={svg.id}
      attachmentType="doorAttachments"
      zIndex={svg.zIndex}
      symbol={svg}
    >
      <SelectableSymbol
        ref={groupRef}
        handleSize={floorplannerStore.symbolHandleSize}
        calculateBoundingBox={calculateBoundingBox}
        onResize={(newWidth, newHeight, newDepth, handle) => {
          const calculatedHeight = newWidth / aspectRatio;
          const widthDiff = newWidth - (floorplannerStore.symbols[svg.id] as SvgType).svgWidth;
          const heightDiff = calculatedHeight - (floorplannerStore.symbols[svg.id] as SvgType).svgHeight;
          setSvgWidth(newWidth);
          setSvgHeight(calculatedHeight);
          floorplannerStore.updateSymbolProperty(svg.id, "svgWidth", newWidth);
          floorplannerStore.updateSymbolProperty(svg.id, "svgHeight", calculatedHeight);
          if (handle === "topLeft") {
            // Lock bottomRight position and adjust topLeft
            svg.position.x -= (widthDiff / 2) * Math.cos(svg.rotation || 0);
            //svg.position.y -= (heightDiff * 2) * Math.sin(svg.rotation || 0);
          } else if (handle === "topRight") {
            // Lock bottomLeft position and adjust topRight
            //svg.position.x += widthDiff * Math.cos(svg.rotation || 0); // X
            svg.position.y += heightDiff //* Math.sin(svg.rotation || 0);
          } else if (handle === "bottomLeft") {
            // Lock topRight position and adjust bottomLeft
            svg.position.x -= widthDiff //* Math.cos(svg.rotation || 0);
            //svg.position.y -= heightDiff * Math.sin(svg.rotation || 0); // X
          } else if (handle === "bottomRight") {
            // Lock topLeft position and adjust bottomRight
            //svg.position.x += widthDiff * Math.cos(svg.rotation || 0); // X
            //svg.position.y -= heightDiff * Math.sin(svg.rotation || 0); // X
          }
        }}
        center={centerPosition}
        rotation={svg.rotation}
        symbol={svg}
        drawHandles={true}
        //pivot={pivot}
        lockAspectRatio={true}
      >
        <group
          ref={groupRef}
          rotation={[0, 0, svg.rotation || 0]}
          position={[0, 0, -0.01]}>
          <Svg
            src={svg.svgPath}
            fillMaterial={{
              color: svg.fillColor || "", // Set the fill color (transparent is not supported, but empty string is)
            }}
            // Dynamically apply scale based on the svgWidth and svgHeight
            //scale={[svgWidth / originalWidth, svgHeight / originalHeight, 1]}
            lineColor={lineColor || "black"}
            lineWeight={lineWeight}
            width={svgWidth}
          />
          {/* {svg.selected && (
            <group
              onPointerDown={onPointerDownHandle as unknown as (event: THREE.Event) => void}
              onPointerUp={onPointerUpHandle as unknown as (event: THREE.Event) => void}
            >
              <mesh>
                <boxGeometry args={[0.25, 0.25, 0.01]} />
                <meshStandardMaterial color="gray" opacity={0.5} transparent />
              </mesh>
            </group>
          )} */}
          {/* draw a dot at the 0,0 */}
          {/* <mesh position={[0, 0, 0.01]}>
            <boxGeometry args={[0.1, 0.1, 0.1]} />
            <meshBasicMaterial color="red" />
          </mesh> */}
          {/* draw a dot at the center */}
          {/* <mesh position={[centerPosition[0], centerPosition[1], 0.01]}>
            <boxGeometry args={[0.1, 0.1, 0.1]} />
            <meshBasicMaterial color="blue" />
          </mesh> */}
        </group>
      </SelectableSymbol>
    </DraggableObject>
  );
};

export default SvgSymbol;
