import { Graph, Point, Room, WallType } from "../../types/wallTypes";

// Tolerance for floating-point comparison
const TOLERANCE = 0.1;

// Utility function to create a string key for a point (x, y)
function pointKey(point: Point): string {
    return `${point.x.toFixed(3)},${point.y.toFixed(3)}`; // Round to 3 decimal places for consistency
}

// Utility function to compare points with tolerance for floating-point precision
function arePointsEqual(p1: Point, p2: Point): boolean {
    return Math.abs(p1.x - p2.x) < TOLERANCE && Math.abs(p1.y - p2.y) < TOLERANCE;
}

// Function to build the graph of wall connections
function buildGraph(walls: { [key: string]: WallType; }): Graph {
    const graph: Graph = {};

    Object.values(walls).forEach(wall => {
        const startKey = pointKey(wall.start);
        const endKey = pointKey(wall.end);

        if (!graph[startKey]) graph[startKey] = [];
        if (!graph[endKey]) graph[endKey] = [];

        // Add connections in both directions (undirected graph)
        graph[startKey].push(wall.end);
        graph[endKey].push(wall.start);
    });

    return graph;
}

// Function to detect rooms (cycles) using DFS (Depth First Search)
function findRooms(graph: Graph): Room[] {
    const visited = new Set<string>();
    const rooms: Room[] = [];

    function dfs(node: string, start: string, path: Point[]): boolean {
        if (visited.has(node)) return false;

        visited.add(node);
        const [x, y] = node.split(',').map(Number); // Convert node string back to Point
        path.push({ x, y });

        const neighbors = graph[node];

        for (let neighbor of neighbors) {
            const neighborKey = pointKey(neighbor);

            // If we find the starting point again and have more than 2 points, it's a cycle
            if (neighborKey === start && path.length > 2) {
                rooms.push([...path, { x, y }]); // Add the cycle as a room
                return true;
            }

            // Continue DFS if the neighbor hasn't been visited yet
            if (!visited.has(neighborKey)) {
                if (dfs(neighborKey, start, path)) return true;
            }
        }

        // Backtrack if no cycle is found
        path.pop();
        return false;
    }

    // Loop over each node and start DFS from there
    for (let node in graph) {
        if (!visited.has(node)) {
            const path: Point[] = [];
            dfs(node, node, path);
        }
    }

    return rooms;
}

// Main function to detect rooms from walls
export function detectRooms(walls: { [key: string]: WallType; }): Room[] {
    const graph = buildGraph(walls);
    const rooms = findRooms(graph);
    return rooms;
}

// Utility function to calculate the area of a polygon (room) using the Shoelace theorem
export function calculatePolygonArea(points: Point[]): number {
    let area = 0;
    const n = points.length;
    for (let i = 0; i < n; i++) {
        const j = (i + 1) % n;
        area += points[i].x * points[j].y - points[j].x * points[i].y;
    }
    return Math.abs(area / 2);
}

// Utility function to calculate the centroid of a polygon (room)
export function calculatePolygonCentroid(points: Point[]): Point {
    let centroid = { x: 0, y: 0 };
    let signedArea = 0;

    for (let i = 0; i < points.length; i++) {
        const x0 = points[i].x;
        const y0 = points[i].y;
        const x1 = points[(i + 1) % points.length].x;
        const y1 = points[(i + 1) % points.length].y;
        const a = x0 * y1 - x1 * y0;
        signedArea += a;
        centroid.x += (x0 + x1) * a;
        centroid.y += (y0 + y1) * a;
    }

    signedArea *= 0.5;
    centroid.x /= 6 * signedArea;
    centroid.y /= 6 * signedArea;

    return centroid;
}
