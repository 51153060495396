import { WallType } from "../../types/wallTypes";

export const removeWallConnection = (
    tempWalls: { [x: string]: WallType },
    wall: WallType,
    connectionPosition: number,
    removeAllConnections?: boolean,
) => {
    if (removeAllConnections) {
        wall.connections?.forEach((c) => {
            if (tempWalls[c.id]) {
                tempWalls[c.id].connections = tempWalls[c.id].connections?.filter(
                    (c2) => c2.id !== wall.id,
                );
                tempWalls[c.id].cutouts = tempWalls[c.id].cutouts?.filter(
                    (c2) => c2.belongsTo !== wall.id,
                );
                tempWalls[c.id].clippingPolygons = tempWalls[
                    c.id
                ].clippingPolygons?.filter((c2) => c2.belongsTo !== wall.id);
            }
            if (tempWalls[wall.id]) {
                tempWalls[wall.id].cutouts = tempWalls[wall.id].cutouts?.filter(
                    (c2) => c2.belongsTo !== c.id,
                );
                tempWalls[wall.id].clippingPolygons = tempWalls[
                    wall.id
                ].clippingPolygons?.filter((c2) => c2.belongsTo !== c.id);
            }
        });
        tempWalls[wall.id].connections = [];
    } else {
        wall.connections?.forEach((c) => {
            if (tempWalls[c.id]) {
                tempWalls[c.id].connections = tempWalls[c.id].connections?.filter(
                    (c2) =>
                        c2.id !== wall.id || c2.targetPosition !== connectionPosition,
                );
                tempWalls[c.id].cutouts = tempWalls[c.id].cutouts?.filter(
                    (c2) => c2.belongsTo !== wall.id,
                );
                tempWalls[c.id].clippingPolygons = tempWalls[
                    c.id
                ].clippingPolygons?.filter((c2) => c2.belongsTo !== wall.id);
            }
            if (tempWalls[wall.id]) {
                tempWalls[wall.id].cutouts = tempWalls[wall.id].cutouts?.filter(
                    (c2) => c2.belongsTo !== c.id,
                );
                tempWalls[wall.id].clippingPolygons = tempWalls[
                    wall.id
                ].clippingPolygons?.filter((c2) => c2.belongsTo !== c.id);
            }
        });
        tempWalls[wall.id].connections = tempWalls[
            wall.id
        ].connections?.filter((c) => c.sourcePosition !== connectionPosition);
    }
}