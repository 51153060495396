import * as THREE from 'three';

// Convert World Coordinates to Metric millimeters
export const convertWorldToMillimeter = (world: number, decimals: number = 0) => {
  const millimeters = world * 1000; // Convert the world units to millimeters
  const factor = Math.pow(10, decimals); // Calculate the factor based on the number of decimals
  return Math.round(millimeters * factor) / factor; // Round the millimeter value based on the factor
};

// Convert World Coordinates to imperial inches
export const convertWorldToInch = (world: number, decimals: number = 0) => {
  const inches = world * 39.3701; // Convert the world units to inches
  const factor = Math.pow(10, decimals); // Calculate the factor based on the number of decimals
  return Math.round(inches * factor) / factor; // Round the inch value based on the factor
};

// Convert World Coordinates to Metric Meter number
export const convertWorldToMeter = (world: number, decimals: number = 0) => {
  const meters = world * 100; // Convert the world units to meters
  const factor = Math.pow(10, decimals); // Calculate the factor based on the number of decimals
  return Math.round(meters * factor) / factor; // Round the meter value based on the factor
}

// Millimeters to World Coordinates
export const convertMillimeterToWorld = (metric: number) => {
  return metric / 1000;
}

// Inches to World Coordinates
export const convertInchToWorld = (imperial: number) => {
  return imperial / 39.3701;
}

// Meters to World Coordinates
export const convertMeterToWorld = (metric: number) => {
  return metric / 100;
}

export const convertWorldToMillimeterVector = (world: THREE.Vector2) => {
  return new THREE.Vector2(convertWorldToMillimeter(world.x), convertWorldToMillimeter(world.y));
};

export const convertMillimeterToWorldVector = (metric: THREE.Vector2) => {
  return new THREE.Vector2(convertMillimeterToWorld(metric.x), convertMillimeterToWorld(metric.y));
};

