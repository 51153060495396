import * as THREE from "three";

export const createFullWallShape = (vertices: THREE.Vector2[]): THREE.Shape => {
  const shape = new THREE.Shape();
  shape.moveTo(vertices[0].x, vertices[0].y);
  vertices.slice(1).forEach((v) => shape.lineTo(v.x, v.y));
  shape.closePath();
  return shape;
};
export const createFullDoorShape = (vertices: THREE.Vector2[]): THREE.Shape => {
    const shape = new THREE.Shape();
    shape.moveTo(vertices[0].x, vertices[0].y);
    vertices.slice(1).forEach(v => shape.lineTo(v.x, v.y));
    shape.closePath();
    return shape;
};
