/* eslint-disable react/no-unknown-property */
import React, { useCallback, useEffect, useState } from "react";
import { WallType, WindowType } from "../../types/wallTypes";
import DraggableObject from "./DraggableObject";
import { BoundingBox, SelectableSymbol } from "./SelectableSymbol";
import { FloorplannerStoreContext } from '../../store/floorplannerStore';
import AttachableSymbol from "./AttachableSymbol";
import { Line } from "@react-three/drei";
import { symbol } from 'prop-types';

interface WindowProps {
  window: WindowType;
  attachedToWall?: WallType;
  onDragStart: (startPosition: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const Window: React.FC<WindowProps> = ({
  window,
  attachedToWall,
  onDragStart,
  onDrag,
  onDragEnd,
}) => {
  const floorplannerStore = React.useContext(FloorplannerStoreContext);
  const windowLength = window.windowLength || floorplannerStore.windowLength;
  //const lineWidth = floorplannerStore.lineWidth;
  const lineWeight = window.lineWeight || floorplannerStore.windowLineWeight;
  const sillLineWeight = lineWeight / 2;
  const color = window.lineColor || floorplannerStore.lineColor;
  const windowFrameWidth = window.windowFrameWidth || floorplannerStore.windowFrameWidth;
  const wallLineWeight = floorplannerStore.convertLineWeightToWorld(attachedToWall?.lineWeight || floorplannerStore.wallLineWeight);
  const wallLineColor = attachedToWall?.lineColor || floorplannerStore.lineColor;
  const wallWidth = attachedToWall?.wallWidth || floorplannerStore.wallWidth;
  const [isHovered, setIsHovered] = useState(false);

  const calculateBoundingBox = (): BoundingBox => {
    let leftMost = -window.windowLength / 2;
    let rightMost = window.windowLength / 2;
    if (attachedToWall && wallWidth >= 0.2) {
      leftMost = Math.min(leftMost, -wallWidth / 2 - wallLineWeight);
      rightMost = Math.max(rightMost, wallWidth / 2 + wallLineWeight);
    }
    return {
      topLeft: [leftMost, window.windowWidth / 2],
      topRight: [rightMost, window.windowWidth / 2],
      bottomLeft: [leftMost, -window.windowWidth / 2],
      bottomRight: [rightMost, -window.windowWidth / 2],
      width: rightMost - leftMost,
      height: window.windowWidth,
      depth: 0.1,
    };
  };

  const customRotation = window.rotation ? window.rotation : 0;

  return (
    <DraggableObject
      position={[window.position.x, window.position.y]}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDrag={(newPosition) => {
        floorplannerStore.unSelectAll();
        onDrag(newPosition);
      }}
      selectable={true}
      attachmentId={window.id}
      attachmentType="windowAttachments"
      symbol={window}
    >
      <AttachableSymbol
        attachmentId={window.id}
        attachmentType="windowAttachments"
        onAttachment={() => { }}
        store={floorplannerStore}
      >
        <SelectableSymbol
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResize={(newWidth, newHeight, newDepth, handle) => {
            const widthDiff = newWidth - (floorplannerStore.symbols[window.id] as WindowType).windowLength;
            floorplannerStore.updateSymbolProperty(window.id, "windowLength", newWidth);
            if (handle === "topLeft" || handle === "bottomLeft") {
              // Lock bottomRight position and adjust topLeft taking rotation into account
              window.position.x -= widthDiff / 2 * Math.cos(window.rotation || 0);
              window.position.y -= widthDiff / 2 * Math.sin(window.rotation || 0);
            } else if (handle === "topRight" || handle === "bottomRight") {
              // Lock bottomLeft position and adjust topRight taking rotation into account
              window.position.x += widthDiff / 2 * Math.cos(window.rotation || 0);
              window.position.y += widthDiff / 2 * Math.sin(window.rotation || 0);
            }
            if (attachedToWall) {
              // Get symbol's position
              const position = floorplannerStore.getSymbolPositionOfWall(window.id, attachedToWall.id);
              if (position) {
                floorplannerStore.detachSymbolFromWall(window.id);
                if (handle === "topLeft" || handle === "bottomLeft") {
                  position.x -= widthDiff / 2 * Math.cos(window.rotation || 0);
                  position.y -= widthDiff / 2 * Math.sin(window.rotation || 0);
                } else if (handle === "topRight" || handle === "bottomRight") {
                  position.x += widthDiff / 2 * Math.cos(window.rotation || 0);
                  position.y += widthDiff / 2 * Math.sin(window.rotation || 0);
                }
                floorplannerStore.attachSymbolToWall(window.id, attachedToWall.id, [position.x, position.y]);
              }
            }
          }}
          rotation={customRotation}
          symbol={window}
          onPointerOver={() => setIsHovered(true)}
          onPointerOut={() => setIsHovered(false)}
        >
          {/* Window is drawn horizontally now */}
          <group
            rotation={[0, 0, customRotation]}
          >
            {/* If window is attached to a wall, close the endpoints by drawing a line mesh across the opening with the wall properties */}
            {attachedToWall && (
              <>
                <mesh position={[-(windowLength / 2 + wallLineWeight / 2), 0, 0]}>
                  <boxGeometry args={[wallLineWeight, wallWidth, 0.001]} />
                  <meshStandardMaterial color={wallLineColor} />
                </mesh>
                <mesh position={[(windowLength / 2 + wallLineWeight / 2), 0, 0]}>
                  <boxGeometry args={[wallLineWeight, wallWidth, 0.001]} />
                  <meshStandardMaterial color={wallLineColor} />
                </mesh>
              </>
            )}
            {/* Window */}

            {/* Left Frame (now horizontal frame) */}
            <Line
              points={[
                [-window.windowLength / 2, window.windowWidth / 2, 0],
                [-window.windowLength / 2, -window.windowWidth / 2, 0]
              ]}
              color={isHovered ? 'blue' : color} 
              lineWidth={lineWeight}
            />
            {/* Additional line near the left frame */}
            <Line
              points={[
                [-window.windowLength / 2 + windowFrameWidth, window.windowWidth / 2, 0],
                [-window.windowLength / 2 + windowFrameWidth, -window.windowWidth / 2, 0]
              ]}
              color={isHovered ? 'blue' : color} 
              lineWidth={lineWeight}
            />
            {/* Middle Frame, only if not attached or attached to a wall wider than 0.1 */}
            {(!attachedToWall || wallWidth > 0.1) && (
              <Line
                points={[
                  [-windowLength / 2 + windowFrameWidth, 0, 0],
                  [windowLength / 2 - windowFrameWidth, 0, 0]
                ]}
                color={isHovered ? 'blue' : color} 
                lineWidth={lineWeight}
              />
            )}
            {/* Right Frame (now horizontal frame) */}
            <Line
              points={[
                [window.windowLength / 2, window.windowWidth / 2, 0],
                [window.windowLength / 2, -window.windowWidth / 2, 0]
              ]}
              color={isHovered ? 'blue' : color} 
              lineWidth={lineWeight}
            />
            {/* Additional line near the right frame */}
            <Line
              points={[
                [window.windowLength / 2 - windowFrameWidth, window.windowWidth / 2, 0],
                [window.windowLength / 2 - windowFrameWidth, -window.windowWidth / 2, 0]
              ]}
              color={isHovered ? 'blue' : color} 
              lineWidth={lineWeight}
            />
            {/* Top Frame (horizontal) */}
            <Line
              points={[
                [-window.windowLength / 2, window.windowWidth / 2, 0],
                [window.windowLength / 2, window.windowWidth / 2, 0]
              ]}
              color={isHovered ? 'blue' : color} 
              lineWidth={lineWeight}
            />
            {/* Bottom Frame */}
            <Line
              points={[
                [-window.windowLength / 2, -window.windowWidth / 2, 0],
                [window.windowLength / 2, -window.windowWidth / 2, 0]
              ]}
              color={isHovered ? 'blue' : color} 
              lineWidth={lineWeight}
            />
            {/* Debug green box to visually see where 0,0 is */}
            {/* <mesh position={[0, 0, 0]} rotation={[0, 0, 0]}>
            <boxGeometry args={[0.01, 0.01, 0.01]} />
            <meshBasicMaterial color="green" />
            </mesh> */}
          </group>
        </SelectableSymbol>
      </AttachableSymbol>
    </DraggableObject>
  );
};

export default Window;
