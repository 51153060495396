import { WallType } from "../../types/wallTypes";
import * as THREE from 'three';
import { closestPointOnLine } from "./closestPointOnLine";
import { floorplannerStore } from '../../store/floorplannerStore';

/**
 * Drag the middle handle of the wall vertically
 * @param wall The wall to drag
 * @param startOffsetX The start x offset of the wall
 * @param startOffsetY The start y offset of the wall
 * @param newX The new x position of the wall
 * @param newY The new y position of the wall
 * @param tempWalls The temporary walls object
 * @param delta The delta vector
 * @returns boolean If the wall was snapped
 */
export const dragMiddleVHandle = (
    wall: WallType,
    startOffsetX: number,
    startOffsetY: number,
    newX: number,
    newY: number,
    tempWalls: { [key: string]: WallType },
    delta: THREE.Vector2,
): boolean => {
    let snapped = false;
    const midPoint = new THREE.Vector2(
        (wall.start.x + wall.end.x) / 2,
        (wall.start.y + wall.end.y) / 2,
    );
    let newDelta = new THREE.Vector2(
        newX - startOffsetX,
        newY - startOffsetY,
    ).sub(midPoint);
    const wallDirection = new THREE.Vector2()
        .subVectors(wall.end, wall.start)
        .normalize();
    const perpendicular = new THREE.Vector2(
        -wallDirection.y,
        wallDirection.x,
    );
    const distance = newDelta.dot(perpendicular);
    newDelta = perpendicular.clone().multiplyScalar(distance);

    // Apply delta to wall positions
    const movedStart = wall.start.clone().add(newDelta);
    const movedEnd = wall.end.clone().add(newDelta);

    // // Snapping logic starts here
    // if (wall.connections && wall.connections.length > 0) {
    //     for (const connection of wall.connections) {
    //         const connectedWallId = connection.id; // Corrected here
    //         if (connectedWallId && connectedWallId !== wall.id) {
    //             const connectedWall = floorplannerStore.walls[connectedWallId];
    //             if (connectedWall) {
    //                 // Calculate the angle between the walls
    //                 const movedDirection = new THREE.Vector2()
    //                     .subVectors(movedEnd, movedStart)
    //                     .normalize();
    //                 const connectedDirection = new THREE.Vector2()
    //                     .subVectors(connectedWall.end, connectedWall.start)
    //                     .normalize();

    //                 // Correct angle calculation
    //                 const dotProduct = movedDirection.dot(connectedDirection);
    //                 const crossProduct = movedDirection.x * connectedDirection.y - movedDirection.y * connectedDirection.x;
    //                 const angleBetweenWalls = Math.atan2(crossProduct, dotProduct); // Signed angle in radians

    //                 const angleDifference = Math.abs(Math.abs(angleBetweenWalls) - (Math.PI / 2));

    //                 const angleTolerance = 0.1; // Adjust this value as needed

    //                 if (angleDifference < angleTolerance) {
    //                     // Calculate the closest point on the connected wall
    //                     const movedMidPoint = new THREE.Vector2()
    //                         .addVectors(movedStart, movedEnd)
    //                         .multiplyScalar(0.5);

    //                     const closestPointOnConnectedWall = closestPointOnLine(
    //                         connectedWall.start,
    //                         connectedWall.end,
    //                         movedMidPoint,
    //                     );

    //                     // Adjust positions to snap
    //                     const adjustmentVector = new THREE.Vector2()
    //                         .subVectors(closestPointOnConnectedWall, movedMidPoint);

    //                     // Update wall positions
    //                     movedStart.add(adjustmentVector);
    //                     movedEnd.add(adjustmentVector);

    //                     // Update delta
    //                     delta.add(adjustmentVector);

    //                     if (wall.controlPoint) {
    //                         const newControlPoint = wall.controlPoint.clone().add(delta);
    //                         tempWalls[wall.id].controlPoint = newControlPoint;
    //                     }

    //                     snapped = true;
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    // }

    // Apply final positions
    tempWalls[wall.id].start.copy(movedStart);
    tempWalls[wall.id].end.copy(movedEnd);
    if (!snapped && wall.controlPoint) {
        const newControlPoint = wall.controlPoint.clone().add(newDelta);
        tempWalls[wall.id].controlPoint = newControlPoint;
    }

    delta.x = newDelta.x;
    delta.y = newDelta.y;
    return snapped;
}