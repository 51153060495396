import React, { useEffect, useRef } from 'react';
import { useThree, useFrame, invalidate } from '@react-three/fiber';
import * as THREE from 'three';
import { editorStore } from '../../store/editorStore';
import { observer } from 'mobx-react-lite';
import { projectToWorld } from '../FloorPlan/projectToWorld';
import { floorplannerStore } from '../../store/floorplannerStore';
import { WallConnectionEnd, WallConnectionStart, WallType } from '../../types/wallTypes';
import { snapToOtherWalls } from '../FloorPlan/snapToOtherWalls';
import { runInAction } from 'mobx';
import { updateAlignmentLines } from '../FloorPlan/updateAligmentLines';

const WallConstructionPlane = observer(() => {
  const {
    wallConstructionMode,
    wallConstructionModeAddNewWall,
    view3D,
  } = editorStore;
  const { gl, camera } = useThree();
  const addNewWall = useRef(false);
  const currentWall = useRef(undefined as string | undefined);
  const lastClickPosition = useRef(undefined as THREE.Vector2 | undefined);

  // When the user press Enter key (like inside Edit field), editorStore.wallConstructionModeAddNewWall will signal to add a new wall
  useEffect(() => {
    if (currentWall.current && wallConstructionModeAddNewWall) {
      editorStore.setWallConstructionModeAddNewWall(false);
      const wall = floorplannerStore.walls[currentWall.current];
      if (wall) {
        lastClickPosition.current = wall.end.clone();
        createNewWallSegment([wall.end.x, wall.end.y]);
      }
    }
  }, [wallConstructionModeAddNewWall]);

  useEffect(() => {
    if (currentWall.current) {
      const wall = floorplannerStore.walls[currentWall.current];
      if (wall) {
        if (!addNewWall.current) {
          // Get the wall previous connection
          const previousWall = floorplannerStore.getConnectedWall(wall, WallConnectionStart);
          // Remove current constructed wall since it is not click confirmed yet
          floorplannerStore.removeWall(currentWall.current);
          // If there is a previous wall, snap the previous wall to any possible connections at the end
          if (previousWall) {
            runInAction(() => {
              const snapped = snapToOtherWalls(floorplannerStore.walls, previousWall, WallConnectionEnd, previousWall.end, true);
            });
          }
        } else if (addNewWall.current) {
          // Snap the current wall to any possible connections at the start
          runInAction(() => {
            if (currentWall.current) {
              const snapped = snapToOtherWalls(floorplannerStore.walls, wall, WallConnectionEnd, wall.end, true);
              if (snapped) {
                // Select the wall if it is snapped to another wall
                floorplannerStore.selectWall(currentWall.current);
              }
            }
          });
        }
      }
    }
    editorStore.setWallEditingLength(undefined);
    addNewWall.current = false;
    currentWall.current = undefined;
    lastClickPosition.current = undefined;
    if (wallConstructionMode || view3D) {
      document.body.style.cursor = 'crosshair';
    } else {
      document.body.style.cursor = 'auto';
    }
  }, [wallConstructionMode, view3D]);

  const handlePointerDown = (event: PointerEvent) => {
    if (editorStore.wallConstructionMode) {
      // Get the click position of the pointer in the world
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);
      lastClickPosition.current = new THREE.Vector2(pos[0], pos[1]);
      addNewWall.current = true;
    }
  };

  const handlePointerMove = (event: PointerEvent) => {
    if (editorStore.wallConstructionMode && !editorStore.view3D) {
      document.body.style.cursor = 'crosshair';
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);

      // Get the distance between the current mouse position and the last clicked position
      const distance = lastClickPosition.current?.distanceTo(new THREE.Vector2(pos[0], pos[1])) ?? 0;

      if (addNewWall.current && distance > 0.1 && lastClickPosition.current) {
        createNewWallSegment(pos);
      }

      // Update the wall's position if it's already been created
      if (!addNewWall.current && currentWall.current) {
        const wall = floorplannerStore.walls[currentWall.current];
        const start = wall.start;
        let end = new THREE.Vector2(pos[0], pos[1]);
        // Check if Shift is pressed
        if (!event.shiftKey) {
          // Calculate the angle between the start and current mouse position
          const deltaX = end.x - start.x;
          const deltaY = end.y - start.y;
          const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI); // Convert to degrees

          // Get the current distance between the start and end position
          const currentDistance = start.distanceTo(end);

          // Normalize the angle to a positive value between 0 and 360
          const normalizedAngle = (angle + 360) % 360;

          // Snap the angle to the nearest 45 degrees
          const snapAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
          let closestSnapAngle = snapAngles.reduce((prev, curr) => Math.abs(curr - normalizedAngle) < Math.abs(prev - normalizedAngle) ? curr : prev);

          // Convert the snapped angle to radians
          const radianSnapAngle = closestSnapAngle * (Math.PI / 180);

          // Calculate the snapped end position (along the nearest 45-degree line)
          const snapEndX = start.x + currentDistance * Math.cos(radianSnapAngle);
          const snapEndY = start.y + currentDistance * Math.sin(radianSnapAngle);

          // Calculate the distance between the current mouse position and the snapped position
          const snapDistance = end.distanceTo(new THREE.Vector2(snapEndX, snapEndY));

          // If the distance to the snapped angle is less than 0.2, snap to the closest 45-degree angle
          if (snapDistance < 0.2) {
            end.set(snapEndX, snapEndY);
          }
        }

        floorplannerStore.updateWallPosition(currentWall.current, start, end);
        // Update alignment lines for the wall being constructed
        updateAlignmentLines(wall, floorplannerStore.walls, floorplannerStore.walls, end.x, end.y, "end");
      }
    }
  };

  const handlePointerUp = () => {
  };

  const createNewWallSegment = (pos: [number, number]) => {
    if (lastClickPosition.current && currentWall) {
      const start = currentWall.current ?
        floorplannerStore.walls[currentWall.current].end.clone() :
        lastClickPosition.current.clone();
      const end = new THREE.Vector2(pos[0], pos[1]);
      // Create a new wall and snap to other walls
      const newWall: WallType = {
        id: floorplannerStore.generateId(),
        type: "wall",
        start: start,
        end: end,
      };
      floorplannerStore.addWall(newWall);
      editorStore.setWallEditingLength(newWall.id);  // This will make the wall width editable while constructing
      // Pause mobx observer updates for this operation
      runInAction(() => {
        if (currentWall.current) {
          const wall = floorplannerStore.walls[currentWall.current];
          const snappedStart = snapToOtherWalls(floorplannerStore.walls, newWall, WallConnectionStart, start, true);
          const snappedEnd = snapToOtherWalls(floorplannerStore.walls, wall, WallConnectionEnd, end, true);
        }
      });

      currentWall.current = newWall.id;
      addNewWall.current = false;
    }
  };

  return (
    <mesh
      position={[0, 0, -0.01]} // Ensure the plane is in the background
      onPointerDown={(e) => handlePointerDown(e.nativeEvent)}
      onPointerMove={(e) => handlePointerMove(e.nativeEvent)}
      onPointerUp={handlePointerUp}
    >
      <planeGeometry args={[1000, 1000]} />
      <meshBasicMaterial transparent opacity={0} />
    </mesh>
  );
});

export default WallConstructionPlane;
