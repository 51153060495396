import { isDoorType, isWindowType } from "../types/wallTypes";
import { FloorplannerStore } from "./floorplannerStore";

export function checkFloorplannerStoreIntegrity(store: FloorplannerStore) {
    // Check that all keys are unique
    const wallKeys = Object.keys(store.walls);
    const symbolKeys = Object.keys(store.symbols);
    const allKeys = wallKeys.concat(symbolKeys);
    const uniqueKeys = new Set(allKeys);
    if (uniqueKeys.size !== allKeys.length) {
        console.error("Duplicate keys found in walls and symbols", allKeys);
    }
    // Check that all keys have a corresponding object id
    wallKeys.forEach((key) => {
        if (!store.walls[key]) {
            console.error("Wall key does not have a corresponding object", key);
        }
    });
    symbolKeys.forEach((key) => {
        if (!store.symbols[key]) {
            console.error("Symbol key does not have a corresponding object", key);
        }
    });
    // Check all symbols attachments are valid
    Object.values(store.symbols).forEach((symbol) => {
        if (symbol.attachedTo) {
            if (!store.walls[symbol.attachedTo]) {
                console.error("Symbol is attached to a wall that does not exist", symbol.id, symbol.attachedTo);
            } else {
                const wall = store.walls[symbol.attachedTo];
                if (!wall.symbolAttachments) {
                    console.error("Symbol is attached to a wall that does not have symbol attachments", symbol.id, symbol.attachedTo);
                } else {
                    const attachment = wall.symbolAttachments.find(attachment => attachment.symbolId === symbol.id);
                    if (!attachment) {
                        console.error("Symbol is attached to a wall that does not have the symbol attachment", symbol.id, symbol.attachedTo);
                    }
                }
            }
        }
    });
    // Check if all wall connections are valid
    Object.values(store.walls).forEach((wall) => {
        wall.connections?.forEach((connection) => {
            if (!store.walls[connection.id]) {
                console.error("Wall connection is invalid", wall.id, connection.id);
            } else {
                const connectedWall = store.walls[connection.id];
                if (!connectedWall.connections?.find(connectedWallConnection => connectedWallConnection.id === wall.id)) {
                    console.error("Wall connection is not bidirectional", wall.id, connection.id);
                }
            }
        });
    });
    // Check if all wall clipping polygons are valid
    Object.values(store.walls).forEach((wall) => {
        wall.clippingPolygons?.forEach((polygon) => {
            if (polygon.objectType === "symbol" || polygon.objectType === "door" || polygon.objectType === "window") {
                if (!store.symbols[polygon.belongsTo]) {
                    console.error("Wall clipping polygon belongs to a symbol that does not exist, will now attempt to remove it", wall.id, polygon.belongsTo);
                    // Remove the clipping polygon
                    const wallCopy = { ...wall };
                    wallCopy.clippingPolygons = wallCopy.clippingPolygons?.filter(polygon => polygon.belongsTo !== polygon.belongsTo);
                    store.walls = { ...store.walls, [wall.id]: wallCopy };
                }
            } else if (polygon.objectType === "wall") {
                if (!store.walls[polygon.belongsTo]) {
                    console.error("Wall clipping polygon belongs to a wall that does not exist", wall.id, polygon.belongsTo);
                }
            } else {
                console.error("Wall clipping polygon has an invalid object type, will remove it", wall.id, polygon.belongsTo, polygon.objectType);
            }
        });
    });
    // Check if all symbols have all required properties of their type by looking at the type property
    Object.values(store.symbols).forEach((symbol) => {
        if (isDoorType(symbol)) {
            if (!symbol.doorWidth) {
                console.error("Door symbol does not have a door width", symbol.id);
            }
        } else if (isWindowType(symbol)) {
            if (!symbol.windowWidth) {
                console.error("Window symbol does not have a window width", symbol.id);
            }
            if (!symbol.windowLength) {
                console.error("Window symbol does not have a window length", symbol.id);
            }
        }
    });
}