import * as THREE from "three";

// Helper function to interpolate points on a quadratic Bezier curve
function getQuadraticBezierPoint(t: number, p0: THREE.Vector2, p1: THREE.Vector2, p2: THREE.Vector2): THREE.Vector2 {
  const invT = 1 - t;
  const x = invT * invT * p0.x + 2 * invT * t * p1.x + t * t * p2.x;
  const y = invT * invT * p0.y + 2 * invT * t * p1.y + t * t * p2.y;
  return new THREE.Vector2(x, y);
}

export const calculateLineVertices = (
  start: THREE.Vector2,
  end: THREE.Vector2,
  offset: number,
  lineWeight: number,
  wallWidth: number,
  shapeType: "straight" | "left" | "right" | "arc" | "arcLeft" | "arcRight" | "endCapBoth" | "endCapStart" | "endCapEnd",
  controlPoint?: THREE.Vector2,
): THREE.Vector2[] => {
  const direction = new THREE.Vector2().subVectors(end, start).normalize();
  const width = shapeType === "straight" || shapeType === "arc" ? wallWidth : lineWeight;
  const perpendicular = new THREE.Vector2(
    -direction.y,
    direction.x,
  ).multiplyScalar(width / 2);
  const offsetVector = perpendicular.clone().multiplyScalar(offset / width);
  const extendLength = 0;

  start = start.clone().sub(direction.clone().multiplyScalar(extendLength));
  end = end.clone().add(direction.clone().multiplyScalar(extendLength));
  const startOffset = start.clone().add(offsetVector);
  const endOffset = end.clone().add(offsetVector);

  // Temporary Recalculate control point based on the midpoint and perpendicular direction
  if (!controlPoint) {
    controlPoint = new THREE.Vector2()
      .addVectors(start, end)
      .multiplyScalar(0.5)
      .add(new THREE.Vector2().subVectors(start, end).normalize().rotateAround(new THREE.Vector2(0, 0), Math.PI / 2).multiplyScalar(-2));
  }

  if (
    shapeType === "endCapBoth" ||
    shapeType === "endCapStart" ||
    shapeType === "endCapEnd"
  ) {
    // Define the vertices for the end caps as a rectangle with 4 vertices
    let startCap1, startCap2, startCap3, startCap4, endCap1, endCap2, endCap3, endCap4;
    // Extend vertically at the start end cap
    // Use wallWidth directly, similar to how lineWeight is used
    const extendedPerpendicular = perpendicular
      .clone()
      .multiplyScalar((wallWidth + lineWeight * 2) / lineWeight);

    startCap1 = startOffset.clone().add(extendedPerpendicular);
    startCap2 = startOffset.clone().sub(extendedPerpendicular);
    endCap1 = startOffset
      .clone()
      .add(extendedPerpendicular)
      .sub(direction.clone().multiplyScalar(lineWeight));
    endCap2 = startOffset
      .clone()
      .sub(extendedPerpendicular)
      .sub(direction.clone().multiplyScalar(lineWeight));

    // Extend vertically at the end end cap
    endCap3 = endOffset.clone().add(extendedPerpendicular);
    endCap4 = endOffset.clone().sub(extendedPerpendicular);
    startCap3 = endOffset
      .clone()
      .add(extendedPerpendicular)
      .add(direction.clone().multiplyScalar(lineWeight));
    startCap4 = endOffset
      .clone()
      .sub(extendedPerpendicular)
      .add(direction.clone().multiplyScalar(lineWeight));
    if (shapeType === "endCapBoth") {
      return [
        startCap1,
        startCap2,
        endCap2,
        endCap1, // Start end cap
        endCap3,
        endCap4,
        startCap4,
        startCap3, // End end cap
      ];
    } else if (shapeType === "endCapStart") {
      return [
        startCap1,
        startCap2,
        endCap2,
        endCap1, // Start end cap
      ];
    } else {
      return [
        endCap3,
        endCap4,
        startCap4,
        startCap3, // End end cap
      ];
    }
  } else if ((shapeType === "arc" || shapeType === "arcLeft" || shapeType === "arcRight") && controlPoint) {
    const segmentsCount = 20;  // Increase segments for smoothness

    const outerPoints: THREE.Vector2[] = [];
    const innerPoints: THREE.Vector2[] = [];
    // Adjust perpendicular direction for the straight segments (i.e., the start and end)
    const perpendicularToStartEnd = new THREE.Vector2(-direction.y, direction.x);
    const halfWallWidth = wallWidth / 2;

    for (let i = 0; i <= segmentsCount; i++) {
      const t = i / segmentsCount;
      const pointOnCurve = getQuadraticBezierPoint(t, start, controlPoint!, end);

      // Use perpendicular based on the tangent except at the start and end
      let perpendicular;
      if (i === 0) {
        // For the start point, use the perpendicular based on the straight direction
        perpendicular = perpendicularToStartEnd.clone();
      } else if (i === segmentsCount) {
        // For the end point, use the perpendicular based on the straight direction
        perpendicular = perpendicularToStartEnd.clone();
      } else {
        // For the points in between, calculate the tangent to the curve
        const tangent = new THREE.Vector2().subVectors(
          getQuadraticBezierPoint(t + 0.01, start, controlPoint!, end),
          pointOnCurve
        ).normalize();
        // Calculate perpendicular vector (90 degrees to the tangent)
        perpendicular = new THREE.Vector2(-tangent.y, tangent.x);
      }

      // Calculate outer and inner points by moving along the perpendicular vector
      let outerPoint, innerPoint;
      if (shapeType === "arc") {
        outerPoint = pointOnCurve.clone().add(perpendicular.clone().multiplyScalar(halfWallWidth));
        innerPoint = pointOnCurve.clone().sub(perpendicular.clone().multiplyScalar(halfWallWidth));
      } else if (shapeType === "arcLeft") {
        outerPoint = pointOnCurve.clone().add(perpendicular.clone().multiplyScalar(halfWallWidth + lineWeight));
        innerPoint = pointOnCurve.clone().add(perpendicular.clone().multiplyScalar(halfWallWidth));
      } else {
        outerPoint = pointOnCurve.clone().sub(perpendicular.clone().multiplyScalar(halfWallWidth));
        innerPoint = pointOnCurve.clone().sub(perpendicular.clone().multiplyScalar(halfWallWidth + lineWeight));
      }

      outerPoints.push(outerPoint);
      innerPoints.push(innerPoint);
    }

    // Reverse the inner points to ensure proper winding of vertices
    innerPoints.reverse();

    // Combine outer and inner points to form the vertices of the line
    const allPoints = [...outerPoints, ...innerPoints];
    return allPoints;
  } else {
    return [
      startOffset.clone().add(perpendicular),
      startOffset.clone().sub(perpendicular),
      endOffset.clone().sub(perpendicular),
      endOffset.clone().add(perpendicular),
    ];
  }
};
