import React, { useState, useRef, useEffect, useMemo } from "react";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";
import {
  isDoorType,
  isWindowType,
  WallType,
  SymbolType,
  isDoubleDoorType,
  isCircleStairsType,
  isRectStairsType,
  isSvgType,
  isTextType,
  DoorType,
  DoubleDoorType,
  WindowType,
  RectStairsType,
  SvgType,
} from "../../types/wallTypes"; // Adjust the import path as needed
import * as THREE from "three";
import { floorplannerStore } from "../../store/floorplannerStore";
import { HiOutlinePlus } from "react-icons/hi";
import { useFloorplansQuery } from "../../gql";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { CgImage } from "react-icons/cg";
import { FiDownload } from "react-icons/fi";
import { LuCopy } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { GrDocumentImage } from "react-icons/gr";
import { PiAngle } from "react-icons/pi";
import { convertWorldToMillimeter, convertMillimeterToWorld } from '../../utils/conversions';
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { invalidate } from "@react-three/fiber";
import { PiFlipHorizontalFill } from "react-icons/pi";
import { PiFlipVerticalFill } from "react-icons/pi";
import { GiAnticlockwiseRotation } from "react-icons/gi";
import { Tooltip } from "flowbite-react";
import { CircleStairsType } from '../../types/wallTypes';

// Sortable Item Component with Tailwind CSS styling and click/drag handling
const SortableItem: React.FC<{
  id: string;
  label: string;
  icon: JSX.Element | null;
  isSelected: boolean;
  onClick: (e: React.MouseEvent) => void;
}> = ({
  id,
  label,
  icon,
  isSelected,
  onClick,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const [isDragging, setIsDragging] = useState(false);

  // Tailwind CSS classes for styling
  const classNames = [
    'block',
    'cursor-pointer',
    'flex',
    'items-center',
    'justify-start',
    'p-2', // Padding inside the row
    'ml-1 mr-1 mb-[1px]', // Margin between rows
    'rounded-md', // Rounded corners for the selected row
    'transition-transform',
    'transform', // Enable transform
    'duration-200', // Smooth animation for drag/drop
    isSelected ? 'bg-[#E5EAFF] border border-none' : 'bg-transparent', // Highlight selected rows
    'hover:bg-indigo-50', // Hover effect for non-selected rows
  ].join(' ');

  // Handle onMouseDown (start of drag)
  const handleMouseDown = () => {
    setIsDragging(false); // Reset dragging state
  };

  // Handle onMouseUp (end of drag or click)
  const handleMouseUp = (e:React.MouseEvent) => {
    if (!isDragging) {
      onClick(e); // Only fire click if dragging did not happen
    }
  };

  // Handle dragging event
  const handleDragStart = () => {
    setIsDragging(true); // Mark as dragging
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: 'none',
      }}
      {...attributes}
      {...listeners}
      className={classNames}
      onMouseDown={handleMouseDown} // Handle when drag starts
      onMouseUp={handleMouseUp} // Handle when drag ends or click happens
      onDragStart={handleDragStart} // Mark dragging state
    >
      <div className="flex items-center justify-center h-5 w-5 mr-2">
        {icon}
      </div>
      <div>{label}</div>
    </div>
  );
};

const PropertyPanel: React.FC = observer(() => {
  const [optionMenuOpen, setOptionMenuOpen] = useState(false);
  const optionMenuRef = useRef<HTMLDivElement | null>(null);
  const [floorplans, setFloorplans] = useState<any[]>([]);
  const [linePropsOpen, setLinePropsOpen] = useState(false);
  const [fillPropsOpen, setFillPropsOpen] = useState(false);
  const [otherPropsOpen, setOtherPropsOpen] = useState(false);
  const [layerPropsOpen, setLayerPropsOpen] = useState(true);

  /**
   * Local state for form fields, it is used while editing so that the global state is not updated
   * until the user clicks outside the form field or presses Enter.
   * This is to prevent the form field from updating the global state on every key press.
   * */
  const [localValues, setLocalValues] = useState<any>({});

  const { data } = useFloorplansQuery({
    variables: {},
  });

  const [symbolOrder, setSymbolOrder] = useState(Object.keys(floorplannerStore.symbols));
  const sensors = useSensors(useSensor(PointerSensor));

  // Function to handle drag end event
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setSymbolOrder((items) => {
        const oldIndex = items.indexOf(String(active.id));
        const newIndex = over ? items.indexOf(String(over.id)) : oldIndex;
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  // Determine if the symbol is selected by checking editorStore.selections
  const isSymbolSelected = (symbolId: string) => {
    return editorStore.selections.some((selection) => {
      return selection.id === symbolId; // Adjust this based on the actual structure of your selection
    });
  };

  // Generate labels based on symbol type
  const symbolsWithLabels = useMemo(() => {
    const typeCounts: { [key: string]: number } = {};

    return symbolOrder.map((symbolId, index) => {
      const symbol = floorplannerStore.symbols[symbolId];
      let symbolTypeLabel = '';

      if (isDoorType(symbol)) {
        symbolTypeLabel = 'Door';
      } else if (isDoubleDoorType(symbol)) {
        symbolTypeLabel = 'Double Door';
      } else if (isCircleStairsType(symbol)) {
        symbolTypeLabel = 'Circle Stairs';
      } else if (isRectStairsType(symbol)) {
        symbolTypeLabel = 'Rectangle Stairs';
      } else if (isSvgType(symbol)) {
        symbolTypeLabel = 'SVG Symbol';
      } else if (isTextType(symbol)) {
        symbolTypeLabel = 'Text Symbol';
      } else if (isWindowType(symbol)) {
        symbolTypeLabel = 'Window';
      } else {
        symbolTypeLabel = 'Symbol';
      }

      // Keep track of how many times this type appears
      if (!typeCounts[symbolTypeLabel]) {
        typeCounts[symbolTypeLabel] = 1;
      } else {
        typeCounts[symbolTypeLabel]++;
      }

      // Generate label with type and count
      const label = `${symbol?.name ?? symbolTypeLabel} ${typeCounts[symbolTypeLabel]}`;

      return {
        symbolId,
        label,
      };
    });
  }, [symbolOrder, floorplannerStore.symbols]);

  useEffect(() => {
    setSymbolOrder(Object.keys(floorplannerStore.symbols));
  }, [floorplannerStore.symbols]);

  useEffect(() => {
    let floorplans: React.SetStateAction<any[]> = [];
    if (data) {
      data.floorplans?.forEach((floorplan) => {
        floorplans.push({ id: floorplan?.id, name: floorplan?.name });
      });
      setFloorplans(floorplans);
    }
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (optionMenuRef.current && !optionMenuRef.current.contains(event.target as Node)) {
        setOptionMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionMenuRef]);

  // When global state is updated, update local state
  useEffect(() => {
    editorStore.selections.forEach((selection) => {
      if (selection.hasOwnProperty("type")) {
        if (selection.type === "wall") {
          const wall = floorplannerStore.walls[selection.id];
          setLocalValues((prevValues: any) => ({
            ...prevValues,
            [selection.id]: {
              startX: convertWorldToMillimeter(wall.start.x),
              startY: convertWorldToMillimeter(wall.start.y),
              endX: convertWorldToMillimeter(wall.end.x),
              endY: convertWorldToMillimeter(wall.end.y),
              wallWidth: convertWorldToMillimeter(wall.wallWidth || floorplannerStore.wallWidth),
            },
          }));
        } else if (["symbol", "door", "doubleDoor", "window", "circleStairs", "rectStairs", "svg", "text"].includes(selection.type)) {
          const symbol = floorplannerStore.symbols[selection.id];
          setLocalValues((prevValues: any) => ({
            ...prevValues,
            [selection.id]: {
              positionX: convertWorldToMillimeter(symbol.position.x),
              positionY: convertWorldToMillimeter(symbol.position.y),
              rotation: symbol.rotation,
              lineWidth: symbol.lineWidth,
              flipHorizontal: symbol.flipHorizontal,
              flipVertical: symbol.flipVertical,
            },
          }));
          if (isDoorType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                doorWidth: symbol.doorWidth,
                openAngle: symbol.openAngle,
              },
            }));
          }
          if (isDoubleDoorType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                doubleDoorWidth: symbol.doubleDoorWidth,
                openAngle: symbol.openAngle,
              },
            }));
          }
          if (isCircleStairsType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                circleStairsWidth: symbol.circleStairsWidth,
                openAngle: symbol.openAngle,
                stairStepSize: symbol.stairStepSize,
              },
            }));
          }
          if (isRectStairsType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                rectStairsWidth: symbol.rectStairsWidth,
                rectStairsHeight: symbol.rectStairsHeight,
                openAngle: symbol.openAngle,
                stairStepSize: symbol.stairStepSize,
              },
            }));
          }
          if (isSvgType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                svgWidth: symbol.svgWidth,
                svgHeight: symbol.svgHeight,
                svgPath: symbol.svgPath
              },
            }));
          }
          if (isTextType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                textBoxWidth: symbol.textBoxWidth,
                textBoxHeight: symbol.textBoxHeight,
                fontSize: symbol.fontSize,
                fontStyle: symbol.fontStyle,
                fontWeight: symbol.fontWeight,
                lineHeight: symbol.lineHeight,
                text: symbol.text,
              },
            }));
          }
          if (isWindowType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                windowWidth: symbol.windowWidth,
                windowLength: symbol.windowLength,
              },
            }));
          }
        }
      }
    });
  }, [editorStore.selections, floorplannerStore.walls, floorplannerStore.symbols]);

  const updateGlobalState = (object: any, property: string, value: any) => {
    editorStore.selections.forEach((selection) => {
      if (selection.hasOwnProperty("type")) {
        if (selection.type === "wall") {
          if (property === "lineColor" || property === "fillColor" || property === "lineWeight" || property === "lineForm") {
            floorplannerStore.setWallProperty(selection.id, property, value);
          } else {
            floorplannerStore.setWallProperty(selection.id, property, convertMillimeterToWorld(value));
          }
        } else if (["symbol", "door", "doubleDoor", "window", "circleStairs", "rectStairs", "svg", "text"].includes(selection.type)) {
          if (property === "lineColor" || property === "fillColor") {
            floorplannerStore.updateSymbolProperty(selection.id, property, value);
          } else if (property === "length" || property === "windowWidth" || property === "doorWidth" || property === "circleStairsWidth" || property === "rectStairsWidth" || property === "rectStairsHeight" || property === "stairStepSize" || property === "svgWidth" || property === "svgHeight") {
            floorplannerStore.updateSymbolProperty(selection.id, property, convertMillimeterToWorld(value));
          } else if (property === "positionX") {
            const orgY = floorplannerStore.symbols[selection.id].position.y;
            floorplannerStore.updateSymbolProperty(selection.id, "position", [convertMillimeterToWorld(value), orgY]);
          } else if (property === "positionY") {
            const orgX = floorplannerStore.symbols[selection.id].position.x;
            floorplannerStore.updateSymbolProperty(selection.id, "position", [orgX, convertMillimeterToWorld(value)]);
          } else {
            floorplannerStore.updateSymbolProperty(selection.id, property, value);
          }
        }
      }
    });
  };

  const handleSelectItem = (id: string, isWall: boolean) => {
    editorStore.setSelections(isWall
      ? [floorplannerStore.walls[id]]
      : [floorplannerStore.symbols[id]]);
  };

  // Handle local state update
  const handleLocalInputChange = (id: string, field: string, value: any) => {
    setLocalValues((prevValues: any) => ({
      ...prevValues,
      [id]: {
        ...prevValues[id],
        [field]: value,
      },
    }));
  };

  const commitInputChange = (object: any, field: string, id: string) => {
    const value = localValues[id]?.[field];
    if (value !== undefined) {
      if (field === "lineColor" || field === "fillColor") {
        // Convert the hex string from the color picker to a numeric color
        const colorValue = hexColorToNumber(value);
        handleLocalInputChange(id, field, numberToHexColor(colorValue));
        updateGlobalState(object, field, numberToHexColor(colorValue));
      } else {
        handleLocalInputChange(id, field, value);
        updateGlobalState(object, field, value);
      }

      // Immediately sync local state with the updated global state
      syncLocalStateWithGlobalState();
    }
  };

  const syncLocalStateWithGlobalState = () => {
    editorStore.selections.forEach((selection) => {
      if (selection.type === "wall") {
        const wall = floorplannerStore.walls[selection.id];
        setLocalValues((prevValues: any) => ({
          ...prevValues,
          [selection.id]: {
            ...prevValues[selection.id],
            lineColor: numberToHexColor(wall.lineColor || 0),
            fillColor: numberToHexColor(wall.fillColor || 0),
          },
        }));
      } else if (["symbol", "door", "doubleDoor", "window", "circleStairs", "rectStairs", "svg", "text"].includes(selection.type)) {
        const symbol = floorplannerStore.symbols[selection.id];
        setLocalValues((prevValues: any) => ({
          ...prevValues,
          [selection.id]: {
            ...prevValues[selection.id],
            lineColor: numberToHexColor(symbol.lineColor || 0),
            fillColor: numberToHexColor(symbol.fillColor || 0),
          },
        }));
      }
    });
  };

  // Convert a numeric color (0xRRGGBB) to a hex string (#RRGGBB)
  const numberToHexColor = (colorValue: number) => {
    let hexColor = colorValue.toString(16).padStart(6, '0');
    if (!hexColor.startsWith('#')) {
      hexColor = `#${hexColor}`;
    }
    return hexColor;
  };

  // Convert a hex string (#RRGGBB) to a numeric color (0xRRGGBB)
  const hexColorToNumber = (hexColor: string) => {
    // Ensure that the hexColor starts with '#'
    if (hexColor.startsWith('#')) {
      hexColor = hexColor.slice(1); // Remove the '#' before converting
    }
    return parseInt(hexColor, 16);
  };

  // Render color input for Line properties
  const renderLineColorInput = (object: SymbolType | WallType) => {
    const id = object.id;

    // Convert the numeric color to a hex string for the color picker
    const lineColor = localValues[id]?.lineColor || numberToHexColor(object.lineColor || 0);

    return (
      <div
        key={`lineColor-${id}`}
        className="flex flex-row justify-between items-start py-2"
      >
        <div className="mb-2">Color</div>
        <input
          className="border border-gray-300 rounded p-1 w-[90px]"
          type="color"
          value={lineColor}
          onChange={(e) => {
            const newColor = e.target.value; // Get the selected color from the input

            // Immediately update the global state
            updateGlobalState(object, "lineColor", newColor);
          }}
        />
      </div>
    );
  };

  // Render color input for Fill properties
  const renderFillColorInput = (object: SymbolType | WallType) => {
    const id = object.id;

    // Convert the numeric color to a hex string for the color picker
    const fillColor = localValues[id]?.fillColor || numberToHexColor(object.fillColor || 0);

    return (
      <div
        key={`fillColor-${id}`}
        className="flex flex-row justify-between items-start py-2"
      >
        <div className="mb-2">Color</div>
        <input
          className="border border-gray-300 rounded p-1 w-[90px]"
          type="color"
          value={fillColor}
          onChange={(e) => {
            const newColor = e.target.value; // Get the selected color from the input

            // Immediately update the global state
            updateGlobalState(object, "fillColor", newColor);
          }}
        />
      </div>
    );
  };

  // Render form fields for SymbolType
  const renderSymbolTypeFields = (object: SymbolType) => {
    const id = object.id;
    const position = object.position || new THREE.Vector2(0, 0);

    return (
      <div
        key={`symbolTypeFields-${id}`}
        className="grid grid-cols-2 gap-4 mb-4"
      >
        <label className="text-sm font-medium whitespace-normal">Position X:</label>
        <input
          className="border border-gray-300 rounded p-1 w-full"
          type="number"
          value={localValues[id]?.positionX ?? position.x}
          onChange={(e) =>
            updateGlobalState(id, "positionX", parseFloat(e.target.value))
          }
        />

        <label className="text-sm font-medium whitespace-normal">Position Y:</label>
        <input
          className="border border-gray-300 rounded p-1 w-full"
          type="number"
          value={localValues[id]?.positionY ?? position.y}
          onChange={(e) =>
            updateGlobalState(id, "positionY", parseFloat(e.target.value))
          }
        />

        <label className="text-sm font-medium whitespace-normal">Rotation:</label>
        <input
          className="border border-gray-300 rounded p-1 w-full"
          type="number"
          value={localValues[id]?.rotation ?? (object.rotation || 0)}
          onChange={(e) =>
            handleLocalInputChange(id, "rotation", parseFloat(e.target.value))
          }
          onBlur={() =>
            commitInputChange(object, "rotation", id)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              commitInputChange(object, "rotation", id);
            }
          }}
        />

        {/*  <label className="text-sm font-medium whitespace-normal">Line Width:</label>
        <input
          className="border border-gray-300 rounded p-1 w-full"
          type="number"
          value={localValues[id]?.lineWidth ?? (object.lineWidth || 0)}
          onChange={(e) =>
            handleLocalInputChange(id, "lineWidth", parseFloat(e.target.value))
          }
          onBlur={() =>
            commitInputChange(object, "lineWidth", id)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              commitInputChange(object, "lineWidth", id);
            }
          }}
        /> */}
      </div>
    );
  };

  const renderDoorTypeFields = (object: SymbolType & { doorWidth: number; openAngle?: number }) => {
    const id = object.id;

    return (
      <>
        {renderSymbolTypeFields(object)}
        <div
          key={`doorTypeFields-${id}`}
          className="grid grid-cols-2 gap-4 mb-4"
        >
          <label className="text-sm font-medium whitespace-normal">Door Width:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.doorWidth ?? object.doorWidth}
            onChange={(e) =>
              handleLocalInputChange(id, "doorWidth", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "doorWidth", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "doorWidth", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Open Angle:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.openAngle ?? (object.openAngle || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "openAngle", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "openAngle", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "openAngle", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Flip Horizontal:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="checkbox"
            //checked={localValues[id]?.flipHorizontal ?? (object.flipHorizontal || false)}
            checked={object.flipHorizontal || false}
            onChange={(e) =>
              //handleLocalInputChange(id, "flipHorizontal", e.target.checked)
              updateGlobalState(object, "flipHorizontal", e.target.checked)
            }
          // onBlur={() =>
          //   commitInputChange(object, "flipHorizontal", id)
          // }
          />

          <label className="text-sm font-medium whitespace-normal">Flip Vertical:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="checkbox"
            //checked={localValues[id]?.flipVertical ?? (object.flipVertical || false)}
            checked={object.flipVertical || false}
            onChange={(e) =>
              //handleLocalInputChange(id, "flipVertical", e.target.checked)
              updateGlobalState(object, "flipVertical", e.target.checked)
            }
          // onBlur={() =>
          //   commitInputChange(object, "flipVertical", id)
          // }
          />
        </div>
      </>
    );
  };

  const renderDoubleDoorTypeFields = (object: SymbolType & { doubleDoorWidth: number; openAngle?: number }) => {
    const id = object.id;

    return (
      <>
        {renderSymbolTypeFields(object)}
        <div
          key={`doubleDoorTypeFields-${id}`}
          className="grid grid-cols-2 gap-4 mb-4"
        >
          <label className="text-sm font-medium whitespace-normal">Double Door Width:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.doubleDoorWidth ?? object.doubleDoorWidth}
            onChange={(e) =>
              handleLocalInputChange(id, "doubleDoorWidth", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "doubleDoorWidth", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "doubleDoorWidth", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Open Angle:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.openAngle ?? (object.openAngle || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "openAngle", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "openAngle", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "openAngle", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Flip Horizontal:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="checkbox"
            //checked={localValues[id]?.flipHorizontal ?? (object.flipHorizontal || false)}
            checked={object.flipHorizontal || false}
            onChange={(e) =>
              //handleLocalInputChange(id, "flipHorizontal", e.target.checked)
              updateGlobalState(object, "flipHorizontal", e.target.checked)
            }
          // onBlur={() =>
          //   commitInputChange(object, "flipHorizontal", id)
          // }
          />

          <label className="text-sm font-medium whitespace-normal">Flip Vertical:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="checkbox"
            //checked={localValues[id]?.flipVertical ?? (object.flipVertical || false)}
            checked={object.flipVertical || false}
            onChange={(e) =>
              //handleLocalInputChange(id, "flipVertical", e.target.checked)
              updateGlobalState(object, "flipVertical", e.target.checked)
            }
          // onBlur={() =>
          //   commitInputChange(object, "flipVertical", id)
          // }
          />
        </div>
      </>
    );
  };

  const renderSvgTypeFields = (object: SymbolType & { svgPath: string; svgWidth: number; svgHeight: number; }) => {
    const id = object.id;

    return (
      <>
        {renderSymbolTypeFields(object)}
        <div
          key={`svgsTypeFields-${id}`}
          className="grid grid-cols-2 gap-4 mb-4"
        >
          <label className="text-sm font-medium whitespace-normal"> Width:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.svgWidth ?? object.svgWidth}
            onChange={(e) =>
              handleLocalInputChange(id, "svgWidth", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "svgWidth", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "svgWidth", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Height:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.svgHeight ?? object.svgHeight}
            onChange={(e) =>
              handleLocalInputChange(id, "svgHeight", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "svgHeight", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "svgsHeight", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Path:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.svgPath ?? (object.svgPath || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "svgPath", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "svgPath", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "svgPath", id);
              }
            }}
          />
        </div>
      </>
    );
  };
  const renderTextTypeFields = (object: SymbolType & {
    textBoxWidth: number; textBoxHeight: number; fontSize: number;
    fontStyle: string; fontWeight: string; lineHeight: number; text: string;
  }) => {
    const id = object.id;

    return (
      <>
        {renderSymbolTypeFields(object)}
        <div
          key={`textTypeFields-${id}`}
          className="grid grid-cols-2 gap-4 mb-4"
        >
          <label className="text-sm font-medium whitespace-normal"> Width:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.textBoxWidth ?? object.textBoxWidth}
            onChange={(e) =>
              handleLocalInputChange(id, "textBoxWidth", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "textBoxWidth", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "textBoxWidth", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Height:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.textBoxHeight ?? object.textBoxHeight}
            onChange={(e) =>
              handleLocalInputChange(id, "textBoxHeight", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "textBoxHeight", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "textBoxHeight", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Font Size:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.fontSize ?? (object.fontSize || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "fontSize", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "fontSize", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "fontSize", id);
              }
            }}
          />
          <label className="text-sm font-medium whitespace-normal">Font Style:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.fontStyle ?? (object.fontStyle || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "fontStyle", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "fontStyle", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "fontStyle", id);
              }
            }}
          />
          <label className="text-sm font-medium whitespace-normal">Font Weight:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.fontWeight ?? (object.fontWeight || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "fontWeight", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "fontWeight", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "fontWeight", id);
              }
            }}
          />
          <label className="text-sm font-medium whitespace-normal">Line Height:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.lineHeight ?? (object.lineHeight || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "lineHeight", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "lineHeight", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "lineHeight", id);
              }
            }}
          />
          <label className="text-sm font-medium whitespace-normal">Text:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="string"
            value={localValues[id]?.text ?? (object.text || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "text", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "text", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "text", id);
              }
            }}
          />
        </div>
      </>
    );
  };


  const renderCircleStairsTypeFields = (object: SymbolType & { circleStairsWidth: number; openAngle?: number; stairStepSize?: number; }) => {
    const id = object.id;

    return (
      <>
        {renderSymbolTypeFields(object)}
        <div
          key={`circleStairsTypeFields-${id}`}
          className="grid grid-cols-2 gap-4 mb-4"
        >
          <label className="text-sm font-medium whitespace-normal">Circle Stairs Width:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.circleStairsWidth ?? object.circleStairsWidth}
            onChange={(e) =>
              handleLocalInputChange(id, "circleStairsWidth", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "circleStairsWidth", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "circleStairsWidth", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Open Angle:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.openAngle ?? (object.openAngle || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "openAngleCs", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "openAngleCs", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "openAngleCs", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Stairs Step Size:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            step="0.1"
            value={localValues[id]?.stairStepSize ?? (object.stairStepSize || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "stairStepSize", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "stairStepSize", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "stairStepSize", id);
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderRectStairsTypeFields = (object: SymbolType & { rectStairsWidth: number; rectStairsHeight: number; openAngle?: number; stairStepSize?: number; }) => {
    const id = object.id;

    return (
      <>
        {renderSymbolTypeFields(object)}
        <div
          key={`rectStairsTypeFields-${id}`}
          className="grid grid-cols-2 gap-4 mb-4"
        >
          <label className="text-sm font-medium whitespace-normal">Rectangle Stairs Width:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.rectStairsWidth ?? object.rectStairsWidth}
            onChange={(e) =>
              handleLocalInputChange(id, "rectStairsWidth", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "rectStairsWidth", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "rectStairsWidth", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Rectangle Stairs Height:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.rectStairsHeight ?? object.rectStairsHeight}
            onChange={(e) =>
              handleLocalInputChange(id, "rectStairsHeight", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "rectStairsHeight", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "rectStairsHeight", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Open Angle:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.openAngle ?? (object.openAngle || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "openAngle", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "openAngle", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "openAngle", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Stairs Step Size:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            step="0.1"
            value={localValues[id]?.stairStepSize ?? (object.stairStepSize || 0)}
            onChange={(e) =>
              handleLocalInputChange(id, "stairStepSize", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "stairStepSize", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "stairStepSize", id);
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderWindowTypeFields = (object: SymbolType & { windowWidth: number; windowLength: number }) => {
    const id = object.id;

    return (
      <>
        {renderSymbolTypeFields(object)}
        <div
          key={`windowTypeFields-${id}`}
          className="grid grid-cols-2 gap-4 mb-4"
        >
          <label className="text-sm font-medium whitespace-normal">Window Width:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.windowWidth ?? object.windowWidth}
            onChange={(e) =>
              handleLocalInputChange(id, "windowWidth", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "windowWidth", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "windowWidth", id);
              }
            }}
          />

          <label className="text-sm font-medium whitespace-normal">Window Length:</label>
          <input
            className="border border-gray-300 rounded p-1 w-full"
            type="number"
            value={localValues[id]?.windowLength ?? object.windowLength}
            onChange={(e) =>
              handleLocalInputChange(id, "windowLength", parseFloat(e.target.value))
            }
            onBlur={() =>
              commitInputChange(object, "windowLength", id)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                commitInputChange(object, "windowLength", id);
              }
            }}
          />
        </div>
      </>
    );
  };
  const renderLineWeightFields = (object: WallType | SymbolType) => {
    const id = object.id;
    return (
      <div key={`wallTypeFields-${id}`}>
        <div>
          <div className="pb-2">Weight</div>
          <div className="flex flex-row items-center justify-center">
            <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
            <input
              className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="number"
              step={0.1}
              min={0.1}
              value={object.lineWeight ??
                (object.type === "wall" ? floorplannerStore.wallLineWeight :
                  object.type === "window" ? floorplannerStore.windowLineWeight :
                    object.type === "door" || object.type === "doubleDoor" ? floorplannerStore.doorLineWeight :
                      object.type === "circleStairs" || object.type === "rectStairs" ? floorplannerStore.symbolLineWeight :
                        object.type === "svg" ? floorplannerStore.symbolLineWeight : floorplannerStore.lineWeight)}
              onChange={(e) => {
                // Make sure input is a number and is not negative
                const value = Math.max(0.1, e.target.value ? parseFloat(e.target.value) : 0.1);
                updateGlobalState(id, "lineWeight", value)
              }}
            />
          </div>
        </div>
      </div>
    )
  }
  const renderWallTypeFields = (object: WallType) => {
    const id = object.id;
    const start = object.start || new THREE.Vector2(0, 0);
    const end = object.end || new THREE.Vector2(0, 0);

    return (
      <div
        key={`wallTypeFields-${id}`}
        className="grid grid-cols-2 gap-4 mb-4"
      >
        <label className="text-sm font-medium whitespace-normal">Start Position X:</label>
        <input
          className="border border-gray-300 rounded p-1 w-full"
          type="number"
          value={localValues[id]?.startX ?? start.x}
          onChange={(e) =>
            handleLocalInputChange(id, "startX", parseFloat(e.target.value))
          }
          onBlur={() =>
            commitInputChange(object, "start", id)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              commitInputChange(object, "start", id);
            }
          }}
        />
        <label className="text-sm font-medium whitespace-normal">Start Position Y:</label>
        <input
          className="border border-gray-300 rounded p-1 w-full"
          type="number"
          value={localValues[id]?.startY ?? start.y}
          onChange={(e) =>
            handleLocalInputChange(id, "startY", parseFloat(e.target.value))
          }
          onBlur={() =>
            commitInputChange(object, "start", id)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              commitInputChange(object, "start", id);
            }
          }}
        />
        <label className="text-sm font-medium whitespace-normal">End Position X:</label>
        <input
          className="border border-gray-300 rounded p-1 w-full"
          type="number"
          value={localValues[id]?.endX ?? end.x}
          onChange={(e) =>
            handleLocalInputChange(id, "endX", parseFloat(e.target.value))
          }
          onBlur={() =>
            commitInputChange(object, "end", id)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              commitInputChange(object, "end", id);
            }
          }}
        />
        <label className="text-sm font-medium whitespace-normal">End Position Y:</label>
        <input
          className="border border-gray-300 rounded p-1 w-full"
          type="number"
          value={localValues[id]?.endY ?? end.y}
          onChange={(e) =>
            handleLocalInputChange(id, "endY", parseFloat(e.target.value))
          }
          onBlur={() =>
            commitInputChange(object, "end", id)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              commitInputChange(object, "end", id);
            }
          }}
        />
        <label className="text-sm font-medium whitespace-normal">Wall Width:</label>
        <input
          className="border border-gray-300 rounded p-1 w-full"
          type="number"
          value={localValues[id]?.wallWidth ?? (object.wallWidth || 0)}
          onChange={(e) =>
            handleLocalInputChange(id, "wallWidth", parseFloat(e.target.value))
          }
          onBlur={() =>
            commitInputChange(object, "wallWidth", id)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              commitInputChange(object, "wallWidth", id);
            }
          }}
        />

        <label className="text-sm font-medium whitespace-normal">Curve wall:</label>
        <input
          className="border border-gray-300 rounded p-1 w-full"
          type="checkbox"
          checked={object.lineForm === "arc" || false}
          onChange={(e) => {
            console.log("lineForm", e.target.checked, object.lineForm)
            updateGlobalState(object, "lineForm", e.target.checked ? "arc" : "straight")
          }}
        // onBlur={() =>
        //   commitInputChange(object, "flipHorizontal", id)
        // }
        />

      </div>
    );
  };

  const renderHierarchy = () => {
    const wallIds = Object.keys(floorplannerStore.walls);
    const symbolIds = Object.keys(floorplannerStore.symbols);

    return (
      <div className="space-y-4">
        <div>
          <h3 className="text-lg font-semibold">Walls ({wallIds.length})</h3>
          <ul className="pl-4">
            {wallIds.map((id) => (
              <li
                key={id}
                className="cursor-pointer text-blue-500 hover:underline"
                onClick={() => handleSelectItem(id, true)}
              >
                Wall {id}
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="text-lg font-semibold">Symbols ({symbolIds.length})</h3>
          <ul className="pl-4">
            {symbolIds.map((id) => (
              <li
                key={id}
                className="cursor-pointer text-blue-500 hover:underline"
                onClick={() => handleSelectItem(id, false)}
              >
                {isDoorType(floorplannerStore.symbols[id])
                  ? `Door ${id}`
                  : isDoubleDoorType(floorplannerStore.symbols[id])
                    ? `Double Door ${id}`
                    : isCircleStairsType(floorplannerStore.symbols[id])
                      ? `Circle Stair ${id}`
                      : isRectStairsType(floorplannerStore.symbols[id])
                        ? `Rectangle Stair ${id}`
                        : isSvgType(floorplannerStore.symbols[id])
                          ? ` SVG ${id}`
                          : isTextType(floorplannerStore.symbols[id])
                            ? ` Text ${id}`
                            : isWindowType(floorplannerStore.symbols[id])
                              ? `Window ${id}`
                              : `Symbol ${id}`}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const PropertyHeader = () => {
    return (
      <div className="flex items-center justify-between flex-cols select-none pb-3">
        <div className="p-2">
          <div className="flex h-[34px] w-[34px] items-center justify-center gap-2 rounded-lg border border-stone-500 p-1 relative cursor-pointer">
            <IoMdInformationCircleOutline style={{ strokeWidth: 2 }} size={19} />
          </div>
        </div>
        <div className="p-3 flex flex-row">
          <div
            className={`flex h-[34px] w-[34px] items-center justify-center gap-2 rounded-lg border border-stone-500 p-1 relative mr-2 cursor-pointer ${optionMenuOpen ? "bg-black text-white" : ""
              }`}
            onClick={() => setOptionMenuOpen(!optionMenuOpen)}
            ref={optionMenuRef}
          >
            <div className="text-zinc-950 font-['DM Sans'] pb-1 text-center text-xl font-normal">
              ···
            </div>
            {optionMenuOpen && (
              <div className="fixed right-20 top-16 w-auto bg-black text-white border border-gray-300 shadow-lg rounded-lg py-2 z-20">
                <div
                  className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                  onClick={() => { }}
                >
                  <CgImage className="text-xl transform scale-90" />
                  Post in gallery
                </div>
                <div className="border-b border-[#66645F]" />
                <div
                  className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                  onClick={() => { }}
                >
                  <FiDownload className="text-xl transform scale-90" />
                  Download project
                </div>
                <div className="border-b border-[#66645F]" />
                {floorplans.map((floorplan) => (
                  <div
                    key={floorplan.id}
                    className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                    onClick={() => {
                      window.location.href = `/floorplan/${floorplan.id}`;
                      setOptionMenuOpen(false);
                    }}
                  >
                    <GrDocumentImage className="text-xl transform scale-90" />
                    {floorplan.name} ({floorplan.id})
                  </div>
                ))}
                <div className="border-b border-[#66645F]" />
                <div
                  className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                  onClick={() => {
                    window.location.href = "/floorplan/";
                  }}
                >
                  <HiOutlinePlus className="text-xl transform scale-90" />
                  Create a new project
                </div>
                <div
                  className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                  onClick={() => { }}
                >
                  <LuCopy className="text-xl transform scale-90" />
                  Copy project
                </div>
                <div
                  className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                  onClick={() => {
                    floorplannerStore.deleteFromCloud(floorplannerStore.id);
                    setOptionMenuOpen(false);
                  }}
                >
                  <RiDeleteBinLine className="text-xl transform scale-90" />
                  Delete project
                </div>
              </div>
            )}
          </div>
          <div className="flex h-[34px] items-center justify-center gap-2 rounded-lg border border-stone-500 p-2.5 bg-[#082AC8] cursor-pointer">
            <div className="text-white font-['DM Sans'] text-center text-sm font-normal">
              Share
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <PropertyHeader />
      {!editorStore.minimized && (
        <>
          {/* <div className="p-3 pb-0 flex items-center justify-between">
            <div className=""></div>
            <div className="flex flex-row items-center justify-center text-[10px]">
              <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button>
              <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
            </div>
          </div> */}
          {editorStore.selections.filter((selection) => selection.type === "wall").map((selectedWall) => {
            const wall = floorplannerStore.walls[selectedWall.id] as WallType;
            return (
              <div
                key={`wall-${wall.id}`}
              >
                <div className="text-sm font-normal p-3">
                  <div className="flex items-center justify-between">
                    <div className="">Wall component</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button>
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="flex items-center justify-between py-2">
                    <div className="flex flex-row items-center justify-center">
                      <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(
                          floorplannerStore.wallOuterLength(wall)
                        )}
                        onChange={(e) =>
                          updateGlobalState(wall.id, "length", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(
                          wall.wallWidth || floorplannerStore.wallWidth
                        )}
                        onChange={(e) =>
                          updateGlobalState(wall.id, "wallWidth", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between py-2">
                    <div className="flex flex-row items-center justify-center">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiAngle />
                      </div>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isDoorType(selection)).map((object) => {
            const door = floorplannerStore.symbols[object.id] as DoorType;
            return (
              <div
                key={`singleDoor-${object.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">Single Door</div>
                    <div className="text-zinc-950 font-['DM Sans'] pr-1.5 text-center text-xl font-normal">
                      ···
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Position</div>
                  </div>
                  <div className="px-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position X">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">X</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(door.position.x)}
                        onChange={(e) =>
                          updateGlobalState(door.id, "positionX", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position Y">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">Y</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(door.position.y)}
                        onChange={(e) =>
                          updateGlobalState(door.id, "positionY", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(door.doorWidth)}
                        onChange={(e) =>
                          updateGlobalState(door.id, "doorWidth", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Length">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(door.doorWidth)}
                        onChange={(e) =>
                          updateGlobalState(door.id, "doorWidth", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Open Angle</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Door Angle">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <PiAngle />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={90}
                          value={
                            // Convert to degrees
                            (door.openAngle * 180) / Math.PI
                          }
                          onChange={(e) =>
                            updateGlobalState(door.id, "openAngle",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((door.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(door.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pl-4 flex items-center justify-between">
                    <div className="">Door Hanging</div>
                  </div>
                  <div className="p-3 items-left py-2">
                    <div className="flex flex-row items-center pb-4">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiFlipHorizontalFill />
                      </div>
                      <input
                        className="px-1 py-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="checkbox"
                        checked={door.flipHorizontal}
                        onChange={(e) =>
                          updateGlobalState(door.id, "flipHorizontal", e.target.checked)
                        }
                      />&nbsp;Horizontal
                    </div>
                    <div className="flex flex-row items-center pb-4">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiFlipVerticalFill />
                      </div>
                      <input
                        className="px-1 py-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="checkbox"
                        checked={door.flipVertical}
                        onChange={(e) =>
                          updateGlobalState(door.id, "flipVertical", e.target.checked)
                        }
                      />&nbsp;Vertical
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isDoubleDoorType(selection)).map((object) => {
            const door = floorplannerStore.symbols[object.id] as DoubleDoorType;
            return (
              <div
                key={`doubleDoor-${object.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">Double Door</div>
                    <div className="text-zinc-950 font-['DM Sans'] pr-1.5 text-center text-xl font-normal">
                      ···
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Position</div>
                  </div>
                  <div className="px-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position X">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">X</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(door.position.x)}
                        onChange={(e) =>
                          updateGlobalState(door.id, "positionX", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position Y">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">Y</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(door.position.y)}
                        onChange={(e) =>
                          updateGlobalState(door.id, "positionY", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(door.doubleDoorWidth)}
                        onChange={(e) =>
                          updateGlobalState(door.id, "doorWidth", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Length">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(door.doubleDoorWidth)}
                        onChange={(e) =>
                          updateGlobalState(door.id, "doubleDoorWidth", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Open Angle</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Door Angle">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <PiAngle />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={90}
                          value={
                            // Convert to degrees
                            (door.openAngle * 180) / Math.PI
                          }
                          onChange={(e) =>
                            updateGlobalState(door.id, "openAngle",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((door.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(door.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pl-4 flex items-center justify-between">
                    <div className="">Door Hanging</div>
                  </div>
                  <div className="p-3 items-left py-2">
                    <div className="flex flex-row items-center pb-4">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiFlipHorizontalFill />
                      </div>
                      <input
                        className="px-1 py-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="checkbox"
                        checked={door.flipHorizontal}
                        onChange={(e) =>
                          updateGlobalState(door.id, "flipHorizontal", e.target.checked)
                        }
                      />&nbsp;Horizontal
                    </div>
                    <div className="flex flex-row items-center pb-4">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiFlipVerticalFill />
                      </div>
                      <input
                        className="px-1 py-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="checkbox"
                        checked={door.flipVertical}
                        onChange={(e) =>
                          updateGlobalState(door.id, "flipVertical", e.target.checked)
                        }
                      />&nbsp;Vertical
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isWindowType(selection)).map((object) => {
            const window = floorplannerStore.symbols[object.id] as WindowType;
            return (
              <div
                key={`window-${window.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">Window</div>
                    <div className="text-zinc-950 font-['DM Sans'] pr-1.5 text-center text-xl font-normal">
                      ···
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Position</div>
                  </div>
                  <div className="px-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position X">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">X</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(window.position.x)}
                        onChange={(e) =>
                          updateGlobalState(window.id, "positionX", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position Y">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">Y</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(window.position.y)}
                        onChange={(e) =>
                          updateGlobalState(window.id, "positionY", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(window.windowWidth)}
                        onChange={(e) =>
                          updateGlobalState(window.id, "windowWidth", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Length">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(window.windowLength)}
                        onChange={(e) =>
                          updateGlobalState(window.id, "windowLength", convertMillimeterToWorld(parseFloat(e.target.value)))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((window.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(window.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isCircleStairsType(selection)).map((object) => {
            const circleStairs = floorplannerStore.symbols[object.id] as CircleStairsType;
            return (
              <div
                key={`circleStair-${circleStairs.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">Circle Staircase</div>
                    <div className="text-zinc-950 font-['DM Sans'] pr-1.5 text-center text-xl font-normal">
                      ···
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Position</div>
                  </div>
                  <div className="px-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position X">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">X</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(circleStairs.position.x)}
                        onChange={(e) =>
                          updateGlobalState(circleStairs.id, "positionX", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position Y">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">Y</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(circleStairs.position.y)}
                        onChange={(e) =>
                          updateGlobalState(circleStairs.id, "positionY", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(circleStairs.circleStairsWidth)}
                        onChange={(e) =>
                          updateGlobalState(circleStairs.id, "circleStairsWidth", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Step depth</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Step depth">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">SD</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(circleStairs.stairStepSize || floorplannerStore.stairStepSize)}
                        onChange={(e) =>
                          updateGlobalState(circleStairs.id, "stairStepSize", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((circleStairs.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(circleStairs.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isRectStairsType(selection)).map((object) => {
            const rectStair = floorplannerStore.symbols[object.id] as RectStairsType;
            return (
              <div
                key={`rectStair-${rectStair.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">Rectangular Staircase</div>
                    <div className="text-zinc-950 font-['DM Sans'] pr-1.5 text-center text-xl font-normal">
                      ···
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Position</div>
                  </div>
                  <div className="px-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position X">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">X</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(rectStair.position.x)}
                        onChange={(e) =>
                          updateGlobalState(rectStair.id, "positionX", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position Y">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">Y</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(rectStair.position.y)}
                        onChange={(e) =>
                          updateGlobalState(rectStair.id, "positionY", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(rectStair.rectStairsWidth)}
                        onChange={(e) =>
                          updateGlobalState(rectStair.id, "rectStairsWidth", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Step depth</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Step depth">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">SD</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(rectStair.stairStepSize || floorplannerStore.stairStepSize)}
                        onChange={(e) =>
                          updateGlobalState(rectStair.id, "stairStepSize", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((rectStair.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(rectStair.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isSvgType(selection)).map((object) => {
            const svg = floorplannerStore.symbols[object.id] as SvgType;
            return (
              <div
                key={`svg-${svg.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">{object.name ?? "SVG Symbol"}</div>
                    <div className="text-zinc-950 font-['DM Sans'] pr-1.5 text-center text-xl font-normal">
                      ···
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Position</div>
                  </div>
                  <div className="px-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position X">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">X</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(svg.position.x)}
                        onChange={(e) =>
                          updateGlobalState(svg.id, "positionX", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Position Y">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">Y</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(svg.position.y)}
                        onChange={(e) =>
                          updateGlobalState(svg.id, "positionY", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(svg.svgWidth)}
                        onChange={(e) =>
                          updateGlobalState(svg.id, "svgWidth", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Length">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">H</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(svg.svgHeight)}
                        onChange={(e) =>
                          updateGlobalState(svg.id, "svgHeight", parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((svg.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(svg.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.length !== 0 && (
            <>
              <div className="text-sm font-normal p-3">
                <div className="flex items-center justify-between" onClick={() => setLinePropsOpen(!linePropsOpen)}>
                  <div className="">Line</div>
                  <div className="flex flex-row items-center justify-center">
                    {linePropsOpen ? "-" : "+"}
                  </div>
                </div>
              </div>
              <div
                className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${linePropsOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
                  }`}
              >
                <div className="text-sm font-normal p-3 pt-0">
                  {editorStore.selections.map((object) => {
                    let currentObject
                    if (object.type === "wall") {
                      currentObject = floorplannerStore.walls[object.id];
                    } else {
                      currentObject = floorplannerStore.symbols[object.id];
                    }
                    return (
                      <div
                        key={`lineProps-${object.id}`}
                      >
                        <div className="flex items-center justify-between py-2 pt-0">

                          {renderLineWeightFields(currentObject)}
                          <div>
                            <div className="pb-2">Type</div>
                            <div className="flex flex-row items-center justify-center">
                              <select className="px-1 py-2 w-[90px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option>Single</option>
                                <option>Hollow</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {renderLineColorInput(currentObject)}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="border-b border-gray-300" />
            </>
          )}
          {editorStore.selections.length !== 0 && (
            <>
              <div className="text-sm font-normal p-3">
                <div className="flex items-center justify-between" onClick={() => setFillPropsOpen(!fillPropsOpen)}>
                  <div className="">Fill</div>
                  <div className="flex flex-row items-center justify-center">
                    {fillPropsOpen ? "-" : "+"}
                  </div>
                </div>
              </div>
              <div
                className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${fillPropsOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
                  }`}
              >
                <div className="text-sm font-normal p-3">
                  {editorStore.selections.map((object) => renderFillColorInput(object))}
                </div>
              </div>
              <div className="border-b border-gray-300" />
            </>
          )}
          <div className="text-sm font-normal p-3">
            <div className="flex items-center justify-between" onClick={() => setLayerPropsOpen(!layerPropsOpen)}>
              <div className="">Layers</div>
              <div className="flex flex-row items-center justify-center">{layerPropsOpen ? "-" : "+"}</div>
            </div>
          </div>
          <div
            className={`text-sm font-normal overflow-hidden transition-[max-height] duration-500 ease-in-out ${layerPropsOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
              }`}
          >
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={symbolOrder} strategy={verticalListSortingStrategy}>
                <div className="flex flex-col w-full"> {/* Reduced gap for smaller rows */}
                  {symbolsWithLabels.map(({ symbolId, label }) => (
                    <SortableItem
                      key={symbolId}
                      id={symbolId}
                      label={label}
                      icon={null}
                      isSelected={isSymbolSelected(symbolId)} // Check if the symbol is selected
                      onClick={(e) => {
                        // If shift is not pressed, clear any previous selection
                        if (!e.shiftKey) {
                          editorStore.clearSelections()
                        }
                        if (isSymbolSelected(symbolId)) {
                          editorStore.removeSelection(floorplannerStore.symbols[symbolId])
                          floorplannerStore.updateSymbolProperty(symbolId, "selected", false);
                        } else {
                          editorStore.addSelection(floorplannerStore.symbols[symbolId])
                          floorplannerStore.updateSymbolProperty(symbolId, "selected", true);
                        }
                        invalidate()
                      }}
                    />
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          </div>
          <div className="border-b border-gray-300" />
          <div className="text-sm font-normal p-3">
            <div className="flex items-center justify-between" onClick={() => setOtherPropsOpen(!otherPropsOpen)}>
              <div className="">Other</div>
              <div className="flex flex-row items-center justify-center">{otherPropsOpen ? "-" : "+"}</div>
            </div>
          </div>
          <div
            className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${otherPropsOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
              }`}
          >
            <div className="text-sm font-normal p-3">
              {editorStore.selections.length > 0 ? (
                editorStore.selections.map((selectedObject) => {
                  let object
                  if (selectedObject.type === "wall") {
                    object = floorplannerStore.walls[selectedObject.id];
                  } else {
                    object = floorplannerStore.symbols[selectedObject.id];
                  }
                  return (
                    <div
                      key={`otherProps-${object.id}`}
                      className="p-4 border border-gray-200 rounded-lg mb-4"
                    >
                      <h3 className="text-md font-semibold mb-2">
                        {isDoorType(object)
                          ? `Door ${object.id}`
                          : isDoubleDoorType(object)
                            ? `Double Door ${object.id}`
                            : isCircleStairsType(object)
                              ? `Circle Stairs ${object.id}`
                              : isRectStairsType(object)
                                ? `Rectangle Stairs ${object.id}`
                                : isSvgType(object)
                                  ? `SVG Symbol ${object.id}`
                                  : isTextType(object)
                                    ? `Text Symbol ${object.id}`
                                    : isWindowType(object)
                                      ? `Window ${object.id}`
                                      : "start" in object && "end" in object
                                        ? `Wall ${object.id}`
                                        : `Symbol ${object.id}`}
                      </h3>
                      {isDoorType(object) && renderDoorTypeFields(object)}
                      {isDoubleDoorType(object) && renderDoubleDoorTypeFields(object)}
                      {isCircleStairsType(object) && renderCircleStairsTypeFields(object)}
                      {isRectStairsType(object) && renderRectStairsTypeFields(object)}
                      {isWindowType(object) && renderWindowTypeFields(object)}
                      {isSvgType(object) && renderSvgTypeFields(object)}
                      {isTextType(object) && renderTextTypeFields(object)}
                      {!isDoorType(object) &&
                        !isDoubleDoorType(object) &&
                        !isCircleStairsType(object) &&
                        !isRectStairsType(object) &&
                        !isWindowType(object) &&
                        !isSvgType(object) &&
                        !isTextType(object) &&
                        renderSymbolTypeFields(object as SymbolType)}
                      {"start" in object && "end" in object && renderWallTypeFields(object as WallType)}
                    </div>
                  )
                })
              ) : (
                renderHierarchy()
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default PropertyPanel;
