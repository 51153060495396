import { Line } from "@react-three/drei";
import * as THREE from "three";
import { WallType } from "../../types/wallTypes";
import { floorplannerStore } from "../../store/floorplannerStore";

export const updateAlignmentLines = (
    wall: WallType,
    walls: Record<string, WallType>,
    tempWalls: Record<string, WallType>,
    newX: number,
    newY: number,
    dragHandle: string
) => {
    const newAlignmentLines: JSX.Element[] = [];
    const tolerance = 0.005; // Sensitivity for alignment

    Object.values(walls).forEach((otherWall) => {
        if (otherWall.id !== wall.id) {
            if (
                dragHandle === "wall" ||
                dragHandle === "middleV" ||
                dragHandle === "middleH"
            ) {
                // Align both start and end
                const wallStartX = tempWalls[wall.id].start.x;
                const wallStartY = tempWalls[wall.id].start.y;
                const wallEndX = tempWalls[wall.id].end.x;
                const wallEndY = tempWalls[wall.id].end.y;

                const alignedXStart =
                    Math.abs(otherWall.start.x - wallStartX) < tolerance ||
                    Math.abs(otherWall.end.x - wallStartX) < tolerance;
                const alignedYStart =
                    Math.abs(otherWall.start.y - wallStartY) < tolerance ||
                    Math.abs(otherWall.end.y - wallStartY) < tolerance;

                if (alignedXStart) {
                    newAlignmentLines.push(
                        <Line
                            key={`align-x-start-${otherWall.id}`}
                            points={[[wallStartX, -1000, 0], [wallStartX, 1000, 0]]}
                            color="blue"
                            lineWidth={0.5}
                        />
                    );
                }

                if (alignedYStart) {
                    newAlignmentLines.push(
                        <Line
                            key={`align-y-start-${otherWall.id}`}
                            points={[[-1000, wallStartY, 0], [1000, wallStartY, 0]]}
                            color="blue"
                            lineWidth={0.5}
                        />
                    );
                }

                const alignedXEnd =
                    Math.abs(otherWall.start.x - wallEndX) < tolerance ||
                    Math.abs(otherWall.end.x - wallEndX) < tolerance;
                const alignedYEnd =
                    Math.abs(otherWall.start.y - wallEndY) < tolerance ||
                    Math.abs(otherWall.end.y - wallEndY) < tolerance;

                if (alignedXEnd) {
                    newAlignmentLines.push(
                        <Line
                            key={`align-x-end-${otherWall.id}`}
                            points={[[wallEndX, -1000, 0], [wallEndX, 1000, 0]]}
                            color="blue"
                            lineWidth={0.5}
                        />
                    );
                }

                if (alignedYEnd) {
                    newAlignmentLines.push(
                        <Line
                            key={`align-y-end-${otherWall.id}`}
                            points={[[-1000, wallEndY, 0], [1000, wallEndY, 0]]}
                            color="blue"
                            lineWidth={0.5}
                        />
                    );
                }
            } else {
                // When dragging start or end handle, align respective point
                const alignedX =
                    Math.abs(otherWall.start.x - newX) < tolerance ||
                    Math.abs(otherWall.end.x - newX) < tolerance;
                const alignedY =
                    Math.abs(otherWall.start.y - newY) < tolerance ||
                    Math.abs(otherWall.end.y - newY) < tolerance;

                if (alignedX) {
                    newAlignmentLines.push(
                        <Line
                            key={`align-x-${otherWall.id}`}
                            points={[[newX, -1000, 0], [newX, 1000, 0]]}
                            color="blue"
                            lineWidth={0.5}
                        />
                    );
                }

                if (alignedY) {
                    newAlignmentLines.push(
                        <Line
                            key={`align-y-${otherWall.id}`}
                            points={[[-1000, newY, 0], [1000, newY, 0]]}
                            color="blue"
                            lineWidth={0.5}
                        />
                    );
                }
            }
        }
    });

    floorplannerStore.setAlignmentLines(newAlignmentLines);
};
