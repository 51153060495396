/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */
import { useRef, useEffect, useState, useCallback } from "react";
import { invalidate, ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useFloorplannerStore, floorplannerStore } from '../../store/floorplannerStore';
import {
  WallShapeType,
  Point,
  WallType,
  WallConnectionStart,
  WallConnectionEnd,
  Room,
} from "../../types/wallTypes";
import { trimIntersectingLines } from "./trimIntersectingLines";
import {
  createMiddleDragHandle,
  handleSize,
  handleWidth,
} from "./createMiddleDragHandle";
import { updateWallConnections } from "./updateWallConnections";
import { observer } from "mobx-react-lite";
import { action, set } from "mobx";
import { projectToWorld } from "./projectToWorld";
import { editorStore } from '../../store/editorStore';
import { Line, Text, Html } from "@react-three/drei";
import { convertWorldToMillimeter, convertMillimeterToWorld } from '../../utils/conversions';
import { UpDownArrow } from "./UpDownArrow";
import { LeftRightArrow } from "./LeftRightArrow";
import { fillMeshWalls } from "./fillMeshWalls";
import { composeLines } from "./composeLines";
import { dragStartHandle } from "./dragStartHandle";
import { dragEndHandle } from "./dragEndHandle";
import { dragMiddleVHandle } from "./dragMiddleVHandle";
import { dragMiddleHHandle } from "./dragMiddleHHandle";
import { dragWallHandle } from "./dragWallHandle";
import { dragControlHandle } from "./dragControlHandle";
import { updateAlignmentLines } from "./updateAligmentLines";
import { detectRooms } from "./findRooms";
import { RoomLabels } from "./RoomLabels";

const WallStructure = observer(() => {
  const floorplannerStore = useFloorplannerStore();
  const {
    walls,
    setWalls,
    wallWidth,
    lineColor,
    fillColor,
    hoverColor,
  } = floorplannerStore;
  const dragWall = useRef<WallType | null>(null);
  const dragOffset = useRef<[number, number, number, number] | null>(null);
  const dragHandle = useRef<{
    handle: string;
    startPosition: THREE.Vector3;
    endPosition: THREE.Vector3;
  }>({
    handle: "",
    startPosition: new THREE.Vector3(),
    endPosition: new THREE.Vector3(),
  });
  const [dragging, setDragging] = useState(false);
  const [dragEndFreeToSnap, setDragEndFreeToSnap] = useState(false);
  const { camera, gl } = useThree();
  const [meshWalls, setMeshWalls] = useState<THREE.Mesh[]>([]);
  const scene = useThree((state) => state.scene);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [hoveredHandle, setHoveredHandle] = useState<string | null>(null);
  const isHovered = (handle: string) => hoveredHandle === handle;
  const noopRaycast = () => null;
  const customLineWeight = floorplannerStore.convertLineWeightToWorld(floorplannerStore.lineWeight)
  const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);
  const [rooms, setRooms] = useState<Room[]>([]);

  useFrame(() => {
    if (camera.zoom !== prevCameraZoom) {
      setPrevCameraZoom(camera.zoom);
      invalidate(); // Trigger a re-render
    }
  });
  
  useEffect(() => {
    const handleKeyDown = (e: { key: string; }) => {
      if (e.key === 'Shift') setIsShiftPressed(true);
    };

    const handleKeyUp = (e: { key: string; }) => {
      if (e.key === 'Shift') setIsShiftPressed(false);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const disposeMesh = useCallback((mesh: THREE.Mesh) => {
    mesh.traverse((child: THREE.Object3D) => {
      const _child = child as THREE.Mesh;
      if (_child.geometry) _child.geometry.dispose();
      if (_child.material) {
        if (Array.isArray(_child.material)) {
          _child.material.forEach((material) => material.dispose());
        } else {
          _child.material.dispose();
        }
      }
    });
  }, []);

  const updateDragHandles = useCallback((handle: string, wall: WallType) => {
    const handleOffset = 0.035;
    const start = new THREE.Vector3(wall.start.x, wall.start.y, 0);
    const end = new THREE.Vector3(wall.end.x, wall.end.y, 0);
    const wallDirection = new THREE.Vector3()
      .subVectors(end, start)
      .normalize();
    const startHandle = new THREE.Vector3().addVectors(
      start,
      wallDirection.clone().multiplyScalar(-handleOffset),
    );
    const endHandle = new THREE.Vector3().addVectors(
      end,
      wallDirection.clone().multiplyScalar(handleOffset),
    );
    dragHandle.current.handle = handle;
    dragHandle.current.startPosition = startHandle;
    dragHandle.current.endPosition = endHandle;
  }, []);

  useEffect(() => {
    const handleContextLost = (event: Event) => {
      event.preventDefault();
      console.warn("WebGL context lost");
    };

    const handleContextRestored = () => {
      console.log("WebGL context restored");
      invalidate();
    };

    gl.domElement.addEventListener(
      "webglcontextlost",
      handleContextLost as EventListener,
    );
    gl.domElement.addEventListener(
      "webglcontextrestored",
      handleContextRestored as EventListener,
    );

    return () => {
      gl.domElement.removeEventListener(
        "webglcontextlost",
        handleContextLost as EventListener,
      );
      gl.domElement.removeEventListener(
        "webglcontextrestored",
        handleContextRestored as EventListener,
      );
    };
  }, [gl.domElement]);

  useEffect(() => {
    const filledLines: WallShapeType[] = [];
    const leftLines: WallShapeType[] = [];
    const rightLines: WallShapeType[] = [];
    const endCaps: WallShapeType[] = [];

    composeLines(filledLines, leftLines, rightLines, endCaps, floorplannerStore);

    trimIntersectingLines(rightLines, leftLines, walls, scene);
    trimIntersectingLines(leftLines, rightLines, walls, scene);
    trimIntersectingLines(filledLines, filledLines, walls, scene);

    const newMeshWalls: THREE.Mesh[] = [];

    fillMeshWalls(filledLines, leftLines, rightLines, endCaps, newMeshWalls, floorplannerStore);

    meshWalls.forEach((mesh) => {
      disposeMesh(mesh);
    });

    setMeshWalls(newMeshWalls);
    invalidate();

    return () => {
      newMeshWalls.forEach((mesh) => {
        disposeMesh(mesh);
      });
    };
  }, [walls, wallWidth, lineColor, fillColor, disposeMesh, scene, camera.zoom]);

  const onWallPointerEnter = useCallback(
    (event: ThreeEvent<PointerEvent>, index: number) => {
      event.stopPropagation();
      // Change cursor to grab when hovering over a wall
      if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.style.cursor = "grab";
      let updated = false;

      // Get the wall data to check if it's selected
      const wallData = walls[Object.keys(walls)[index]];

      // Iterate through the wall's children and apply hoverColor to lines
      meshWalls[index].children.forEach((child) => {
        const mesh = child as THREE.Mesh;
        if (child.userData.type === "line") {
          const currentColor = (mesh.material as THREE.MeshBasicMaterial).color.getHex();

          // Always apply hoverColor when hovered, whether selected or not
          if (currentColor !== hoverColor) {
            (mesh.material as THREE.MeshBasicMaterial).color.set(hoverColor);
            updated = true;
          }
        }
      });
      if (updated) {
        invalidate();
      }
    },
    [hoverColor, meshWalls, walls],
  );

  const onWallPointerLeave = useCallback(
    (event: ThreeEvent<PointerEvent>, index: number) => {
      event.stopPropagation();
      // Reset cursor when leaving a wall
      if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.style.cursor = "auto";
      let updated = false;

      // Get the wall data to check if it's selected
      const wallData = walls[Object.keys(walls)[index]];

      meshWalls[index].children.forEach((child) => {
        const mesh = child as THREE.Mesh;
        if (child.userData.type === "line") {
          const currentColor = (mesh.material as THREE.MeshBasicMaterial).color.getHex();

          // If the wall is selected, keep the hoverColor
          // Otherwise, revert back to the default line color
          const newColor = wallData?.selected ? hoverColor : (walls[Object.keys(walls)[index]]?.lineColor || lineColor);

          if (currentColor !== newColor) {
            (mesh.material as THREE.MeshBasicMaterial).color.set(newColor);
            updated = true;
          }
        }
      });
      if (updated) {
        invalidate();
      }
    },
    [meshWalls, walls, lineColor, hoverColor],
  );

  const handlePointerMove = useCallback(
    action((e: PointerEvent) => {
      if (dragWall.current !== null && dragOffset.current) {
        if (!dragging) setDragging(true);
        let [newX, newY] = projectToWorld(e.clientX, e.clientY, gl, camera);
        const [startOffsetX, startOffsetY, endOffsetX, endOffsetY] =
          dragOffset.current;
        const wall = dragWall.current;
        const tempWalls = { ...walls };

        let delta = new THREE.Vector2(0, 0);
        let snapped = false;
        if (dragHandle.current.handle === "start") {
          snapped = dragStartHandle(wall, startOffsetX, startOffsetY, newX, newY, tempWalls, dragEndFreeToSnap, isShiftPressed, delta);
        } else if (dragHandle.current.handle === "end") {
          snapped = dragEndHandle(wall, endOffsetX, endOffsetY, newX, newY, tempWalls, dragEndFreeToSnap, isShiftPressed, delta);
        } else if (dragHandle.current.handle === "middleV") {
          snapped = dragMiddleVHandle(wall, startOffsetX, startOffsetY, newX, newY, tempWalls, delta);
        } else if (dragHandle.current.handle === "middleH") {
          snapped = dragMiddleHHandle(wall, startOffsetX, startOffsetY, newX, newY, tempWalls, delta);
        } else if (dragHandle.current.handle === "wall") {
          snapped = dragWallHandle(wall, startOffsetX, startOffsetY, endOffsetX, endOffsetY, newX, newY, tempWalls, delta);
        } else if (dragHandle.current.handle === "control") {
          dragControlHandle(wall, newX, newY, tempWalls);
        }
        updateDragHandles(dragHandle.current?.handle || "", tempWalls[wall.id]);

        // Update alignment lines based on the handle type
        updateAlignmentLines(
          wall,
          walls,
          tempWalls,
          newX,
          newY,
          dragHandle.current.handle,
        );

        if (!delta.equals(new THREE.Vector2(0, 0))) {
          updateWallConnections(
            tempWalls,
            wall,
            dragHandle.current?.handle || null,
            [wall.id],
            floorplannerStore
          );
        }
        setWalls(tempWalls);
      }
    }),
    [dragging, projectToWorld, setWalls, updateDragHandles, walls, camera.zoom],
  );


  useEffect(() => {
    const handlePointerUp = () => {
      dragWall.current = null;
      dragOffset.current = null;
      dragHandle.current.handle = "";
      gl.domElement.removeEventListener("pointermove", handlePointerMove);
      gl.domElement.removeEventListener("pointerup", handlePointerUp);
      floorplannerStore.setBlockDirty(false);
      if (dragging) floorplannerStore.setDirty();
    };

    if (dragWall.current !== null) {
      gl.domElement.addEventListener("pointermove", handlePointerMove);
      gl.domElement.addEventListener("pointerup", handlePointerUp);
      floorplannerStore.setBlockDirty(true);
    }

    return () => {
      gl.domElement.removeEventListener("pointermove", handlePointerMove);
      gl.domElement.removeEventListener("pointerup", handlePointerUp);
    };
  }, [handlePointerMove, gl.domElement]);

  const handlePointerUp = useCallback(() => {
    dragWall.current = null;
    dragOffset.current = null;
    dragHandle.current.handle = "";
    setDragging(false);
    // Change cursor back to auto when dragging is done
    document.body.style.cursor = "auto";
    gl.domElement.removeEventListener("pointermove", handlePointerMove);
    gl.domElement.removeEventListener("pointerup", handlePointerUp);
    floorplannerStore.setBlockDirty(false);
    if (dragging) floorplannerStore.setDirty();
  }, [gl.domElement, handlePointerMove]);

  const onWallPointerDown = useCallback(
    (event: ThreeEvent<PointerEvent>, index: number) => {
      event.stopPropagation();
      // Change cursor to grabbing when dragging a wall
      document.body.style.cursor = "grabbing";
      const wall = walls[Object.keys(walls)[index]];
      dragWall.current = wall;
      updateDragHandles(dragHandle.current?.handle || "wall", wall);
      const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
      const startOffsetX = worldX - wall.start.x;
      const startOffsetY = worldY - wall.start.y;
      const endOffsetX = worldX - wall.end.x;
      const endOffsetY = worldY - wall.end.y;
      dragOffset.current = [startOffsetX, startOffsetY, endOffsetX, endOffsetY];
      editorStore.addSelection(wall);
      floorplannerStore.selectWall(wall.id);

      // Ensure the wall keeps hoverColor when selected or dragged
      meshWalls[index].children.forEach((child) => {
        const mesh = child as THREE.Mesh;
        if (child.userData.type === "line") {
          (mesh.material as THREE.MeshBasicMaterial).color.set(hoverColor);
        }
      });

      floorplannerStore.setBlockDirty(true);
      gl.domElement.addEventListener("pointermove", handlePointerMove);
      gl.domElement.addEventListener("pointerup", handlePointerUp);
      floorplannerStore.setBlockDirty(true);
    },
    [
      walls,
      updateDragHandles,
      projectToWorld,
      gl.domElement,
      handlePointerMove,
      handlePointerUp,
      meshWalls, // Ensure the hoverColor is applied when selected/dragged
      hoverColor,
      camera.zoom
    ],
  );

  const onWallSelectionPointerDown = useCallback(
    (
      event: ThreeEvent<PointerEvent>,
      index: number,
      handle: "start" | "end" | "middleV" | "middleH" | "control",
    ) => {
      event.stopPropagation();
      const wall = walls[Object.keys(walls)[index]];
      dragWall.current = wall;
      updateDragHandles(handle, wall);
      const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
      let offsetX, offsetY;

      if (handle === "middleV" || handle === "middleH" || handle === "control") {
        const midpointX = (wall.start.x + wall.end.x) / 2;
        const midpointY = (wall.start.y + wall.end.y) / 2;
        offsetX = worldX - midpointX;
        offsetY = worldY - midpointY;
      } else {
        offsetX = worldX - (handle === "start" ? wall.start.x : wall.end.x);
        offsetY = worldY - (handle === "start" ? wall.start.y : wall.end.y);
      }
      if (handle === "start" || handle === "end") {
        const isWallEdgeConnected = wall.connections?.find(
          (c) => (c.targetPosition === WallConnectionStart || c.targetPosition === WallConnectionEnd) &&
            c.sourcePosition === (handle === 'start' ? WallConnectionStart : WallConnectionEnd),
        );
        setDragEndFreeToSnap(!isWallEdgeConnected);
      }

      dragOffset.current = [offsetX, offsetY, offsetX, offsetY];
      editorStore.addSelection(wall);
      floorplannerStore.selectWall(wall.id);
      gl.domElement.addEventListener("pointermove", handlePointerMove);
      gl.domElement.addEventListener("pointerup", handlePointerUp);
      floorplannerStore.setBlockDirty(true);
    },
    [
      walls,
      updateDragHandles,
      projectToWorld,
      gl.domElement,
      handlePointerMove,
      handlePointerUp,
      camera.zoom
    ],
  );

  const handleCanvasClick = useCallback(() => {
    editorStore.clearSelections();
    editorStore.setWallEditingLength(undefined);
  }, []);

  useEffect(() => {
    gl.domElement.addEventListener("pointerdown", handleCanvasClick);
    return () => {
      gl.domElement.removeEventListener("pointerdown", handleCanvasClick);
    };
  }, [gl.domElement, handleCanvasClick]);

  const calculateWallPositionAndRotation = (start: THREE.Vector2, end: THREE.Vector2) => {
    const position = new THREE.Vector3(
      (start.x + end.x) / 2,
      (start.y + end.y) / 2,
      0.001 // Slightly above the floor
    );

    const angle = Math.atan2(end.y - start.y, end.x - start.x);

    // Calculate the perpendicular offset vector (normalized)
    const perpendicular = new THREE.Vector2(
      -(end.y - start.y),
      end.x - start.x
    ).normalize();

    // Calculate the correct text rotation
    const textRotation = angle > Math.PI / 2 || angle < -Math.PI / 2
      ? angle + Math.PI // Flip the text if it's upside down
      : angle;

    return { position, rotation: angle, perpendicular, textRotation };
  };

  const handleLengthClick = (wallId: string) => {
    editorStore.setWallEditingLength(wallId); // Set the ID of the wall being edited
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, wall: WallType) => {
    if (editorStore.wallEditingLength === wall.id) {
      if (event.key === "Enter") {
        const newLength = parseFloat((event.target as HTMLInputElement).value);
        if (!isNaN(newLength)) {
          floorplannerStore.setWallLength(wall, convertMillimeterToWorld(newLength));
          editorStore.setWallEditingLength(undefined);
        }
      }
    }
  };

  const calculateRotatedPosition = (position: THREE.Vector3, origin: THREE.Vector3, angle: number): THREE.Vector3 => {
    const cosTheta = Math.cos(angle);
    const sinTheta = Math.sin(angle);

    const x = cosTheta * (position.x - origin.x) - sinTheta * (position.y - origin.y) + origin.x;
    const y = sinTheta * (position.x - origin.x) + cosTheta * (position.y - origin.y) + origin.y;

    return new THREE.Vector3(x, y, position.z); // Ensure to preserve z position
  };

  useEffect(() => {
    // Detect rooms after the walls are updated
    const detectedRooms = detectRooms(floorplannerStore.walls);
    setRooms(detectedRooms);
  }, [floorplannerStore.walls]);
  
  return (
    <group>
      {meshWalls.map((wall, index) => {
        const wallData = walls[wall.userData.id];
        if (!wallData || !wallData.end || !wallData.start) return null;

        // Calculate the perpendicular vector (90 degrees to the line direction)
        const innerPerpendicular = floorplannerStore.wallPerpendicularInner(wallData);
        const outerPerpendicular = floorplannerStore.wallPerpendicularOuter(wallData);

        const { position, rotation, textRotation } = calculateWallPositionAndRotation(
          wallData.start,
          wallData.end
        );

        const rulerLineOffset = 0.2 + (wallData.wallWidth || floorplannerStore.wallWidth) / 2; // Offset to move the line away from the wall
        const rulerOuterTextOffset = 0.2 + (wallData.wallWidth || floorplannerStore.wallWidth) / 2; // Offset to move the outer ruler text away from the line
        const rulerInnerTextOffset = 0.13 + (wallData.wallWidth || floorplannerStore.wallWidth) / 2; // Offset to move the text away from the line
        const rulerTextBoxPadding = 0.15 / editorStore.zoomLevelDivisor(); // Padding for the text background box
        const rulerTextBoxHeight = 0.3 / editorStore.zoomLevelDivisor(); // Height of the text background box
        const rulerTextBoxWidth = 0.5 / editorStore.zoomLevelDivisor(); // Estimated width of the text background box
        const rulerTextFontSize = 0.2 / editorStore.zoomLevelDivisor(); // Font size of the text
        const stopLineLength = 0.1; // Length of the endcap lines
        const wallDirection = floorplannerStore.wallDirection(wallData);
        const wallOppositeDirection = floorplannerStore.wallOppositeDirection(wallData);
        const wallStartCapLength = floorplannerStore.wallStartCapLength(wallData);
        const wallEndCapLength = floorplannerStore.wallEndCapLength(wallData);
        const wallMinMidHandleSize = editorStore.zoomLevel() < 3 ? Math.max(0.15, 0.15 / editorStore.zoomLevelDivisor()) : 0.1; // Minimum size of the mid-handle
        const wallMidHandleGap = 0.09 / editorStore.zoomLevelDivisor(); // Gap between the mid-handles

        return (
          <group
            key={wall.userData.id}
            onPointerEnter={!dragging && !editorStore.wallConstructionMode ? (event) => onWallPointerEnter(event, index) : undefined}
            onPointerLeave={!dragging && !editorStore.wallConstructionMode ? (event) => onWallPointerLeave(event, index) : undefined}
            onPointerDown={!editorStore.wallConstructionMode ? (event) => onWallPointerDown(event, index) : undefined}
          >
            <primitive object={wall} />
            {(editorStore.wallShowLength || wallData?.selected) && (
              <group>
                {/* A Measurement line parallell offset from the wall */}
                <Line
                  points={[
                    [wallData.start.x + outerPerpendicular.x * rulerLineOffset + wallOppositeDirection.x * wallStartCapLength, wallData.start.y + outerPerpendicular.y * rulerLineOffset + wallOppositeDirection.y * wallStartCapLength, 0],
                    [wallData.end.x + outerPerpendicular.x * rulerLineOffset + wallDirection.x * wallEndCapLength, wallData.end.y + outerPerpendicular.y * rulerLineOffset + wallDirection.y * wallEndCapLength, 0],
                  ]}
                  color="black"
                  lineWidth={1}
                  dashed={false}
                />

                <Line
                  points={[
                    [
                      (wallData.start.x + outerPerpendicular.x * stopLineLength) + (rulerLineOffset * outerPerpendicular.x) + (wallOppositeDirection.x * wallStartCapLength),
                      (wallData.start.y + outerPerpendicular.y * stopLineLength) + (rulerLineOffset * outerPerpendicular.y) + (wallOppositeDirection.y * wallStartCapLength),
                      0,
                    ],
                    [
                      (wallData.start.x - outerPerpendicular.x * stopLineLength) + (rulerLineOffset * outerPerpendicular.x) + (wallOppositeDirection.x * wallStartCapLength),
                      (wallData.start.y - outerPerpendicular.y * stopLineLength) + (rulerLineOffset * outerPerpendicular.y) + (wallOppositeDirection.y * wallStartCapLength),
                      0,
                    ],
                  ]}
                  color="black"
                  lineWidth={1}
                />

                <Line
                  points={[
                    [
                      (wallData.end.x + outerPerpendicular.x * stopLineLength) + (rulerLineOffset * outerPerpendicular.x) + (wallDirection.x * wallEndCapLength),
                      (wallData.end.y + outerPerpendicular.y * stopLineLength) + (rulerLineOffset * outerPerpendicular.y) + (wallDirection.y * wallEndCapLength),
                      0,
                    ],
                    [
                      (wallData.end.x - outerPerpendicular.x * stopLineLength) + (rulerLineOffset * outerPerpendicular.x) + (wallDirection.x * wallEndCapLength),
                      (wallData.end.y - outerPerpendicular.y * stopLineLength) + (rulerLineOffset * outerPerpendicular.y) + (wallDirection.y * wallEndCapLength),
                      0,
                    ],
                  ]}
                  color="black"
                  lineWidth={1}
                />

                {/* Group for outer ruler measure */}
                <group
                  position={new THREE.Vector3(
                    position.x + outerPerpendicular.x * rulerOuterTextOffset,
                    position.y + outerPerpendicular.y * rulerOuterTextOffset,
                    0.02 // Slightly above the line and endcaps
                  )}
                  rotation={[0, 0, textRotation]} // Apply rotation to both text and background
                >
                  {/* Background Box behind the text */}
                  {editorStore.wallEditingLength !== wallData.id && (
                    <mesh position={[0, 0, -0.01]}>
                      <planeGeometry args={[rulerTextBoxWidth + rulerTextBoxPadding, rulerTextBoxHeight]} />
                      <meshBasicMaterial
                        color={wallData?.selected ? 0xE5EAFF : 0xFFFFFF}
                        depthWrite={false}
                        depthTest={true}
                        blending={THREE.NormalBlending}
                      />
                    </mesh>
                  )}
                  {/* Measurement text */}
                  <Text
                    position={[0, 0, 0]} // Text and box are aligned at the same position
                    fontSize={rulerTextFontSize}
                    color="black"
                    onClick={() => handleLengthClick(wallData.id)}
                  >
                    {editorStore.wallEditingLength === wallData.id ? (
                      <Html center style={
                        {
                          position: 'absolute',
                          top: '-3px',
                        }
                      }>
                        <input
                          type="text"
                          defaultValue={convertWorldToMillimeter(
                            floorplannerStore.wallOuterLength(wallData)
                          )}
                          onKeyPress={(event) => handleKeyPress(event, wallData)}
                          autoFocus
                          style={{
                            background: "white",
                            border: "none",
                            color: "black",
                            fontSize: "10px", // Match the size of the 3D text
                            textAlign: "center",
                            outline: "none", // Remove the focus outline
                            width: "60px", // Adjust the width as needed
                            height: "15px", // Adjust the height as needed
                          }}
                          onFocus={(event) => event.target.select()}
                        />
                      </Html>
                    ) : (
                      `${convertWorldToMillimeter(
                        floorplannerStore.wallOuterLength(wallData)
                      )}`
                    )}
                  </Text>
                </group>
                {/* Group for inner ruler measure */}
                {false && floorplannerStore.wallIsConnected(wallData, true) && (
                  <group
                    position={new THREE.Vector3(
                      position.x + innerPerpendicular.x * rulerInnerTextOffset,
                      position.y + innerPerpendicular.y * rulerInnerTextOffset,
                      0.011 // Slightly above the line and endcaps
                    )}
                    rotation={[0, 0, textRotation]} // Apply rotation to both text and background
                  >
                    {/* Background Box behind the text */}
                    <mesh position={[0, 0, -0.01]}>
                      <planeGeometry args={[rulerTextBoxWidth / 1.5 + rulerTextBoxPadding, rulerTextBoxHeight / 1.5]} />
                      <meshBasicMaterial
                        color={"darkblue"}
                      />
                    </mesh>
                    {/* Measurement text */}
                    <Text
                      position={[0, 0, 0]} // Text and box are aligned at the same position
                      fontSize={rulerTextFontSize / 1.3}
                      color="white"
                    >
                      {convertWorldToMillimeter(floorplannerStore.wallInnerLength(wallData))}
                    </Text>
                  </group>
                )}
              </group>
            )}
            {(wallData?.selected && !editorStore.wallConstructionMode) && (
              <group>
                {/* Middle, start, and end handles */}
                {(!dragging || dragHandle.current?.handle === "middleV") && (
                  <group
                    position={
                      calculateRotatedPosition(
                        position.clone().add(new THREE.Vector3(-(((wallData.wallWidth || wallWidth) / 2) + wallMidHandleGap), 0, 0.001)),
                        position,
                        rotation
                      )}
                    rotation={[0, 0, rotation]}
                    onPointerDown={(event) => onWallSelectionPointerDown(event, index, "middleV")}
                    onPointerEnter={(event) => {
                      event.stopPropagation();
                      document.body.style.cursor = "move";
                      setHoveredHandle("middleV")
                    }}
                    onPointerLeave={(event) => {
                      event.stopPropagation();
                      if (!dragging) document.body.style.cursor = "auto";
                      setHoveredHandle(null)
                    }}
                  >
                    <mesh>
                      <planeGeometry args={[
                        Math.max(wallMinMidHandleSize + 0.03, (wallData.wallWidth || wallWidth) + customLineWeight * 5),
                        Math.max(wallMinMidHandleSize + 0.03, (wallData.wallWidth || wallWidth) + customLineWeight * 5)
                      ]} />
                      <meshBasicMaterial color={isHovered("middleV") || dragHandle.current?.handle === "middleV" ? "blue" : "white"} />
                    </mesh>
                    {/* Draw an up-down-arrow using Line */}
                    <UpDownArrow
                      wall={wallData}
                      wallWidth={Math.max(wallMinMidHandleSize, wallWidth)}
                      position={[0, 0, 0]}
                      color={isHovered("middleV") || dragHandle.current?.handle === "middleV" ? "white" : "black"}
                    />
                    <mesh raycast={noopRaycast}>
                      <primitive object={createMiddleDragHandle(wallData, Math.max(wallMinMidHandleSize, wallWidth))} />
                      <meshBasicMaterial color={isHovered("middleV") || dragHandle.current?.handle === "middleV" ? "white" : "black"} transparent opacity={0.8} />
                    </mesh>
                  </group>
                )}
                {(!dragging || dragHandle.current?.handle === "middleH") && (
                  <group
                    position={
                      calculateRotatedPosition(
                        position.clone().add(new THREE.Vector3(((wallData.wallWidth || wallWidth) / 2) + wallMidHandleGap, 0, 0.001)),
                        position,
                        rotation
                      )}
                    rotation={[0, 0, rotation]}
                    onPointerDown={(event) => onWallSelectionPointerDown(event, index, "middleH")}
                    onPointerEnter={(event) => {
                      event.stopPropagation();
                      document.body.style.cursor = "move";
                      setHoveredHandle("middleH")
                    }}
                    onPointerLeave={(event) => {
                      event.stopPropagation();
                      if (!dragging) document.body.style.cursor = "auto";
                      setHoveredHandle(null)
                    }}
                  >
                    <mesh>
                      <planeGeometry args={[
                        Math.max(wallMinMidHandleSize + 0.03, (wallData.wallWidth || wallWidth) + customLineWeight * 5),
                        Math.max(wallMinMidHandleSize + 0.03, (wallData.wallWidth || wallWidth) + customLineWeight * 5)
                      ]} />
                      <meshBasicMaterial color={isHovered("middleH") || dragHandle.current?.handle === "middleH" ? "blue" : "white"} />
                    </mesh>
                    {/* Draw a left-right-arrow using Line */}
                    <LeftRightArrow
                      wall={wallData}
                      wallWidth={Math.max(wallMinMidHandleSize, wallWidth)}
                      position={[0, 0, 0]}
                      color={isHovered("middleH") || dragHandle.current?.handle === "middleH" ? "white" : "black"}
                    />
                    <mesh raycast={noopRaycast}>
                      <primitive object={createMiddleDragHandle(wallData, Math.max(wallMinMidHandleSize, wallWidth))} />
                      <meshBasicMaterial color={isHovered("middleH") || dragHandle.current?.handle === "middleH" ? "white" : "black"} transparent opacity={0.8} />
                    </mesh>
                  </group>
                )}
                {(!dragging || dragHandle.current?.handle === "start") && (
                  <group
                    position={new THREE.Vector3(wallData.start.x, wallData.start.y, 0.001)}
                    onPointerDown={(event) => onWallSelectionPointerDown(event, index, "start")}
                    onPointerEnter={(event) => {
                      event.stopPropagation();
                      document.body.style.cursor = "move";
                      setHoveredHandle("start")
                    }}
                    onPointerLeave={(event) => {
                      event.stopPropagation();
                      if (!dragging) document.body.style.cursor = "auto";
                      setHoveredHandle(null)
                    }}
                  >
                    <mesh>
                      <planeGeometry args={[(handleWidth * 2), (handleWidth * 2)]} />
                      <meshBasicMaterial
                        //color="white"
                        transparent
                        opacity={0.0}
                      />
                    </mesh>
                    <mesh raycast={noopRaycast}>
                      <torusGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0.003, 16, 100]} />
                      <meshBasicMaterial color="blue" />
                    </mesh>
                    <mesh
                      position={[0, 0, 0]}
                      rotation={[Math.PI / 2, 0, 0]}
                      raycast={noopRaycast}
                    >
                      <cylinderGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0, 0, 32]} />
                      <meshBasicMaterial color={isHovered("start") ? "blue" : "white"} />
                    </mesh>
                  </group>
                )}

                {(!dragging || dragHandle.current?.handle === "end") && (
                  <group
                    position={new THREE.Vector3(0, 0, 0.001)}
                    onPointerDown={(event) => onWallSelectionPointerDown(event, index, "end")}
                    onPointerEnter={(event) => {
                      event.stopPropagation();
                      document.body.style.cursor = "move";
                      setHoveredHandle("end")
                    }}
                    onPointerLeave={(event) => {
                      event.stopPropagation();
                      if (!dragging) document.body.style.cursor = "auto";
                      setHoveredHandle(null)
                    }}
                  >
                    {/* Draw a circle grab handle for the end */}
                    <group position={new THREE.Vector3(wallData.end.x, wallData.end.y, 0.001)}>
                      <mesh>
                        <planeGeometry args={[handleSize * 2, handleSize * 2]} />
                        <meshBasicMaterial
                          //color="white"
                          transparent
                          opacity={0.0}
                        />
                      </mesh>
                      <mesh raycast={noopRaycast}>
                        <torusGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0.003, 16, 100]} />
                        <meshBasicMaterial color="blue" />
                      </mesh>
                      <mesh
                        position={[0, 0, 0]}
                        rotation={[Math.PI / 2, 0, 0]}
                        raycast={noopRaycast}
                      >
                        <cylinderGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0, 0, 32]} />
                        <meshBasicMaterial color={isHovered("end") ? "blue" : "white"} />
                      </mesh>
                    </group>
                  </group>
                )}

                {(wallData.lineForm === "arc" && (!dragging || dragHandle.current?.handle === "control")) && (
                  <group
                    position={new THREE.Vector3(0, 0, 0.001)}
                    onPointerDown={(event) => onWallSelectionPointerDown(event, index, "control")}
                    onPointerEnter={(event) => {
                      event.stopPropagation();
                      document.body.style.cursor = "move";
                      setHoveredHandle("control")
                    }}
                    onPointerLeave={(event) => {
                      event.stopPropagation();
                      if (!dragging) document.body.style.cursor = "auto";
                      setHoveredHandle(null)
                    }}
                  >
                    {/* Draw a circle grab handle for the control point */}
                    <group
                      position={
                        wallData.controlPoint ? new THREE.Vector3(wallData.controlPoint.x, wallData.controlPoint.y, 0.001) :
                          new THREE.Vector3(
                            wallData.start.x + (wallData.end.x - wallData.start.x) / 2,
                            wallData.start.y + (wallData.end.y - wallData.start.y) / 2,
                            0.001)
                      }>
                      <mesh>
                        <planeGeometry args={[(handleSize * 2) / editorStore.zoomLevelDivisor(), (handleSize * 2) / editorStore.zoomLevelDivisor()]} />
                        <meshBasicMaterial
                          //color="white"
                          transparent
                          opacity={0.0}
                        />
                      </mesh>
                      <mesh raycast={noopRaycast}>
                        <torusGeometry args={[handleSize / editorStore.zoomLevelDivisor(), 0.004, 16, 100]} />
                        <meshBasicMaterial color="blue" />
                      </mesh>
                      <mesh
                        position={[0, 0, 0]}
                        rotation={[Math.PI / 2, 0, 0]}
                        raycast={noopRaycast}
                      >
                        <cylinderGeometry args={[handleSize / editorStore.zoomLevelDivisor(), 0, 0, 32]} />
                        <meshBasicMaterial color={isHovered("control") ? "blue" : "white"} />
                      </mesh>
                    </group>
                  </group>
                )}
              </group>
            )}
          </group>
        );
      })}
      {(dragging || editorStore.wallConstructionMode) &&
        floorplannerStore.alignmentLines
      }
      {/* Render room area labels */}
      <RoomLabels rooms={rooms} />
    </group>
  );
});

export default WallStructure;
