import React, { useRef, useState } from 'react';
import { useThree, invalidate } from '@react-three/fiber';
import * as THREE from 'three';
import { Line } from '@react-three/drei';
import { editorStore } from '../../store/editorStore';
import { floorplannerStore } from '../../store/floorplannerStore';

export const MAX_PLANE_SIZE = 300;

const SelectionPlane: React.FC = () => {
    const { camera, raycaster, gl, scene } = useThree();
    const isSelecting = useRef(false);
    const startPoint = useRef<THREE.Vector3 | null>(null);
    const endPoint = useRef<THREE.Vector3 | null>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [wireframePoints, setWireframePoints] = useState<THREE.Vector3[]>([]);

    const handlePointerDown = (event: PointerEvent) => {
        if (!editorStore.view3D && !editorStore.wallConstructionMode && editorStore.panning) {
            isSelecting.current = true;
            setIsDrawing(true);
            startPoint.current = getWorldPositionFromMouse(event.clientX, event.clientY);
            setWireframePoints([]);
        }
    };

    const handlePointerMove = (event: PointerEvent) => {
        if (isSelecting.current && startPoint.current && editorStore.panning) {
            endPoint.current = getWorldPositionFromMouse(event.clientX, event.clientY);

            if (endPoint.current) {
                // Create rectangle wireframe points from start and end points
                const points = [
                    new THREE.Vector3(startPoint.current.x, startPoint.current.y, 0),
                    new THREE.Vector3(endPoint.current.x, startPoint.current.y, 0),
                    new THREE.Vector3(endPoint.current.x, endPoint.current.y, 0),
                    new THREE.Vector3(startPoint.current.x, endPoint.current.y, 0),
                    new THREE.Vector3(startPoint.current.x, startPoint.current.y, 0), // Closing the loop
                ];

                setWireframePoints(points);
            }
        }
    };

    const handlePointerUp = () => {
        if (isSelecting.current && editorStore.panning) {
            isSelecting.current = false;
            setIsDrawing(false);

            // Perform selection of walls and symbols
            if (wireframePoints.length && startPoint.current && endPoint.current) {
                selectObjectsInBox(startPoint.current, endPoint.current);
            }
        }
    };

    const getWorldPositionFromMouse = (clientX: number, clientY: number): THREE.Vector3 | null => {
        // Convert screen coordinates (clientX, clientY) into normalized device coordinates (NDC)
        const ndcX = (clientX / window.innerWidth) * 2 - 1;
        const ndcY = -(clientY / window.innerHeight) * 2 + 1;

        // Create a vector from the NDC and unproject it to world coordinates
        const mouseVector = new THREE.Vector2(ndcX, ndcY);
        raycaster.setFromCamera(mouseVector, camera);

        // Define a plane on which to project the mouse (here we use the XY plane at Z=0)
        const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
        const intersectionPoint = new THREE.Vector3();

        if (raycaster.ray.intersectPlane(plane, intersectionPoint)) {
            return intersectionPoint;
        }

        return null;
    };

    const projectToScreen = (vector: THREE.Vector3): THREE.Vector2 => {
        const projected = vector.clone().project(camera);
        return new THREE.Vector2(
            (projected.x + 1) * 0.5 * window.innerWidth,
            (1 - projected.y) * 0.5 * window.innerHeight
        );
    };

    const selectObjectsInBox = (start: THREE.Vector3, end: THREE.Vector3) => {
        const walls = floorplannerStore.walls;
        const symbols = floorplannerStore.symbols;

        // Convert the world space start and end points to screen space
        const screenStart = projectToScreen(start);
        const screenEnd = projectToScreen(end);

        const minX = Math.min(screenStart.x, screenEnd.x);
        const minY = Math.min(screenStart.y, screenEnd.y);
        const maxX = Math.max(screenStart.x, screenEnd.x);
        const maxY = Math.max(screenStart.y, screenEnd.y);

        Object.values(symbols).forEach((symbol) => {
            const symbolPosition = new THREE.Vector3(symbol.position.x, symbol.position.y, 0);
            const symbolScreenPos = projectToScreen(symbolPosition);

            if (
                symbolScreenPos.x >= minX &&
                symbolScreenPos.x <= maxX &&
                symbolScreenPos.y >= minY &&
                symbolScreenPos.y <= maxY
            ) {
                // Symbol is within the selection box
                floorplannerStore.selectSymbol(symbol.id);
                editorStore.addSelection(symbol);
            }
        });

        Object.values(walls).forEach((wall) => {
            const start = new THREE.Vector3(wall.start.x, wall.start.y, 0);
            const end = new THREE.Vector3(wall.end.x, wall.end.y, 0);

            const startScreenPos = projectToScreen(start);
            const endScreenPos = projectToScreen(end);

            if (
                (startScreenPos.x >= minX && startScreenPos.x <= maxX && startScreenPos.y >= minY && startScreenPos.y <= maxY) ||
                (endScreenPos.x >= minX && endScreenPos.x <= maxX && endScreenPos.y >= minY && endScreenPos.y <= maxY)
            ) {
                // Wall is within the selection box
                floorplannerStore.selectWall(wall.id);
                editorStore.addSelection(wall);
            }
        });

        invalidate(); // Trigger a render to reflect the selection changes
    };

    return (
        <>
            <mesh
                position={[0, 0, -0.01]} // Ensure the plane is in the background
                onPointerDown={(e) => handlePointerDown(e.nativeEvent)}
                onPointerMove={(e) => handlePointerMove(e.nativeEvent)}
                onPointerUp={handlePointerUp}
            >
                <planeGeometry args={[MAX_PLANE_SIZE * 1.5, MAX_PLANE_SIZE * 1.5]} />
                <meshBasicMaterial transparent opacity={0} />
            </mesh>

            {/* Render wireframe selection box */}
            {isDrawing && wireframePoints.length > 0 && (
                <Line
                    points={wireframePoints} // Array of points for the wireframe
                    color="blue"
                    lineWidth={1} // Adjust thickness of the line
                />
            )}
        </>
    );
};

export default SelectionPlane;
