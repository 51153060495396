import { Line } from "@react-three/drei";
import { WallType } from "../../types/wallTypes";

interface UpDownArrowProps {
    wall: WallType;
    wallWidth: number;
    position: [number, number, number];
    color: string;
}

export const UpDownArrow: React.FC<UpDownArrowProps> = ({
    wall: wallData,
    wallWidth,
    position,
    color
}) => {
    const noopRaycast = () => null;
    return (
        <group position={position} raycast={noopRaycast}>
            {/* Draw an up-down-arrow using Line */}
            <Line
                position={[0, 0, 0.002]}
                points={[
                    [0, (wallData.wallWidth || wallWidth) / 3, 0],
                    [0, -(wallData.wallWidth || wallWidth) / 3, 0],
                ]}
                color={color}
                lineWidth={1}
                raycast={noopRaycast}
            />
            <Line
                position={[0, 0, 0.002]}
                points={[
                    [0, (wallData.wallWidth || wallWidth) / 3, 0],
                    [0.02, (wallData.wallWidth || wallWidth) / 3 - 0.02, 0],
                ]}
                color={color}
                lineWidth={1}
                raycast={noopRaycast}
            />
            <Line
                position={[0, 0, 0.002]}
                points={[
                    [0, (wallData.wallWidth || wallWidth) / 3, 0],
                    [-0.02, (wallData.wallWidth || wallWidth) / 3 - 0.02, 0],
                ]}
                color={color}
                lineWidth={1}
                raycast={noopRaycast}
            />
            <Line
                position={[0, 0, 0.002]}
                points={[
                    [0, -(wallData.wallWidth || wallWidth) / 3, 0],
                    [-0.02, -(wallData.wallWidth || wallWidth) / 3 + 0.02, 0],
                ]}
                color={color}
                lineWidth={1}
                raycast={noopRaycast}
            />
            <Line
                position={[0, 0, 0.002]}
                points={[
                    [0, -(wallData.wallWidth || wallWidth) / 3, 0],
                    [0.02, -(wallData.wallWidth || wallWidth) / 3 + 0.02, 0],
                ]}
                color={color}
                lineWidth={1}
                raycast={noopRaycast}
            />
        </group>
    );
};