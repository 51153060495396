import React, { useRef, useState, useEffect, useCallback } from "react";
import * as THREE from "three";
import { invalidate, useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { CircleStairsType, DoorType } from "../../types/wallTypes";
import { SelectableSymbol, BoundingBox } from "./SelectableSymbol";
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import AttachableSymbol from "./AttachableSymbol";
import { Line } from "@react-three/drei";
import { set } from "mobx";

interface CircleStairsProps {
  circleStair: CircleStairsType;
  onDragStart: (circleStair: CircleStairsType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const CircleStairs: React.FC<CircleStairsProps> = ({
  circleStair,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const [currentOpenAngle, setCurrentOpenAngle] = useState(circleStair.openAngle);
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const attachableRef = useRef<{ checkForAttachment: () => void }>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);
  const lineWeight = circleStair.lineWeight || floorplannerStore.symbolLineWeight;
  const [circleStairsWidth, setCircleStairsWidth] = useState(circleStair.circleStairsWidth); // Radius of the circle stairs ar
  
  const grabHandleSize = 0.15;
  const handleRadius = grabHandleSize / 2;
  const handleDepth = 0.02; // Depth of the handles

  const flipX = circleStair.flipHorizontal ? -1 : 1; // Flip horizontally
  const flipY = circleStair.flipVertical ? -1 : 1; // Flip vertically
  const hingeOffsetX = flipX === -1 ? circleStairsWidth : 0; 
  const [centerPosition, setCenterPosition] = useState<[number, number]>([
    circleStairsWidth/ 2,
    circleStairsWidth / 2,
  ]);
  const lineColor = circleStair.lineColor || floorplannerStore.lineColor;
  const stairStepSize = circleStair.stairStepSize || floorplannerStore.stairStepSize;
 // Calculate handle position relative to hinge
 const openAngleGrabHandleX = hingeOffsetX + circleStairsWidth * 0.8 * Math.cos(currentOpenAngle) * flipX;
 const openAngleGrabHandleY = circleStairsWidth * 0.8 * Math.sin(currentOpenAngle) * flipY;

 const [hoveredHandle, setHoveredHandle] = useState<string | null>(null);
  const isHovered = (handle: string) => hoveredHandle === handle;
  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) {
      return {
        topLeft: [0, 0],
        topRight: [0, 0],
        bottomLeft: [0, 0],
        bottomRight: [0, 0],
        width: 0,
        height: 0,
        depth: 0,
      };
    }

    // Clone the object
    const tempGroup = groupRef.current.clone();

    // Save the original rotation
    const originalRotation = tempGroup.rotation.clone();

    // Temporarily reset the rotation to 0
    tempGroup.rotation.set(0, 0, 0);

    // Calculate the bounding box as if the object had no rotation
    const boundingBox = new THREE.Box3().setFromObject(tempGroup);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    // Restore the original rotation
    tempGroup.rotation.copy(originalRotation);

    // Calculate the corners of the bounding box
    const topLeft = new THREE.Vector3(
      boundingBox.min.x,
      boundingBox.max.y,
      0
    );
    const topRight = new THREE.Vector3(
      boundingBox.max.x,
      boundingBox.max.y,
      0
    );
    const bottomLeft = new THREE.Vector3(
      boundingBox.min.x,
      boundingBox.min.y,
      0
    );
    const bottomRight = new THREE.Vector3(
      boundingBox.max.x,
      boundingBox.min.y,
      0
    );

    setCenterPosition([topLeft.x + size.x / 2, bottomLeft.y + size.y / 2]);

    return {
      topLeft: [topLeft.x, topLeft.y],
      topRight: [topRight.x, topRight.y],
      bottomLeft: [bottomLeft.x, bottomLeft.y],
      bottomRight: [bottomRight.x, bottomRight.y],
      width: size.x,
      height: size.y,
      depth: size.z,
    };
  }, [circleStair.position.x, circleStair.position.y, circleStairsWidth, currentOpenAngle]);

  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = (event: PointerEvent) => {
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dx = worldX - circleStair.position.x - dragOffset.current.x;
    const dy = worldY - circleStair.position.y - dragOffset.current.y;
    let angle = Math.atan2(dy * flipY, dx * flipX);
    angle = angle < 0 ? angle + 2 * Math.PI : angle;
  
    const lowerBoundToSnap90 = (75 * Math.PI) / 180; // 85 degrees in radians
    const upperBoundToSnap90 = (105 * Math.PI) / 180; // 95 degrees in radians
    const lowerBoundToSnap180 = (165 * Math.PI) / 180; // 165 degrees in radians
    const upperBoundToSnap180 = (195 * Math.PI) / 180; // 195 degrees in radians
    const lowerBoundToSnap270 = (255 * Math.PI) / 180; // 255 degrees in radians
    const upperBoundToSnap270 = (285 * Math.PI) / 180; // 285 degrees in radians
    const lowerBoundToSnap360 = (345 * Math.PI) / 180; // 345 degrees in radians
    const upperBoundToSnap360 = (360 * Math.PI) / 180; // 360 degrees in radians
  
    // Check if the current open angle is within snapping ranges
    if (angle >= lowerBoundToSnap90 && angle <= upperBoundToSnap90) {
      // Snap to 90 degrees if in range 75 to 105
      const snappedAngle = (90* Math.PI) / 180;
      setCurrentOpenAngle(snappedAngle);
      floorplannerStore.updateSymbolProperty(circleStair.id, "openAngleCs", snappedAngle);
    } else if (angle >= lowerBoundToSnap180 && angle <= upperBoundToSnap180) {
      
      // Snap to 180 degrees if in range 165 to 195
      const snappedAngle = Math.PI; 
      setCurrentOpenAngle(snappedAngle);
      floorplannerStore.updateSymbolProperty(circleStair.id, "openAngleCs", snappedAngle);
    } else if (angle >= lowerBoundToSnap270 && angle <= upperBoundToSnap270) {
      // Snap to 270 degrees if in range 255 to 285
      const snappedAngle = (270 * Math.PI) / 180; 
      setCurrentOpenAngle(snappedAngle);
      floorplannerStore.updateSymbolProperty(circleStair.id, "openAngleCs", snappedAngle);
    } else if (angle >= lowerBoundToSnap360 && angle <= upperBoundToSnap360) {
      // Snap to 360 degrees if in range 345 to 360
      const snappedAngle = (360* Math.PI) / 180;
      setCurrentOpenAngle(snappedAngle);
      floorplannerStore.updateSymbolProperty(circleStair.id, "openAngleCs", snappedAngle);
    } else {
      setCurrentOpenAngle(angle); // Otherwise, just update the current angle
    }
  
    // Immediately trigger a re-render after updating the angle
    invalidate();
  };
  
  
  const onPointerUpHandle = () => {
    // Remove event listeners and update the store
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };
  
  
  const onPointerDownHandle = (event: React.PointerEvent) => {
    event.stopPropagation();
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    
    // Calculate the drag offset from the current angle
    dragOffset.current = {
      x: worldX - (circleStairsWidth * 0.75 * Math.cos(currentOpenAngle) * flipX + circleStair.position.x),
      y: worldY - (circleStairsWidth * 0.75 * Math.sin(currentOpenAngle) * flipY + circleStair.position.y),
    };
  
    // Start dragging event listeners
    gl.domElement.addEventListener("pointermove", onHandleDrag);
    gl.domElement.addEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(true);
  };
  

  const handleDrag = (newPosition: [number, number]) => {
    onDrag(newPosition);
  };

  const generateArcPointsAndLines = () => {
    const arcPoints = [];
    const extraLines = [];
    const maxDistance = Math.max(stairStepSize, 0.01)
    let previousPoint = null;
    let angle = 0;
    let lastLinePoint = null; // Track the position of the last line added
    let maxIterations = 10000; // Maximum number of iterations to prevent infinite loop

    while (angle <= currentOpenAngle && maxIterations > 0) {
      maxIterations--;
      // Calculate the current point based on the angle
      const currentPoint = new THREE.Vector3(
        circleStairsWidth * Math.cos(angle) * flipX,
        circleStairsWidth * Math.sin(angle) * flipY,
        0
      );

      // If this isn't the first point, check the distance from the previous point
      if (previousPoint) {
        const distance = currentPoint.distanceTo(previousPoint);

        // If the distance is greater than the maxDistance, reduce the angle step
        if (distance > maxDistance) {
          // Reduce the angle increment to place points closer together
          angle -= (distance - maxDistance) / circleStairsWidth;
          continue; // Recalculate the current point with the reduced angle
        }
      }

      // Add the current point to the arc points array
      arcPoints.push(currentPoint);

      // Add a line if the distance from the last line point is greater than maxDistance
      if (!lastLinePoint || currentPoint.distanceTo(lastLinePoint) > maxDistance) {
        extraLines.push({
          x: currentPoint.x,
          y: currentPoint.y,
          rotation: angle,
        });
        lastLinePoint = currentPoint.clone(); // Update the last line point
      }

      // Update the previous point and increase the angle by a default small step
      previousPoint = currentPoint;
      angle += 0.01; // Small default increment to move along the arc
    }

    return { arcPoints, extraLines };
  };

  const { arcPoints, extraLines } = generateArcPointsAndLines();

  useEffect(() => {
    // Sync the currentOpenAngle with the circleStair's openAngle when it changes from outside
    if (circleStair.openAngle !== currentOpenAngle) {
      setCurrentOpenAngle(circleStair.openAngle);
    }
  }, [circleStair.openAngle]);
  
  useEffect(() => {
    setCircleStairsWidth(circleStair.circleStairsWidth);
  }, [circleStair.circleStairsWidth]);

  return (
    <DraggableObject
      position={[circleStair.position.x, circleStair.position.y]}
      onDragStart={(offset) => onDragStart(circleStair, offset)}
      onDragEnd={(endPosition) => onDragEnd(endPosition)}
      onDrag={handleDrag}
      selectable={true}
      attachmentId={circleStair.id}
      attachmentType="doorAttachments"
      zIndex={circleStair.zIndex}
    >
      
        <SelectableSymbol
          ref={groupRef}
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResize={(newWidth, newHeight, newDepth) => {
            floorplannerStore.updateSymbolProperty(circleStair.id, "circleStairsWidth", newWidth);
          }}
          center={centerPosition}
          rotation={circleStair.rotation}
          symbol={circleStair}
        >
          <group
            ref={groupRef}
            rotation={[0, 0, circleStair.rotation || 0]}
          >
            {/* Render the arc */}
            {/* <mesh>
              <tubeGeometry args={[arcCurve, 64, lineWeight / 2, 8, false]} />
              <meshBasicMaterial color="lightgrey" />
            </mesh> */}
          <Line
            points={arcPoints.map((point) => [point.x, point.y, point.z])}
            color={lineColor}
            lineWidth={lineWeight}
          />

            {/* Render the extra lines */}
            {extraLines.map((line, index) => (
              // <mesh
              //   key={index}
              //   position={[line.x / 2, line.y / 2, 0]}
              //   rotation={[0, 0, line.rotation]}
              // >
              //   <boxGeometry args={[circleStairsWidth, lineWeight/100, lineWeight/100]} />
              //   <meshBasicMaterial color="black" />
              // </mesh>
              <Line
                key={index}
                points={[
                  [0, 0, 0],
                  [line.x, line.y, 0],
                ]}
                color={lineColor}
                lineWidth={lineWeight}
              />
            ))}

            {/* Render line1 */}
            {/* <mesh position={[circleStairsWidth / 2 * flipX, 0, 0]} rotation={[0, 0, 0]}>
              <boxGeometry args={[circleStairsWidth, lineWeight, lineWeight]} />
              <meshBasicMaterial color="black" />
            </mesh> */}
            {/* <Line
              points={[
                [circleStairsWidth / 2 * flipX, 0, 0],
                [circleStairsWidth * Math.cos(currentOpenAngle) / 2 * flipX, circleStairsWidth * Math.sin(currentOpenAngle) / 2 * flipY, 0]
              ]}
              color={lineColor}
            lineWidth={lineWeight}
            /> */}

            {/* Render line2 */}
            {/* <mesh position={[circleStairsWidth * Math.cos(currentOpenAngle) / 2 * flipX, circleStairsWidth * Math.sin(currentOpenAngle) / 2 * flipY, 0]} rotation={[0, 0, currentOpenAngle * flipX * flipY]}>
              <boxGeometry args={[circleStairsWidth, lineWeight, lineWeight]} />
              <meshBasicMaterial color="black" />
            </mesh> */}
            <Line
              points={[
                [0, 0, 0],
                [circleStairsWidth * Math.cos(currentOpenAngle) * flipX, circleStairsWidth * Math.sin(currentOpenAngle) * flipY, 0]
              ]}
              color={lineColor}
            lineWidth={lineWeight}
            />

            {/* Handle for adjusting open angle */}
            {circleStair.selected && (
               <group
               onPointerDown={onPointerDownHandle as unknown as (event: THREE.Event) => void}
               onPointerUp={onPointerUpHandle as unknown as (event: THREE.Event) => void}
               onPointerEnter={(event) => {
                event.stopPropagation();
                document.body.classList.add("cursor-arc");
                setHoveredHandle("circleStairHandle")
              }}
              onPointerLeave={(event) => {
                event.stopPropagation();
                document.body.classList.remove("cursor-arc"); 
                setHoveredHandle(null)
              }}
               position={[
                 openAngleGrabHandleX,
                 openAngleGrabHandleY,
                 0,
               ]}
             >
               <mesh>
                 <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                 <meshBasicMaterial color="blue" />
               </mesh>
               <mesh
                 position={[0, 0, handleDepth / 2]}
                 rotation={[Math.PI / 2, 0, 0]}
               >
                 <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                 <meshBasicMaterial color={isHovered("circleStairHandle") ? "blue" : "white"}/>
               </mesh>
             </group>
            )}
          </group>
        </SelectableSymbol>
      
    </DraggableObject>
  );
};

export default CircleStairs;
