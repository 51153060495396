import { WallConnectionEnd, WallConnectionStart, WallType } from "../../types/wallTypes";
import * as THREE from 'three';
import { removeWallConnection } from "./removeWallConnection";
import { snapToOtherWalls } from "./snapToOtherWalls";

/**
 * Drag the whole wall handle, it will move the whole wall releasing any connections
 * @param wall The wall to drag
 * @param startOffsetX The start x offset of the wall
 * @param startOffsetY The start y offset of the wall
 * @param endOffsetX The end x offset of the wall
 * @param endOffsetY The end y offset of the wall
 * @param newX The new x position of the wall
 * @param newY The new y position of the wall
 * @param tempWalls The temporary walls object
 * @param delta The delta vector
 * @returns boolean If the wall was snapped
 */
export const dragWallHandle = (
    wall: WallType,
    startOffsetX: number,
    startOffsetY: number,
    endOffsetX: number,
    endOffsetY: number,
    newX: number,
    newY: number,
    tempWalls: { [key: string]: WallType },
    delta: THREE.Vector2,
): boolean => {
    let snapped = false;

    const deltaStart = new THREE.Vector2(
        newX - startOffsetX,
        newY - startOffsetY,
    ).sub(wall.start);
    const newStart = wall.start.clone().add(deltaStart);
    const deltaEnd = new THREE.Vector2(
        newX - endOffsetX,
        newY - endOffsetY,
    ).sub(wall.end);
    const newEnd = wall.end.clone().add(deltaEnd);
    let newControlPoint;
    if (wall.controlPoint) {
        newControlPoint = new THREE.Vector2(
            wall.controlPoint.x + deltaStart.x,
            wall.controlPoint.y + deltaStart.y,
        );
        wall.controlPoint = newControlPoint;
    }

    delta.x = deltaStart.x;
    delta.y = deltaStart.y;

    removeWallConnection(tempWalls, wall, 0, true);

    const startSnapped = snapToOtherWalls(tempWalls, wall, WallConnectionStart, newStart, true);
    const endSnapped = snapToOtherWalls(tempWalls, wall, WallConnectionEnd, newEnd, true);
    if (!startSnapped && !endSnapped) {
        tempWalls[wall.id].end.copy(newEnd);
        tempWalls[wall.id].start.copy(newStart);
    }
    return snapped;
}
