import React, { useRef, useState, useEffect, useCallback } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { RectStairsType } from "../../types/wallTypes";
import { SelectableSymbol, BoundingBox } from "./SelectableSymbol";
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Line } from "@react-three/drei";

interface RectangleStairsProps {
  rectStair: RectStairsType;
  onDragStart: (rectStair: RectStairsType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const RectStairs: React.FC<RectangleStairsProps> = ({
  rectStair,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);

  const lineWeight = rectStair.lineWeight || floorplannerStore.symbolLineWeight;
  const [rectStairsWidth, setRectStairsWidth] = useState(rectStair.rectStairsWidth);
  const [rectStairsHeight, setRectStairsHeight] = useState(rectStair.rectStairsHeight);

  const grabHandleSize = 0.1; // Variable to control the size of the grab handle
  const flipX = rectStair.flipHorizontal ? -1 : 1; // Flip horizontally
  const flipY = rectStair.flipVertical ? -1 : 1; // Flip vertically
  const [centerPosition, setCenterPosition] = useState<[number, number]>([
    rectStairsWidth / 2,
    rectStairsHeight / 2,
  ]);
  const initialHeight = useRef(rectStair.rectStairsHeight); // Store the initial height
  const lineColor = rectStair.lineColor || floorplannerStore.lineColor;
  // State to store the pivot dynamically
  const [pivot, setPivot] = useState<[number, number]>([
    -rectStairsWidth / 2,
    -rectStairsHeight / 2,
  ]);
  const scene = useThree((state) => state.scene);
  const [isHovered, setIsHovered] = useState(false);
  const stairStepSize = rectStair.stairStepSize || floorplannerStore.stairStepSize;

  // Calculate the bounding box of the stairs
  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) {
      return {
        topLeft: [0, 0],
        topRight: [0, 0],
        bottomLeft: [0, 0],
        bottomRight: [0, 0],
        width: 0,
        height: 0,
        depth: 0,
      };
    }

    // Clone the object
    const tempGroup = groupRef.current.clone();

    // Save the original rotation
    const originalRotation = tempGroup.rotation.clone();

    // Temporarily reset the rotation to 0
    tempGroup.rotation.set(0, 0, 0);

    // Calculate the bounding box as if the object had no rotation
    const boundingBox = new THREE.Box3().setFromObject(tempGroup);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    // Restore the original rotation
    tempGroup.rotation.copy(originalRotation);

    // Calculate the corners of the bounding box
    const topLeft = new THREE.Vector3(
      0,
      size.y + (lineWeight / 100),
      0
    );
    const topRight = new THREE.Vector3(
      size.x + (lineWeight / 100),
      size.y + (lineWeight / 100),
      0
    );
    const bottomLeft = new THREE.Vector3(
      0,
      0,
      0
    );
    const bottomRight = new THREE.Vector3(
      size.x + (lineWeight / 100),
      0,
      0
    );


    return {
      topLeft: [topLeft.x, topLeft.y],
      topRight: [topRight.x, topRight.y],
      bottomLeft: [bottomLeft.x, bottomLeft.y],
      bottomRight: [bottomRight.x, bottomRight.y],
      width: boundingBox.max.x - boundingBox.min.x,
      height: boundingBox.max.y - boundingBox.min.y,
      depth: 0,
    };
  }, [rectStair.position.x, rectStair.position.y, rectStair.flipHorizontal, rectStair.flipVertical, rectStairsWidth, rectStairsHeight]);

  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = (event: PointerEvent) => {
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dy = worldY - rectStair.position.y - dragOffset.current.y;

    // Update the handle's Y position
    setCenterPosition([rectStair.position.x, rectStair.position.y + dy]);
  };

  const onPointerUpHandle = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };

  const handleDrag = (newPosition: [number, number]) => {
    onDrag(newPosition);
  };

  const generateRectPointsAndLines = () => {
    const rectPoints: THREE.Vector3[] = [];
    const extraLines: THREE.Vector3[][] = [];
    const maxDistance = Math.max(stairStepSize, 0.01); // Minimum distance between horizontal lines
    let maxIterations = 1000; // Maximum number of iterations to prevent infinite loop

    // Define the corners of the rectangle
    const topLeft = new THREE.Vector3(-rectStairsWidth / 2, rectStairsHeight / 2, 0);
    const topRight = new THREE.Vector3(rectStairsWidth / 2, rectStairsHeight / 2, 0);
    const bottomRight = new THREE.Vector3(rectStairsWidth / 2, -rectStairsHeight / 2, 0);
    const bottomLeft = new THREE.Vector3(-rectStairsWidth / 2, -rectStairsHeight / 2, 0);

    rectPoints.push(topLeft, topRight, bottomRight, bottomLeft, topLeft); // Close the loop

    // Add horizontal lines at fixed vertical increments
    let currentY = topLeft.y - maxDistance; // Start below the top
    while (currentY > bottomLeft.y && maxIterations > 0) { // Stop before reaching the bottom
      maxIterations--; // Decrement the counter
      extraLines.push([
        new THREE.Vector3(topLeft.x, currentY, 0), // Start of the horizontal line
        new THREE.Vector3(topRight.x, currentY, 0), // End of the horizontal line
      ]);
      currentY -= maxDistance; // Move down by maxDistance
    }

    return { rectPoints, extraLines };
  };

  // Call the function and destructure the returned values
  const { rectPoints, extraLines } = generateRectPointsAndLines();

  // Handle Drag Right (Green Line)
  const dragOffsetRight = useRef({ x: 0, y: 0 });

  const onHandleDragRight = (event: PointerEvent) => {
    const [worldX] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dx = worldX - rectStair.position.x - dragOffsetRight.current.x;
    setRectStairsWidth(Math.max(0.1, rectStairsWidth + dx));
    setPivot([-rectStairsWidth / 2, -rectStairsHeight / 2]);
  };

  const onPointerDownHandleRight = (event: React.PointerEvent) => {
    event.stopPropagation();
    const [worldX] = projectToWorld(event.clientX, event.clientY, gl, camera);
    dragOffsetRight.current = {
      x: worldX - (rectStairsWidth * flipX + rectStair.position.x),
      y: 0,
    };
    gl.domElement.addEventListener("pointermove", onHandleDragRight);
    gl.domElement.addEventListener("pointerup", onPointerUpHandleRight);
    floorplannerStore.setBlockDirty(true);
  };

  const onPointerUpHandleRight = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDragRight);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandleRight);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.updateSymbolProperty(rectStair.id, "rectStairsWidth", rectStairsWidth);
  };

  // Handle Drag Top (Red Line)
  const dragOffsetTop = useRef({ x: 0, y: 0 });

  const onHandleDragTop = (event: PointerEvent) => {
    const [, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dy = worldY - rectStair.position.y - dragOffsetTop.current.y;
    setRectStairsHeight(Math.max(0.1, rectStairsHeight + dy * flipY));
    setPivot([-rectStairsWidth / 2, -rectStairsHeight / 2]);
  };

  const onPointerDownHandleTop = (event: React.PointerEvent) => {
    event.stopPropagation();
    const [, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    dragOffsetTop.current = {
      x: 0,
      y: worldY - (rectStairsHeight * flipY + rectStair.position.y),
    };
    gl.domElement.addEventListener("pointermove", onHandleDragTop);
    gl.domElement.addEventListener("pointerup", onPointerUpHandleTop);
    floorplannerStore.setBlockDirty(true);
  };

  const onPointerUpHandleTop = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDragTop);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandleTop);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.updateSymbolProperty(rectStair.id, "rectStairsHeight", rectStairsHeight);
  };

  return (
    <DraggableObject
      position={[rectStair.position.x, rectStair.position.y]}
      onDragStart={(offset) => onDragStart(rectStair, offset)}
      onDragEnd={(endPosition) => onDragEnd(endPosition)}
      onDrag={handleDrag}
      selectable={true}
      attachmentId={rectStair.id}
      attachmentType="doorAttachments"
      zIndex={rectStair.zIndex}
      symbol={rectStair}
    >
      <SelectableSymbol
        ref={groupRef}
        handleSize={floorplannerStore.symbolHandleSize}
        calculateBoundingBox={calculateBoundingBox}
        onResize={(newWidth, newHeight, newDepth, handle) => {
          const widthDiff = newWidth - (floorplannerStore.symbols[rectStair.id] as RectStairsType).rectStairsWidth;
          const heightDiff = newHeight - (floorplannerStore.symbols[rectStair.id] as RectStairsType).rectStairsHeight;
          setRectStairsWidth(newWidth);
          setRectStairsHeight(newHeight);
          floorplannerStore.updateSymbolProperty(rectStair.id, "rectStairsWidth", newWidth);
          floorplannerStore.updateSymbolProperty(rectStair.id, "rectStairsHeight", newHeight);
          if (handle === "topLeft") {
            // Lock bottomRight position and adjust topLeft
            rectStair.position.x -= widthDiff / 2;
            rectStair.position.y += heightDiff / 2;
          } else if (handle === "topRight") {
            // Lock bottomLeft position and adjust topRight
            rectStair.position.x += widthDiff / 2;
            rectStair.position.y += heightDiff / 2;
          } else if (handle === "bottomLeft") {
            // Lock topRight position and adjust bottomLeft
            rectStair.position.x -= widthDiff / 2;
            rectStair.position.y -= heightDiff / 2;
          } else if (handle === "bottomRight") {
            // Lock topLeft position and adjust bottomRight
            rectStair.position.x += widthDiff / 2;
            rectStair.position.y -= heightDiff / 2;
          }

          setPivot([-newWidth / 2, -newHeight / 2]);
        }}
        center={centerPosition}
        rotation={rectStair.rotation}
        symbol={rectStair}
        drawHandles={true}
        pivot={pivot}
        onPointerOver={() => setIsHovered(true)}
        onPointerOut={() => setIsHovered(false)}
      >
        <group ref={groupRef} rotation={[0, 0, rectStair.rotation || 0]}>
          {/* Render top horizontal line */}
          <Line
            points={[
              [-rectStairsWidth / 2 * flipX, rectStairsHeight / 2, 0],
              [rectStairsWidth / 2, rectStairsHeight / 2, 0]
            ]}
            color={isHovered ? 'blue' : lineColor} 
            lineWidth={lineWeight}
          />

          {/* Render bottom horizontal line */}
          <Line
            points={[
              [-rectStairsWidth / 2, -rectStairsHeight / 2, 0],
              [rectStairsWidth / 2, -rectStairsHeight / 2, 0]
            ]
            }
            color={isHovered ? 'blue' : lineColor} 
            lineWidth={lineWeight}
          />

          {/* Render left vertical line */}
          <Line
            points={[
              [-rectStairsWidth / 2, rectStairsHeight / 2, 0],
              [-rectStairsWidth / 2, -rectStairsHeight / 2, 0]
            ]
            }
            color={isHovered ? 'blue' : lineColor} 
            lineWidth={lineWeight}
          />

          {/* Render right vertical line */}
          <Line
            points={[
              [rectStairsWidth / 2, rectStairsHeight / 2, 0],
              [rectStairsWidth / 2, -rectStairsHeight / 2, 0]
            ]
            }
            color={isHovered ? 'blue' : lineColor} 
            lineWidth={lineWeight}
          />

          {extraLines.map((line, index) => (
            <Line
              key={index}
              points={[line[0].toArray(), line[1].toArray()]} // Horizontal line from left to right
              color={isHovered ? 'blue' : lineColor} 
              lineWidth={lineWeight}
            />
          ))}

          {/* {rectStair.selected && (
            <group
              onPointerDown={onPointerDownHandleRight as unknown as (event: THREE.Event) => void}
              onPointerUp={onPointerUpHandleRight as unknown as (event: THREE.Event) => void}
              position={[rectStairsWidth / 2 * flipX, 0 * flipY, 0]}
            >
              <mesh>
                <boxGeometry args={[2 * grabHandleSize, 2 * grabHandleSize, 0.01]} />
                <meshStandardMaterial color="gray" opacity={0.5} transparent />
              </mesh>
            </group>
          )}

          {rectStair.selected && (
            <group
              onPointerDown={onPointerDownHandleTop as unknown as (event: THREE.Event) => void}
              onPointerUp={onPointerUpHandleTop as unknown as (event: THREE.Event) => void}
              position={[0 * flipX, rectStairsHeight / 2 * flipY, 0]}
            >
              <mesh>
                <boxGeometry args={[2 * grabHandleSize, 2 * grabHandleSize, 0.01]} />
                <meshStandardMaterial color="gray" opacity={0.5} transparent />
              </mesh>
            </group>
          )} */}
        </group>
      </SelectableSymbol>
    </DraggableObject>
  );
};

export default RectStairs;
