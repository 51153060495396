import { WallType } from "../../types/wallTypes";
import * as THREE from 'three';

/**
 * Drag control handle of ARC wall, it is the control point of the bezier curve
 * @param wall The wall to drag control handle
 * @param newX The new x position of the control handle
 * @param newY The new y position of the control handle
 * @param tempWalls The temporary walls object
 * @returns void
 */
export const dragControlHandle = (
    wall: WallType,
    newX: number,
    newY: number,
    tempWalls: { [key: string]: WallType },
) => {
    if (!wall.controlPoint) {
        wall.controlPoint = new THREE.Vector2(
            wall.start.x + (wall.start.x + wall.end.x) / 2,
            wall.start.y + (wall.start.y + wall.end.y) / 2,
        );
    }
    const curPoint = new THREE.Vector2(
        newX,
        newY
    );
    // Snap newX and newY to the wall's middle point (along the perpendicular direction from the middle point)
    const midPoint = new THREE.Vector2(
        (wall.start.x + wall.end.x) / 2,
        (wall.start.y + wall.end.y) / 2,
    );
    const wallDirection = new THREE.Vector2()
        .subVectors(wall.end, wall.start)
        .normalize();
    const perpendicular = new THREE.Vector2(
        -wallDirection.y,
        wallDirection.x,
    );
    const newDistance = new THREE.Vector2(
        newX - midPoint.x,
        newY - midPoint.y,
    ).dot(perpendicular);
    const snapPoint = midPoint.clone().add(perpendicular.clone().multiplyScalar(newDistance));
    // If the snapPoint is < 0.1mm away from the current point, snap to it
    if (snapPoint.distanceTo(curPoint) < 0.5) {
        newX = snapPoint.x;
        newY = snapPoint.y;
    }

    const deltaControl = new THREE.Vector2(
        newX,
        newY,
    ).sub(wall.controlPoint as THREE.Vector2);
    const newControl = (wall.controlPoint as THREE.Vector2).clone().add(deltaControl);
    tempWalls[wall.id].controlPoint = newControl;
}