import { useThree } from "@react-three/fiber";
import {
  WallConnectionEnd,
  WallConnectionStart,
  WallShapeType,
  WallType,
} from "../../types/wallTypes";
import { checkLineIntersection } from "./checkLineIntersections";
import * as THREE from "three";
import path from "path";
import { get } from 'http';

const getCornerOfShape = (
  shape: WallShapeType,
  position: "topLeft" | "topRight" | "bottomLeft" | "bottomRight",
): {
    position: THREE.Vector2;
    index: number;
  } => {
  if (shape.wall.lineForm === "arc") {
    const pathsCount = shape.paths.length;
    if (position === "topLeft") {
      return {
        position: shape.paths[0],
        index: 0,
      };
    } else if (position === "topRight") {
      return {
        position: shape.paths[pathsCount - 1],
        index: pathsCount - 1,
      };
    } else if (position === "bottomRight") {
      return {
        position: shape.paths[pathsCount / 2],
        index: pathsCount / 2,
      };
    } else {
      return {
        position: shape.paths[pathsCount / 2 - 1],
        index: pathsCount / 2 - 1,
      };
    }
  } else {
    if (position === "topLeft") {
      return {
        position: shape.paths[0],
        index: 0,
      };
    } else if (position === "topRight") {
      return {
        position: shape.paths[1],
        index: 1,
      };
    } else if (position === "bottomLeft") {
      return {
        position: shape.paths[2],
        index: 2,
      };
    } else {
      return {
        position: shape.paths[3],
        index: 3,
      };
    }
  }
}

/**
 * Trim intersecting lines by cutting the paths of the wall shapes at the intersections
 * @param wallShapes
 * @param otherShapes
 * @returns
 */
export const trimIntersectingLines = (
  wallShapes: WallShapeType[],
  otherShapes: WallShapeType[] | undefined,
  walls: { [key: string]: WallType },
  scene: THREE.Scene,
) => {
  for (let i = 0; i < wallShapes.length; i++) {
    const wall = wallShapes[i].wall;
    const startConnection = wall.connections?.find(
      (c) => c.sourcePosition === WallConnectionStart,
    );
    const startConnectedTo = wallShapes.find(
      (w) => w.wall.id === startConnection?.id,
    );
    const endConnection = wall.connections?.find(
      (c) => c.sourcePosition === WallConnectionEnd,
    );
    const endConnectedTo = wallShapes.find(
      (w) => w.wall.id === endConnection?.id,
    );
    if (startConnectedTo) {
      if (startConnection?.targetPosition === WallConnectionEnd) {
        // End to end connections, we need to extend the intersection checking for hit on sharp corners (restrictToSegment = false)
        const intersection = checkLineIntersection(
          getCornerOfShape(wallShapes[i], "topLeft").position, //wallShapes[i].paths[0],
          getCornerOfShape(wallShapes[i], "bottomLeft").position, //wallShapes[i].paths[3],
          getCornerOfShape(startConnectedTo, "topLeft").position, //startConnectedTo.paths[0],
          getCornerOfShape(startConnectedTo, "bottomLeft").position, //startConnectedTo.paths[3],
          false,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[getCornerOfShape(wallShapes[i], "topLeft").index] = intersection.point as THREE.Vector2;
          startConnectedTo.paths[getCornerOfShape(startConnectedTo, "bottomLeft").index] = intersection.point as THREE.Vector2;
        }
      //} else if (startConnection?.targetPosition === WallConnectionStart) {
        //MESSY CODE
        // // End to start connections, we need to extend the intersection checking for hit on sharp corners (restrictToSegment = false)
        // const intersection = checkLineIntersection(
        //   wallShapes[i].paths[1],
        //   wallShapes[i].paths[2],
        //   startConnectedTo.paths[0],
        //   startConnectedTo.paths[3],
        //   false,
        // );
        // if (intersection.intersecting) {
        //   console.log('Intersection detected 1', startConnection.sourcePosition);
        //   // Debug draw a dot at the four endpoints being checked for intersection
        //   const geometry1 = new THREE.CircleGeometry(0.01, 32);
        //   const material1 = new THREE.MeshBasicMaterial({ color: 0x0000ff });
        //   const circle1 = new THREE.Mesh(geometry1, material1);
        //   circle1.position.set(wallShapes[i].paths[1].x, wallShapes[i].paths[1].y, 0.01);
        //   scene.add(circle1);
        //   const geometry2 = new THREE.CircleGeometry(0.01, 32);
        //   const material2 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        //   const circle2 = new THREE.Mesh(geometry2, material2);
        //   circle2.position.set(wallShapes[i].paths[2].x, wallShapes[i].paths[2].y, 0.01);
        //   scene.add(circle2);
        //   const geometry3 = new THREE.CircleGeometry(0.01, 32);
        //   const material3 = new THREE.MeshBasicMaterial({ color: 0xff00ff });
        //   const circle3 = new THREE.Mesh(geometry3, material3);
        //   circle3.position.set(startConnectedTo.paths[0].x, startConnectedTo.paths[0].y, 0.01);
        //   scene.add(circle3);
        //   const geometry4 = new THREE.CircleGeometry(0.01, 32);
        //   const material4 = new THREE.MeshBasicMaterial({ color: 0xffff00 });
        //   const circle4 = new THREE.Mesh(geometry4, material4);
        //   circle4.position.set(startConnectedTo.paths[3].x, startConnectedTo.paths[3].y, 0.01);
        //   scene.add(circle4);

        //   // Debug draw the intersection point
        //   const geometry5 = new THREE.CircleGeometry(0.01, 32);
        //   const material5 = new THREE.MeshBasicMaterial({ color: 0x000000 });
        //   const circle5 = new THREE.Mesh(geometry5, material5);
        //   circle5.position.set(intersection.point?.x || 0, intersection.point?.y || 0, 0.01);
        //   scene.add(circle5);

        //   wallShapes[i].paths[1] = intersection.point as THREE.Vector2;
        //   startConnectedTo.paths[0] = intersection.point as THREE.Vector2;
        // }
      } else {
        // End to middle connections, we only need to check the intersections of the connecting wall edges
        let intersection = checkLineIntersection(
          getCornerOfShape(wallShapes[i], "topLeft").position, //wallShapes[i].paths[0],
          getCornerOfShape(wallShapes[i], "bottomLeft").position, //wallShapes[i].paths[3],
          startConnectedTo.paths[getCornerOfShape(startConnectedTo, "topLeft").index],
          startConnectedTo.paths[getCornerOfShape(startConnectedTo, "bottomLeft").index],
          true,
        );
        let clip = false;
        if (intersection.intersecting) {
          wallShapes[i].paths[getCornerOfShape(wallShapes[i], "topLeft").index] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          getCornerOfShape(wallShapes[i], "topRight").position, //wallShapes[i].paths[1],
          getCornerOfShape(wallShapes[i], "bottomRight").position, //wallShapes[i].paths[2],
          getCornerOfShape(startConnectedTo, "topLeft").position, //startConnectedTo.paths[0],
          getCornerOfShape(startConnectedTo, "bottomLeft").position, //startConnectedTo.paths[3],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[getCornerOfShape(wallShapes[i], "topRight").index] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          getCornerOfShape(wallShapes[i], "topLeft").position, //wallShapes[i].paths[0],
          getCornerOfShape(wallShapes[i], "bottomLeft").position, //wallShapes[i].paths[3],
          getCornerOfShape(startConnectedTo, "topRight").position, //startConnectedTo.paths[1],
          getCornerOfShape(startConnectedTo, "bottomRight").position, //startConnectedTo.paths[2],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[getCornerOfShape(wallShapes[i], "topLeft").index] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          getCornerOfShape(wallShapes[i], "topRight").position, //wallShapes[i].paths[1],
          getCornerOfShape(wallShapes[i], "bottomRight").position, //wallShapes[i].paths[2],
          getCornerOfShape(startConnectedTo, "topRight").position, //startConnectedTo.paths[1],
          getCornerOfShape(startConnectedTo, "bottomRight").position, //startConnectedTo.paths[2],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[getCornerOfShape(wallShapes[i], "topRight").index] = intersection.point as THREE.Vector2;
          clip = true;
        }
        // We may also get intersections with other lines/side of the connecting wall
        if (otherShapes) {
          for (let j = 0; j < otherShapes.length; j++) {
            const otherWall = otherShapes[j].wall;
            if (otherWall.id === startConnectedTo.wall.id) {
              let intersection = checkLineIntersection(
                getCornerOfShape(wallShapes[i], "topLeft").position, //wallShapes[i].paths[0],
                getCornerOfShape(wallShapes[i], "bottomLeft").position, //wallShapes[i].paths[3],
                getCornerOfShape(otherShapes[j], "topLeft").position, //otherShapes[j].paths[0],
                getCornerOfShape(otherShapes[j], "bottomLeft").position, //otherShapes[j].paths[3],
                startConnection?.targetPosition === WallConnectionStart ? false : true,
              );
              if (intersection.intersecting) {
                if (startConnection?.targetPosition !== WallConnectionStart) {
                  wallShapes[i].paths[getCornerOfShape(wallShapes[i], "topLeft").index] = intersection.point as THREE.Vector2;
                }
                clip = true;
              }
              intersection = checkLineIntersection(
                getCornerOfShape(wallShapes[i], "topRight").position, //wallShapes[i].paths[1],
                getCornerOfShape(wallShapes[i], "bottomRight").position, //wallShapes[i].paths[2],
                getCornerOfShape(otherShapes[j], "topLeft").position, //otherShapes[j].paths[0],
                getCornerOfShape(otherShapes[j], "bottomLeft").position, //otherShapes[j].paths[3],
                startConnection?.targetPosition === WallConnectionStart ? false : true,
              );
              if (intersection.intersecting) {
                wallShapes[i].paths[getCornerOfShape(wallShapes[i], "topRight").index] = intersection.point as THREE.Vector2;
                if (startConnection?.targetPosition === WallConnectionStart) {
                  otherShapes[j].paths[getCornerOfShape(otherShapes[j], "topLeft").index] = intersection.point as THREE.Vector2;
                }
                clip = true;
              }
            }
          }
        }
        if (clip) {
          const startConnectedToWall = walls[startConnectedTo.wall.id];
          if (startConnectedToWall) {
            const clippingPolygon = startConnectedToWall.clippingPolygons?.find(
              (p) => p.belongsTo === wall.id,
            );
            if (clippingPolygon) {
              clippingPolygon.polygon = [
                new THREE.Vector2(
                  wallShapes[i].paths[0].x,
                  wallShapes[i].paths[0].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[1].x,
                  wallShapes[i].paths[1].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[2].x,
                  wallShapes[i].paths[2].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[3].x,
                  wallShapes[i].paths[3].y,
                ),
              ];
            } else {
              if (!startConnectedToWall.clippingPolygons) {
                startConnectedToWall.clippingPolygons = [];
              }
              startConnectedToWall.clippingPolygons.push({
                belongsTo: wall.id,
                polygon: [
                  new THREE.Vector2(
                    wallShapes[i].paths[0].x,
                    wallShapes[i].paths[0].y,
                  ),
                  new THREE.Vector2(
                    wallShapes[i].paths[1].x,
                    wallShapes[i].paths[1].y,
                  ),
                  new THREE.Vector2(
                    wallShapes[i].paths[2].x,
                    wallShapes[i].paths[2].y,
                  ),
                  new THREE.Vector2(
                    wallShapes[i].paths[3].x,
                    wallShapes[i].paths[3].y,
                  ),
                ],
                objectType: "wall",
              });
            }
          }
        }
      }
    }
    if (endConnectedTo) {
      if (endConnection?.targetPosition === WallConnectionStart) {
        // End to end connections, we need to extend the intersection checking for hit on sharp corners (restrictToSegment = false)
        const intersection = checkLineIntersection(
          getCornerOfShape(wallShapes[i], "topRight").position, //wallShapes[i].paths[1],
          getCornerOfShape(wallShapes[i], "bottomRight").position, //wallShapes[i].paths[2],
          getCornerOfShape(endConnectedTo, "topRight").position, //endConnectedTo.paths[1],
          getCornerOfShape(endConnectedTo, "bottomRight").position, //endConnectedTo.paths[2],
          false,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[getCornerOfShape(wallShapes[i], "bottomRight").index] = intersection.point as THREE.Vector2;
          endConnectedTo.paths[getCornerOfShape(endConnectedTo, "topRight").index] = intersection.point as THREE.Vector2;
        }
      //} else if (endConnection?.targetPosition === WallConnectionEnd) {
        // // End to start connections, we need to extend the intersection checking for hit on sharp corners (restrictToSegment = false)
        // const intersection = checkLineIntersection(
        //   wallShapes[i].paths[0],
        //   wallShapes[i].paths[3],
        //   endConnectedTo.paths[1],
        //   endConnectedTo.paths[2],
        //   false,
        // );
        // if (intersection.intersecting) {
        //   console.log('Intersection detected 2', endConnection.sourcePosition);
        //   // Debug draw a dot at the four endpoints being checked for intersection
        //   const geometry1 = new THREE.CircleGeometry(0.01, 32);
        //   const material1 = new THREE.MeshBasicMaterial({ color: 0x0000ff });
        //   const circle1 = new THREE.Mesh(geometry1, material1);
        //   circle1.position.set(wallShapes[i].paths[1].x, wallShapes[i].paths[1].y, 0.01);
        //   scene.add(circle1);
        //   const geometry2 = new THREE.CircleGeometry(0.01, 32);
        //   const material2 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        //   const circle2 = new THREE.Mesh(geometry2, material2);
        //   circle2.position.set(wallShapes[i].paths[2].x, wallShapes[i].paths[2].y, 0.01);
        //   scene.add(circle2);
        //   const geometry3 = new THREE.CircleGeometry(0.01, 32);
        //   const material3 = new THREE.MeshBasicMaterial({ color: 0xff00ff });
        //   const circle3 = new THREE.Mesh(geometry3, material3);
        //   circle3.position.set(endConnectedTo.paths[0].x, endConnectedTo.paths[0].y, 0.01);
        //   scene.add(circle3);
        //   const geometry4 = new THREE.CircleGeometry(0.01, 32);
        //   const material4 = new THREE.MeshBasicMaterial({ color: 0xffff00 });
        //   const circle4 = new THREE.Mesh(geometry4, material4);
        //   circle4.position.set(endConnectedTo.paths[3].x, endConnectedTo.paths[3].y, 0.01);
        //   scene.add(circle4);

        //   // Debug draw the intersection point
        //   const geometry5 = new THREE.CircleGeometry(0.01, 32);
        //   const material5 = new THREE.MeshBasicMaterial({ color: 0x000000 });
        //   const circle5 = new THREE.Mesh(geometry5, material5);
        //   circle5.position.set(intersection.point?.x || 0, intersection.point?.y || 0, 0.01);
        //   scene.add(circle5);


        //   wallShapes[i].paths[3] = intersection.point as THREE.Vector2;
        //   endConnectedTo.paths[2] = intersection.point as THREE.Vector2;
        // }
      } else {
        // End to middle connections, we only need to check the intersections of the connecting wall edges
        let intersection = checkLineIntersection(
          getCornerOfShape(wallShapes[i], "topRight").position, //wallShapes[i].paths[1],
          getCornerOfShape(wallShapes[i], "bottomRight").position, //wallShapes[i].paths[2],
          getCornerOfShape(endConnectedTo, "topLeft").position, //endConnectedTo.paths[0],
          getCornerOfShape(endConnectedTo, "bottomLeft").position, //endConnectedTo.paths[3],
          true,
        );
        let clip = false;
        if (intersection.intersecting) {
          wallShapes[i].paths[getCornerOfShape(wallShapes[i], "bottomRight").index] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          getCornerOfShape(wallShapes[i], "topLeft").position, //wallShapes[i].paths[0],
          getCornerOfShape(wallShapes[i], "bottomLeft").position, //wallShapes[i].paths[3],
          getCornerOfShape(endConnectedTo, "topLeft").position, //endConnectedTo.paths[0],
          getCornerOfShape(endConnectedTo, "bottomLeft").position, //endConnectedTo.paths[3],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[getCornerOfShape(wallShapes[i], "bottomLeft").index] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          getCornerOfShape(wallShapes[i], "topRight").position, //wallShapes[i].paths[1],
          getCornerOfShape(wallShapes[i], "bottomRight").position, //wallShapes[i].paths[2],
          getCornerOfShape(endConnectedTo, "topRight").position, //endConnectedTo.paths[1],
          getCornerOfShape(endConnectedTo, "bottomRight").position, //endConnectedTo.paths[2],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[getCornerOfShape(wallShapes[i], "bottomRight").index] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          getCornerOfShape(wallShapes[i], "topLeft").position, //wallShapes[i].paths[0],
          getCornerOfShape(wallShapes[i], "bottomLeft").position, //wallShapes[i].paths[3],
          getCornerOfShape(endConnectedTo, "topRight").position, //endConnectedTo.paths[1],
          getCornerOfShape(endConnectedTo, "bottomRight").position, //endConnectedTo.paths[2],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[getCornerOfShape(wallShapes[i], "bottomLeft").index] = intersection.point as THREE.Vector2;
          clip = true;
        }
        // We may also get intersections with other lines/side of the connecting wall
        if (otherShapes) {
          for (let j = 0; j < otherShapes.length; j++) {
            const otherWall = otherShapes[j].wall;
            if (otherWall.id === endConnectedTo.wall.id) {
              let intersection = checkLineIntersection(
                getCornerOfShape(wallShapes[i], "topRight").position, //wallShapes[i].paths[1],
                getCornerOfShape(wallShapes[i], "bottomRight").position, //wallShapes[i].paths[2],
                getCornerOfShape(otherShapes[j], "topRight").position, //otherShapes[j].paths[1],
                getCornerOfShape(otherShapes[j], "bottomRight").position, //otherShapes[j].paths[2],
                endConnection?.targetPosition === WallConnectionEnd ? false : true,
              );
              if (intersection.intersecting) {
                // This collision is confirmed and solved...
                if (endConnection?.targetPosition === WallConnectionEnd) {
                  // console.log('Intersection detected 3 - unconfirmed', intersection.point);
                  // otherShapes[j].paths[2] = intersection.point as THREE.Vector2;
                  // // Debug draw the intersection point
                  // const geometry5 = new THREE.CircleGeometry(0.01, 32);
                  // const material5 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
                  // const circle5 = new THREE.Mesh(geometry5, material5);
                  // circle5.position.set(otherShapes[j].paths[2].x || 0, otherShapes[j].paths[2].y || 0, 0.01);
                  // scene.add(circle5);
                } else {
                  wallShapes[i].paths[getCornerOfShape(wallShapes[i], "bottomRight").index] = intersection.point as THREE.Vector2;
                }
                clip = true;
              }
              intersection = checkLineIntersection(
                getCornerOfShape(wallShapes[i], "topLeft").position, //wallShapes[i].paths[0],
                getCornerOfShape(wallShapes[i], "bottomLeft").position, //wallShapes[i].paths[3],
                getCornerOfShape(otherShapes[j], "topRight").position, //otherShapes[j].paths[1],
                getCornerOfShape(otherShapes[j], "bottomRight").position, //otherShapes[j].paths[2],
                endConnection?.targetPosition === WallConnectionEnd ? false : true,
              );
              if (intersection.intersecting) {
                wallShapes[i].paths[getCornerOfShape(wallShapes[i], "bottomLeft").index] = intersection.point as THREE.Vector2;
                if (endConnection?.targetPosition === WallConnectionEnd) {
                  // Debug draw the intersection point
                  // const geometry5 = new THREE.CircleGeometry(0.01, 32);
                  // const material5 = new THREE.MeshBasicMaterial({ color: 0xff0000 });
                  // const circle5 = new THREE.Mesh(geometry5, material5);
                  // circle5.position.set(otherShapes[j].paths[2].x || 0, otherShapes[j].paths[2].y || 0, 0.01);
                  // scene.add(circle5);
                  //console.log('Intersection detected 4 confirmed', intersection.point);
                  otherShapes[j].paths[getCornerOfShape(otherShapes[j], "bottomRight").index] = intersection.point as THREE.Vector2;
                }
                clip = true;
              }
            }
          }
        }
        if (clip) {
          const endConnectedToWall = walls[endConnectedTo.wall.id];
          if (endConnectedToWall) {
            const clippingPolygon = endConnectedToWall.clippingPolygons?.find(
              (p) => p.belongsTo === wall.id,
            );
            if (clippingPolygon) {
              clippingPolygon.polygon = [
                new THREE.Vector2(
                  wallShapes[i].paths[0].x,
                  wallShapes[i].paths[0].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[1].x,
                  wallShapes[i].paths[1].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[2].x,
                  wallShapes[i].paths[2].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[3].x,
                  wallShapes[i].paths[3].y,
                ),
              ];
            } else {
              if (!endConnectedToWall.clippingPolygons) {
                endConnectedToWall.clippingPolygons = [];
              }
              endConnectedToWall.clippingPolygons.push({
                belongsTo: wall.id,
                polygon: [
                  new THREE.Vector2(
                    wallShapes[i].paths[0].x,
                    wallShapes[i].paths[0].y,
                  ),
                  new THREE.Vector2(
                    wallShapes[i].paths[1].x,
                    wallShapes[i].paths[1].y,
                  ),
                  new THREE.Vector2(
                    wallShapes[i].paths[2].x,
                    wallShapes[i].paths[2].y,
                  ),
                  new THREE.Vector2(
                    wallShapes[i].paths[3].x,
                    wallShapes[i].paths[3].y,
                  ),
                ],
                objectType: "wall",
              });
            }
          }
        }
      }
    }
  }
};
