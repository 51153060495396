import { WallShapeType } from "../../types/wallTypes";
import * as THREE from "three";
import { createFirstWallSegment } from "./createFirstWallSegment";
import { createFullWallShape } from "./createFullWallShape";
import { createLastWallSegment } from "./createLastWallSegment";
import { createWallSegment } from "./createWallSegment";

export const createShapes = (
  wallShape: WallShapeType,
  shapeType: string,
  lineWeight: number,
  wallWidth: number,
): THREE.Shape[] => {
  const shapes: THREE.Shape[] = [];
  const wall = wallShape.wall;
  const wallLength = wall.start.distanceTo(wall.end);
  const wallDirection = new THREE.Vector2()
    .subVectors(wall.end, wall.start)
    .normalize();
  const perpendicular = new THREE.Vector2(-wallDirection.y, wallDirection.x);

  if (shapeType === "endCaps") {
    if (wallShape.paths.length > 4) {
      // Split end caps into separate shapes
      const startEndCap = createFullWallShape(wallShape.paths.slice(0, 4));
      shapes.push(startEndCap);
      const endEndCap = createFullWallShape(wallShape.paths.slice(4));
      shapes.push(endEndCap);
      return shapes;
    } else if (wallShape.paths.length === 4) {
      const shape = createFullWallShape(wallShape.paths);
      shapes.push(shape);
      return shapes;
    }
  } else {
    if (!wall.cutouts || wall.cutouts.length === 0) {
      const shape = createFullWallShape(wallShape.paths);
      shapes.push(shape);
    } else {
      wall.cutouts.sort((a, b) => a.positionFromStart - b.positionFromStart);
      for (let i = 0; i <= wall.cutouts.length; i++) {
        const cutout = i < wall.cutouts.length ? wall.cutouts[i] : null;
        const startPosition =
          i === 0
            ? 0
            : wall.cutouts[i - 1].positionFromStart +
              wall.cutouts[i - 1].length;
        const endPosition =
          i < wall.cutouts.length
            ? wall.cutouts[i].positionFromStart
            : wallLength;
        if (endPosition > startPosition) {
          let shape: THREE.Shape;
          if (i < wall.cutouts.length && !cutout?.appliesTo.has(shapeType)) {
            const cutoutStartPosition = cutout?.positionFromStart || 0;
            const cutoutEndPosition =
              cutoutStartPosition + (cutout?.length || 0);
            shape = createWallSegment(
              wall.start,
              cutoutStartPosition,
              cutoutEndPosition,
              wallDirection,
              perpendicular,
              lineWeight,
              wallWidth,
              shapeType,
            );
            shapes.push(shape);
          }
          if (i === 0) {
            // First segment: use input vertices for top
            shape = createFirstWallSegment(
              wall.start,
              startPosition,
              endPosition,
              wallDirection,
              perpendicular,
              lineWeight,
              wallWidth,
              wallShape.paths[0],
              wallShape.paths[1],
              shapeType,
            );
          } else if (i === wall.cutouts.length) {
            // Last segment: use input vertices for bottom
            shape = createLastWallSegment(
              wall.start,
              startPosition,
              endPosition,
              wallDirection,
              perpendicular,
              lineWeight,
              wallWidth,
              wallShape.paths[2],
              wallShape.paths[3],
              shapeType,
            );
          } else {
            // Middle segments
            shape = createWallSegment(
              wall.start,
              startPosition,
              endPosition,
              wallDirection,
              perpendicular,
              lineWeight,
              wallWidth,
              shapeType,
            );
          }
          shapes.push(shape);
        }
      }
    }
  }

  return shapes;
};
