import { WallConnectionStart, WallType } from "../../types/wallTypes";
import * as THREE from 'three';
import { removeWallConnection } from "./removeWallConnection";
import { snapToOtherWalls } from "./snapToOtherWalls";

const snapAngle = (angle: number, snapInterval: number) => {
    return Math.round(angle / snapInterval) * snapInterval;
};

/**
 * Drag the start handle of the wall, it will move the start of the wall maintaining connections and updates their positions
 * @param wall The wall to drag
 * @param startOffsetX The start x offset of the wall
 * @param startOffsetY The start y offset of the wall
 * @param newX The new x position of the wall
 * @param newY The new y position of the wall
 * @param tempWalls The temporary walls object
 * @param dragEndFreeToSnap If the end is free to snap
 * @param isShiftPressed If the Shift key is pressed
 * @param delta The delta vector
 * @returns boolean If the wall was snapped
 */
export const dragStartHandle = (
    wall: WallType,
    startOffsetX: number,
    startOffsetY: number,
    newX: number,
    newY: number,
    tempWalls: { [key: string]: WallType },
    dragEndFreeToSnap: boolean,
    isShiftPressed: boolean,
    delta: THREE.Vector2,
): boolean => {
    let snapped = false;
    let newDelta = new THREE.Vector2(
        newX - startOffsetX,
        newY - startOffsetY,
    ).sub(wall.start);
    const newStartPosition = wall.start.clone().add(newDelta);
    // Angle snapping
    if (!isShiftPressed) {
        // Snap to specific angles only if Shift is NOT held
        const angle = Math.atan2(newStartPosition.y - wall.end.y, newStartPosition.x - wall.end.x);
        const snappedAngle = snapAngle(angle, Math.PI / 4); // Snap to 45-degree intervals

        // Compare if the current angle is close to a snapped angle
        const angleDiff = Math.abs(angle - snappedAngle);
        // snap tolerance approximately 1 degrees
        if (angleDiff < 0.0175) {
            // Calculate new position based on snapped angle
            const length = newStartPosition.distanceTo(wall.end);
            newStartPosition.x = wall.end.x + length * Math.cos(snappedAngle);
            newStartPosition.y = wall.end.y + length * Math.sin(snappedAngle);
        }
    }

    if (dragEndFreeToSnap) {
        // Always snap to other walls, even when Shift is held
        removeWallConnection(tempWalls, wall, WallConnectionStart);
        snapped = snapToOtherWalls(tempWalls, wall, WallConnectionStart, newStartPosition, false);
    }

    if (!snapped) {
        tempWalls[wall.id].start.copy(newStartPosition);
    }

    delta.x = newDelta.x;
    delta.y = newDelta.y;
    return snapped;
}