import * as THREE from "three";
import { Point } from "../../types/wallTypes";

export const checkLineIntersection = (
  p1: Point,
  p2: Point,
  p3: Point,
  p4: Point,
  restrictToSegment: boolean,
) => {
  const tolerance = 0.01;
  const determinant =
    (p2.x - p1.x) * (p4.y - p3.y) - (p2.y - p1.y) * (p4.x - p3.x);

  if (Math.abs(determinant) < tolerance) {
    return { intersecting: false, point: null };
  }

  const t =
    ((p3.x - p1.x) * (p4.y - p3.y) - (p3.y - p1.y) * (p4.x - p3.x)) /
    determinant;
  const u =
    ((p3.x - p1.x) * (p2.y - p1.y) - (p3.y - p1.y) * (p2.x - p1.x)) /
    determinant;

  const intersectionPoint = new THREE.Vector2(
    p1.x + t * (p2.x - p1.x),
    p1.y + t * (p2.y - p1.y),
  );

  if (restrictToSegment) {
    if (
      t >= -tolerance &&
      t <= 1 + tolerance &&
      u >= -tolerance &&
      u <= 1 + tolerance
    ) {
      return { intersecting: true, point: intersectionPoint };
    }
  } else {
    if (t >= -1000 && t <= 1000 && u >= -1000 && u <= 1000) {
      return { intersecting: true, point: intersectionPoint };
    }
  }

  return { intersecting: false, point: null };
};
