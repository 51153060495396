import { WallConnectionEnd, WallConnectionStart, WallType } from "../../types/wallTypes";
import * as THREE from "three";

const SNAP_THRESHOLD = 0.3;

export const snapToOtherWalls = (
  tempWalls: { [x: string]: WallType },
  wall: WallType,
  snappingEnd: number,
  wallEdge: THREE.Vector2,
  updateOtherEnd: boolean = true,
): boolean => {
  // Make sure that the snappingEnd is free from any connections
  if (wall.connections) {
    const connection = wall.connections.find(
      (c) => c.sourcePosition === snappingEnd,
    );
    if (connection) {
      return false;
    }
  }
  let snap = false;
  Object.values(tempWalls).forEach((targetWall) => {
    // Make sure that the target wall is not already connected to the source wall on any end
    const alreadyConnected = targetWall.connections?.find(
      (c) => c.id === wall.id,
    );
    if (!alreadyConnected) {
      // Check if the target wall is not the same as the source wall
      if (targetWall.id !== wall.id) {
        // Create a line from the target wall to calculate the closest point against
        const targetLine = new THREE.Line3(
          new THREE.Vector3(targetWall.start.x, targetWall.start.y, 0),
          new THREE.Vector3(targetWall.end.x, targetWall.end.y, 0),
        );
        const closestEdge = new THREE.Vector3();
        targetLine.closestPointToPoint(
          new THREE.Vector3(wallEdge.x, wallEdge.y, 0),
          true,
          closestEdge,
        );
        // Check if the closest point is within the snap threshold
        if (
          wallEdge.distanceTo(
            new THREE.Vector2(closestEdge.x, closestEdge.y),
          ) < SNAP_THRESHOLD
        ) {
          snap = true;
          // If the closestEdge is near the target wall start, we snap to the start
          if (closestEdge.distanceTo(new THREE.Vector3(targetWall.start.x, targetWall.start.y, 0)) < SNAP_THRESHOLD) {
            closestEdge.set(targetWall.start.x, targetWall.start.y, 0);
          }
          // If the closestEdge is near the target wall end, we snap to the end
          if (closestEdge.distanceTo(new THREE.Vector3(targetWall.end.x, targetWall.end.y, 0)) < SNAP_THRESHOLD) {
            closestEdge.set(targetWall.end.x, targetWall.end.y, 0);
          }
          // Save the original wall length and angle
          const originalWallLength = wall.start.distanceTo(wall.end);
          const originalWallAngle = Math.atan2(
            wall.end.y - wall.start.y,
            wall.end.x - wall.start.x,
          );
          // Since we are in snapping distance, we need to move the whole wall (to snap)
          if (snappingEnd === WallConnectionStart) {
            const diff = new THREE.Vector2()
              .subVectors(tempWalls[wall.id].start, closestEdge);
            tempWalls[wall.id].start.set(closestEdge.x, closestEdge.y);
            if (updateOtherEnd) {
              tempWalls[wall.id].end.add(diff);
              // Correct the wall end so it is the original length
              tempWalls[wall.id].end.set(
                tempWalls[wall.id].start.x +
                Math.cos(originalWallAngle) * originalWallLength,
                tempWalls[wall.id].start.y +
                Math.sin(originalWallAngle) * originalWallLength,
              );
            }
          } else {
            const diff = new THREE.Vector2()
              .subVectors(tempWalls[wall.id].end, closestEdge);
            tempWalls[wall.id].end.set(closestEdge.x, closestEdge.y);
            if (updateOtherEnd) {
              tempWalls[wall.id].start.add(diff);
              // Correct the wall start so it is the original length
              tempWalls[wall.id].start.set(
                tempWalls[wall.id].end.x -
                Math.cos(originalWallAngle) * originalWallLength,
                tempWalls[wall.id].end.y -
                Math.sin(originalWallAngle) * originalWallLength,
              );
            }
          }
          // Get the position of the connection on the other wall, always from the start
          let connectionPos = targetWall.start.distanceTo(
            new THREE.Vector2(closestEdge.x, closestEdge.y),
          );
          // If connectionPos is the same as the wall end (or larger), we make sure it is the wall end
          if (connectionPos + SNAP_THRESHOLD >= targetWall.start.distanceTo(targetWall.end)) {
            connectionPos = WallConnectionEnd;
          }
          // If connectionPos is the same as the wall start (or smaller), we make sure it is the wall start
          if (connectionPos - SNAP_THRESHOLD <= 0) {
            connectionPos = WallConnectionStart;
          }
          // If the connection is to WallConnectionStart, we need to snap the source wall (tempWalls[wall.id]) into place
          if (connectionPos === WallConnectionStart) {
          }
          // Update the connection of the source wall
          if (!tempWalls[wall.id].connections) tempWalls[wall.id].connections = [];
          tempWalls[wall.id].connections?.push({
            id: targetWall.id,
            sourcePosition: snappingEnd,
            targetPosition: connectionPos,
          });
          // Update the connection of the target wall
          if (!tempWalls[targetWall.id].connections) tempWalls[targetWall.id].connections = [];
          tempWalls[targetWall.id].connections?.push({
            id: wall.id,
            sourcePosition: connectionPos,
            targetPosition: snappingEnd,
          });
          return tempWalls[targetWall.id]
        }
      }
    }
  });
  return snap;
}